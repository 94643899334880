import { useRef, useEffect, useState, useContext } from 'react'
import ProjectContext from '../../context/ProjectContext'
import SceneContext from '../../context/SceneContext'
import QnrContext from '../../context/QnrContext'
import { getAreaVertices } from '../../utils/sceneUtils'
import { getOffset, offsetPoints } from '../../utils/geometry'

const MiniFloorPlan = (props) => {
    const projectActions = useContext(ProjectContext)
    const sceneActions = useContext(SceneContext)
    const qnrActions = useContext(QnrContext)
  
    const canvasRef = useRef(null)

    const {width,height,areaid,zone} = props

    useEffect(() => {

        const canvas = canvasRef.current
        const context = canvas.getContext('2d')             

        draw(context)
    },[])

    const draw = (ctx) => {

        const layer = projectActions.scenes[0].layers[0]
        const vertices = layer.vertices;
        const areas = layer.areas
        const lines = layer.lines
        const groups = layer.groups 
        const foundLines = new Set()
        const foundVertices = new Set()
        const foundAreas = areas.filter(a => {
            const areaTest = areaid.includes(a.id)
            if(areaTest === true){
                lines.filter(l => a.lines.includes(l.id))
                    .forEach(line => {
                        foundLines.add(line)
                    })
                getAreaVertices(layer,a.id).forEach(v => {
                    foundVertices.add(v)
                })
            }
            return areaTest
        })     

        const scale = .05

        // const offset = {x:getOffset(ctx,[...foundVertices]).x,y:getOffset(ctx,[...foundVertices]).y}

        
        const offset = getOffset(ctx,[...foundVertices])

        // console.log('OFFSET',foundVertices,offset)

        if(foundAreas.length > 0){
            foundAreas.forEach(a => {
                const areaVertices = getAreaVertices(layer,a.id)

                const line = new Path2D();


                ctx.fillStyle = 'rgba(255, 0, 0)';
                ctx.beginPath();
                
                ctx.strokeStyle = 'grey';
                ctx.lineWidth = 3;
                ctx.beginPath();
                areaVertices.forEach((vs,j) => {
                    // console.log('DRAWING:',j,vs)
                    if(j === 0){
                        //  ctx.moveTo(0, 0);
                        ctx.moveTo((vs.x * 100 + offset.x) * scale + 30, (vs.y * 100 + offset.y) * scale + 20);
                        // ctx.lineTo(vs[1].x * scale, vs[1].y * scale + offset.y)
                    } else {
                        // ctx.lineTo(width,height)
                        ctx.lineTo((vs.x * 100 + offset.x) * scale + 30, (vs.y * 100 + offset.y) * scale + 20)
                        // ctx.lineTo(vs[1].x * scale, vs[1].y * scale + offset.y)
                    }
                })
                ctx.closePath();      // go back to point 1
                ctx.fill()
            })
        }
    }

    return (
        <canvas
            width={props.width} 
            height={props.height} 
            style={{
                width:props.widt + 'px',
                height:props.height + 'px',
                border:'1px solid red'
            }}
            ref={canvasRef} {...props}
        />
    )
}

export default MiniFloorPlan