import { useEffect, useState } from 'react'
import { getFeatureValue } from '../../utils/formatUtils'
import { getAreaOfElement } from '../../utils/sceneUtils'

const PropertyInput2 = ({property, action, item, areaID}) => {

    const [value,setValue] = useState('')

    useEffect(() => {
        if(item){
            if(property.feature !== undefined){
                setValue(item.features[property.feature])
            } else if (item[property.name]){
                setValue(() => item[property.name])
            } else if (property.measurement){
                console.log('=====IN PROP FEATURE',item.id,value,item)
                setValue(() => item.measurements[property.measurement])
            }
        }
    },[item])

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSave(e)
        }
    }

    const handleSave = (e) => {
        setValue(e.target.value)
        action(e)
    }

    const handleChange = (e) => {
        setValue(e.target.value)
    }

    const inp = () => {
        switch(property.type){
            case 'text':
                return <label className='q-prop'>{property.label && <div className="q-title">{property.label}</div>}
                    <input onChange={handleChange} onKeyDown={handleKeyDown} onBlur={handleSave} type="text" value={value}
                        data-itemid={item ? item.id : null}
                        // data-areaid={areaID ?? null}
                        data-idelement={item.id_element ?? null} 
                        data-feature={property.feature ?? null}
                        data-property={property.name ?? null}
                        data-measurement={property.measurement ?? null}
                    />
                </label>
            case 'enum':
                return <label className='q-prop'>
                    {property.label && <div className="q-title">{property.label}</div>}
                    <select value={value} onChange={handleSave}
                        data-itemid={item ? item.id : null}
                        // data-areaid={areaID ?? null}
                        data-idelement={item.id_element ?? null}
                        data-feature={property.feature ?? null}
                        data-property={property.name ?? null}
                        data-measurement={property.measurement ?? null}
                    >
                        {property.values.map((v,i) => <option key={i} value={v.value}>{v.label}</option>)}
                    </select>
                </label>
            case 'number':
                return <label className='q-prop'>{property.label && <div className="q-title">{property.label}</div>}
                    <input onChange={handleChange} onKeyDown={handleKeyDown} onBlur={handleSave} type="number" value={value}
                        data-itemid={item ? item.id : null}
                        // data-areaid={areaID ?? null}
                        data-idelement={item.id_element ?? null}
                        data-feature={property.feature ?? null} 
                        data-property={property.name ?? null} 
                        data-measurement={property.measurement ?? null} 
                    />
                </label>
            case 'radio':
                return <div className="q-prop">
                        {property.label && <div className="q-title">{property.label}</div>}
                        {property.description && <div className="q-description">{property.description}</div>}
                        {property.values.filter(val => {
                        let testFilter = [];
                        if(item && val.filter && val.filter.length > 0){
                        {/* console.log('IN FILTER',filtered.filter) */}
                        val.filter.forEach(filt => {
                            {/* console.log(filt.v) */}
                            {/* if(ignoreFilters === filt.f){
                            testFilter.push(true)
                            } else {
                            testFilter.push(filt.v.some(f => item.features[filt.f] === f))
                            } */}
                        })
                        }
                        return testFilter === [] || testFilter.every(fl => fl === true);
                    }).map((value,i) => {
                        // console.log('IN RADIO',val,value)
                        return <label key={i}><input type="radio" 
                        onChange={handleChange}
                        name={item ? item.id + '_' + property.type : property.type}
                        data-feature={property.feature} 
                        data-itemid={item ? item.id : null}  
                        data-idelement={item ? item.id_element : null}
                        // data-typology={item ? item.typology : typology}
                        // data-option={option}
                        value={value.value}
                        // checked={val === value.value}
                        style={{marginRight:'.5em'}}
                        />
                            {value.label} 
                            {value.superscript && <div 
                                style={{marginLeft:'.5em',fontSize:'0.8em',height:'1.5em',fontStyle:'italic',background:'#C7DEFC',borderRadius:'3px',padding:'0 5px'}}
                            >
                                {value.superscript}
                            </div>}
                        </label>
                    })}
                </div>
            default :
            return <div>{property.label}-{property.type}-</div>
        }
    }

    return (
        <div className="q-property">{inp()}</div>
    )
}

export default PropertyInput2