import { useState, useContext, Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import '../scss/style.scss';
import Chatroom from '../pages/Chatroom';
import SharedBudget from "./SharedBudget/SharedBudget";
import AuthContext from '../context/AuthContext';
import { AdminProvider } from '../context/AdminContext';
import { ProjectProvider } from '../context/ProjectContext';
import { QnrProvider } from '../context/QnrContext'
import { CalcProvider } from '../context/CalcContext'
import { SceneProvider } from '../context/SceneContext'
import { ModalProvider } from '../context/ModalContext';
import { ChatProvider } from '../context/ChatContext';
import { PuffLoader } from 'react-spinners';

const Qnr = lazy(() => import('../pages/Qnr'));
const ExtQnr = lazy(() => import('../pages/ExtQnr'));

const Login = lazy(() => import('../pages/Login'));
const Profile = lazy(() => import('../pages/Profile'));
const Register = lazy(() => import('../pages/Register'));
const Users = lazy(() => import('../pages/Users'));
const Projects = lazy(() => import('../pages/Projects'));
const Clients = lazy(() => import('../pages/Clients'));
const Calendr = lazy(() => import('../pages/Calendr'));
const Jobs = lazy(() => import('../pages/Jobs'));
const Stats = lazy(() => import('../pages/Stats'));
const Documents = lazy(() => import('../pages/Documents'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const Summary = lazy(() => import('../pages/Summary'));
const MiniProjects = lazy(() => import('../pages/MiniProjects'));
const RemoveTrailingSlash = lazy(() => import('../utils/RemoveTrailingSlash'))
const Datatools = lazy(() => import('../pages/Datatools'));

const Homyplan = () => {

  const queryClient = new QueryClient()
  const { currentUser } = useContext(AuthContext)

  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/login" />;
  }

  const loaderCss = {
    position:'absolute',
    top:'50%', 
    left:'50%',
}  

  return (
    <QueryClientProvider client={queryClient}>
      <ProjectProvider>
        <QnrProvider>
          <AdminProvider>
            <SceneProvider>
              <ModalProvider>
                <CalcProvider>
                  <ChatProvider>
                      <BrowserRouter>
                        <>
                        <RemoveTrailingSlash/>
                        <Suspense fallback={<PuffLoader color={"#555"} loading={true} css={loaderCss} size={50} />}>
                          <Routes>
                            <Route path="/login" element={<Login />}/>
                            <Route path="/" element={<Qnr />} />
                            <Route path="/cocina" element={<Qnr />} />
                            <Route path="/bano" element={<Qnr />} />
                            <Route path="/general" element={<Qnr />} />
                            <Route path="/project/:projectID/:scope" element={<RequireAuth><ExtQnr /></RequireAuth>}/>
                            <Route path="/project/:projectID" element={<RequireAuth><ExtQnr /></RequireAuth>}/>
                            <Route path="/results/:projectID/:scope" element={<SharedBudget />} />
                            <Route path="/results/:projectID" element={<SharedBudget />} />
                            <Route path="/summary/:userId" element={<Summary />} />
                            <Route path="/profile/:userId" element={<RequireAuth><Profile /></RequireAuth>} />
                            <Route path="/chatroom" element={<RequireAuth><Chatroom /></RequireAuth>} exact />
                            <Route path="/register" element={<Register />} exact />
                            <Route path="/projects" element={<RequireAuth><Projects /></RequireAuth>} exact />
                            <Route path="/calendar" element={<RequireAuth><Calendr /></RequireAuth>} exact />
                            <Route path="/documents" element={<RequireAuth><Documents /></RequireAuth>} exact />
                            <Route path="/users" element={<RequireAuth><Users /></RequireAuth>} exact/>
                            <Route path="/jobs" element={<RequireAuth><Jobs /></RequireAuth>} exact/>
                            <Route path="/data" element={<RequireAuth><Datatools /></RequireAuth>} exact/>
                            <Route path="/stats" element={<RequireAuth><Stats /></RequireAuth>} exact/>
                            <Route path="/miniprojects/:userId" element={<RequireAuth><MiniProjects /></RequireAuth>} exact />
                            <Route path="/myprojects" element={<RequireAuth><Projects /></RequireAuth>} exact />
                            <Route path="/clients" element={<RequireAuth><Clients /></RequireAuth>} exact />
                            <Route path="/dashboard" element={<RequireAuth><Dashboard /></RequireAuth>} exact />
                          </Routes>
                        </Suspense>
                        </>
                      </BrowserRouter>
                  </ChatProvider>
                </CalcProvider>
              </ModalProvider>
            </SceneProvider>
          </AdminProvider>
        </QnrProvider>
      </ProjectProvider>
    </QueryClientProvider>
  );
}

export default Homyplan;
