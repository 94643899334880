export const EPSILON = .2;

export const COLORS = {
    planner:{
        text:'white',
        textActive:'',
        textSelected:'',
        textDragged:'',          
        label:'#777786',
        labelActive:'#04073F',
        labelSelected:'#007DFF',
        labelDragged:'',        
        line:"#777786",
        lineActive:'#007DFF',
        lineSelected:'#007DFF',
        lineDragged:'',
        item:'',
        itemActive:'#D4FFD4',
        itemSelected:'#00D4FF',
        itemDragged:'',
    }
}

export const GEOMETRY = {
    wallThickness:0.001
}