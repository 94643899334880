import {generateUUID} from "three/src/math/MathUtils";
import {amplitudeEvent, EnumActionsAmplitude, EnumEventsAmplitude} from "./amplitude";
import { getAreaOfElement } from "./sceneUtils";

/**
 * Convert number or string to number decimal.
 *
 * @param num to convert.
 * @returns Number converted.
 */
const convertNumber = (num) => {
    const { format } = new Intl.NumberFormat('es-ES');
    const [, decimalSign] = /^0(.)1$/.exec(format(0.1));
    return +num
        .replace(new RegExp(`[^${decimalSign}\\d]`, 'g'), '')
        .replace(decimalSign, '.');
}

/**
 * Parse jobs by chapters to get jobs grouped.
 *
 * @param result The result to parse jobs and chapters.
 * @param calcData ProjectActions.
 * @returns A promise with the chapters parsed.
 */
export const getChapters = (result, calcData) => {

    // console.log('IN CHAPTERS',result.jobs.map(j => [j.id,j.value,j.price]))

    return new Promise((resolve) => {
        const newChaptersRaw = {
            /** Jobs of list chapters. */
            jobs: [],
            /** Amount total of all jobs.  */
            amountTotal: 0
        };
        if (result.jobs && result.jobs.length) {
            for (const jobData of result.jobs) {
                const getChapterDataIndex = newChaptersRaw.jobs.findIndex(
                    (jobChapter) => jobChapter.id === jobData.id_chapter
                );
                if (getChapterDataIndex >= 0) {
                    newChaptersRaw.jobs[getChapterDataIndex].amountTotal += jobData.value;
                } else {
                    const foundChapterInfo = calcData.chapters.find(
                        (chapter) => chapter.id_chapter === jobData.id_chapter
                    );
                    const totalJobs = [];
                    const filteredJobsRaw = result.jobs.filter((job) => job.id_chapter === jobData.id_chapter);
                    filteredJobsRaw.forEach((jobRaw) => {
                        const foundJobIndex = totalJobs.findIndex((foundJob) => foundJob.name === jobRaw.job.name);
                        if (typeof jobRaw.price == "string") {
                            jobRaw.price = convertNumber(jobRaw.price);
                        }
                        if (!jobRaw.price || isNaN(jobRaw.price)) {
                            jobRaw.price = 0;
                        }
                        if (!jobRaw.value || isNaN(jobRaw.value)) {
                            jobRaw.value = jobRaw.measurement * jobRaw.price * jobRaw.coef;
                        }
                        if (foundJobIndex >= 0) {
                            totalJobs[foundJobIndex].measurement.measurement = parseFloat(totalJobs[foundJobIndex].measurement.measurement + jobRaw.measurement);
                            totalJobs[foundJobIndex].amountTotal = parseFloat(totalJobs[foundJobIndex].amountTotal + (jobRaw.measurement * jobRaw.price));
                        } else {

                            totalJobs.push({
                                /** Custom Id of the job. */
                                id: jobRaw.id,
                                /** Id of the job. */
                                idJob: jobRaw.id_job,
                                /** Random key to display in table. */
                                key: generateUUID(),
                                /** Name of the job. */
                                name: jobRaw.job.name,
                                /** Amount of the job. */
                                price: jobRaw.price || 0,
                                /** Measurement of the job with unit. */
                                measurement: {
                                    /** Measurement of the job. */
                                    measurement: jobRaw.measurement,
                                    /** unit of the measurement. */
                                    unit: calcData.units[jobRaw.job.id_unit].tag
                                },
                                /** Amount total of the job. */
                                amountTotal: jobRaw.measurement * jobRaw.price,
                                coef: jobRaw.coef || 1,
                                /** Type of job, (0: obras, 1: acabados). */
                                type: jobRaw.type,
                                img: jobRaw.job.img
                            });
                        }
                    });
                    const details = totalJobs.filter((job) => job.type === 1);
                    const work = totalJobs.filter((job) => job.type === 0)
                    newChaptersRaw.jobs.push({
                        /** Name of chapter. */
                        name: foundChapterInfo.name,
                        /** Name in spain of chapter. */
                        name_es: foundChapterInfo.name_es,
                        /** Id of chapter. */
                        id: jobData.id_chapter,
                        /** Random key to display in table. */
                        key: generateUUID(),
                        /** Jobs by chapter (acabados and obras). */
                        jobs: {
                            /** Acabados. */
                            detail: {
                                /** Data to show rows. */
                                data: details,
                                /** Amount total of the Acabados. */
                                amountTotal: getAmountTotal(details)
                            },
                            /** Obras. */
                            work: {
                                /** Data to show rows. */
                                data: work,
                                /** Amount total of the obras. */
                                amountTotal: getAmountTotal(work)
                            },
                        },
                        amountTotal: jobData.value
                    });
                }
            }
        }
        newChaptersRaw.amountTotal = getAmountTotal(newChaptersRaw.jobs)
        newChaptersRaw.jobs.forEach((job) => {
            /** Percentage by chapter. */
            job.percentageTotal = (job.amountTotal / newChaptersRaw.amountTotal) * 100
        })
        newChaptersRaw.jobs = newChaptersRaw.jobs.sort(sortArrayById)
        resolve(newChaptersRaw)
    })
}

/**
 * Sort jobs by id
 */
const sortArrayById = (a, b) => a.id - b.id

/**
 * Sum amounts of chapters to get total amount.
 *
 * @param amount The chapters to get sum amount.
 * @returns Amount total of all chapters.
 */
const getAmountTotal = (amount) => amount.reduce((currentAmount, { amountTotal }) => currentAmount + amountTotal, 0);

/**
 * Get the name of the chapter by id
 *
 * @param chapterId id of chapter.
 * @returns a string with name of chapter.
 */
export const getSectionNameById = (chapterId) => {
    const names = {
        0: 'demolicion',
        4: 'fontaneria',
        6: 'electricidad',
        7: 'techo',
        8: 'revestimientos',
        13: 'accesorios',
        14: 'varios',
        16: 'promociones',
    }
    return names[chapterId] || '';
}

/**
 * Emit event amplitude with name of chapter selected.
 *
 * @param id Of chapter selected.
 */
export const amplitudeEventDropdownTable = (id) => {
    let amplitudeEventDescription = getSectionNameById(id);
    if (amplitudeEventDescription) {
        amplitudeEventDescription = {
            section_name: amplitudeEventDescription
        }
        amplitudeEvent(
            EnumEventsAmplitude.onb_quote_section_opened,
            amplitudeEventDescription,
            EnumActionsAmplitude.InteractionEvent
        )
    }
}

/**
 * Get days by a range of dates.
 *
 * @param startDate The start date.
 * @param endDate The start date.
 * @returns A string with date in days.
 */
export const getDateOfBudget = (startDate, endDate, scope) => {

    let duration = '9 días'

    switch (scope){
        case 'BanoProyecto':
        case 'CocinaProyecto':
            duration = '9 días'
        break;
        case 'CasaProyecto':
            duration = '21 días'
        break;
    }

    return startDate && endDate
        ? (new Date(endDate) - new Date(startDate))/ (1000 * 3600 * 24) + ' días'
        : duration
}

export const getDaysOfBudget = (startDate, endDate) => {
    return startDate && endDate
        ? (new Date(endDate) - new Date(startDate))/ (1000 * 3600 * 24)
        : 0
}

export const getScope = (scope) => {

    let tempScope = '';

    switch(scope){
        case 'BanoProyecto':
            tempScope = 'baño'
        break;
        case 'CocinaProyecto':
            tempScope = 'cocina'
        break;
        case "CasaProyecto":
            tempScope = 'casa'
        break;
        default:
            tempScope = ''
    }

    return tempScope
}

export const getAttributesJob = (job) => {
    // Make here the logic to get attributes by job.
}

export const divideResult = (scene,result) => {

    let dividedResults = [];

    console.log('IN BUDGET',scene,result)

    let bano1Jobs = []
    let cocinaJobs = []
    let otherJobs = []
    
    if(scene){
        const tempLayer = scene.layers[0];

        const roomsCount = scene.layers[0].areas.length

        if(roomsCount == 1){
            dividedResults = [
                {jobs:result[0].jobs.filter(job => job.room === 6),title:'Bano'},
                {jobs:result[0].jobs.filter(job => job.room === 8),title:'Cocina'},
                {jobs:result[0].jobs.filter(job => (job.room !== 6 && job.room !== 8 && !job.id_space)),title:'General'}
            ]
        } else {
            tempLayer.areas.filter(area => area.features[0] === 6).forEach(a => {
                dividedResults.push({jobs:result[0].jobs.filter(job => job.area === a.id && !job.id_space),title:a.label}) 
            })
            tempLayer.areas.filter(area => area.features[0] === 8).forEach(a => {
                dividedResults.push({jobs:result[0].jobs.filter(job => job.area === a.id && !job.id_space),title:a.label}) 
            })
            dividedResults.push(
                {jobs:result[0].jobs.filter(job => {
                    return (job.room !== 6 && job.room !== 8) || job.id_space === 3
                }),title:'General'}
            )
        }
    }

    return dividedResults
}