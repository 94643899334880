import React, { useEffect, useContext, useState } from 'react'
import BigImg from './BigImg'
import PropertyInput from './PropertyInput'
import ProjectContext from '../../context/ProjectContext'
import SceneContext from '../../context/SceneContext'
import { getItem, getElementData, getElementOfId } from '../../utils/sceneUtils'
import { getFeatureValue } from '../../utils/formatUtils'
import { getIndexedName, getIndexedPrice, getPrice } from '../../utils/calcUtils'
import PuffLoader from "react-spinners/PuffLoader";
import { BsArrowRightCircle, BsArrowLeftCircle } from 'react-icons/bs'
import Pricetag from './Pricetag'

const Properties = ({table = 'initialTable',source,properties,preview,itemID}) => {

    const projectActions = useContext(ProjectContext)
    const sceneActions = useContext(SceneContext)

    const [ itemData, setItemData ] = useState(null) 
    const [ item, setItem ] = useState(null) 
    const [ layer, setLayer ] = useState(null)
    const [ refresh, setRefresh ] = useState(false)
    const [ loaded, setLoaded ] = useState(false)
    const [ imgId, setImgId ] = useState(0)
    const [ ignoreFilters, setIgnoreFilters ] = useState(null)
    const indexedName = [];
    let sortedName = '';
    let combinedName = '';
    let price = null;

    useEffect(() => {
      const layer = projectActions.scenes[table === 'initialTable' ? 0 : 1].layers[0]
      const foundItem = getElementOfId(layer,null,itemID,source.filter)
      
      const elementData = getElementData(projectActions.elementsData,source.id_element,foundItem ? foundItem.features[1] : source.filter[0].v)
      console.log('IN PROPERTIES',foundItem,layer,itemID,source)
      setItemData(() => elementData)
      setLayer(layer)
      // const item = getItem(layer, source.id_element,foundItem ? foundItem.features[1] : source.filter[0].v,source.filter)
      setItem(foundItem)
    },[])

    const handleNext = () => {
      setImgId(imgId + 1)
      console.log('|||',imgId)
    }

    const handlePrev = () => {
      setImgId(imgId - 1)
      console.log('|||',imgId)
    }

    const handlePropertyChange = (e) => {
      const { dataset, value, name } = e.target;

      if(dataset.property){
        const prop = JSON.parse(dataset.property)

        console.log('MEASURE',prop.measurement)

        if(prop.measurement){
          const foundValue = prop.values.find(v => v.value == value)
              if(foundValue && foundValue.measurements){          
                switch (prop.measurement){
                  case 'length':
                  break;
                  case 'depth':
                  break;
                  case 'height':
                  break;
                  case 'all':
                  default:
                    sceneActions.setMeasurements(table,foundValue.measurements,dataset.itemid)
              }
            }
        }
      }

      console.log('ITEM DATA ',itemData)

      console.log('------------PROP','F:',dataset.feature, 'V:',value, '', name, 'ID:',dataset.itemid,source.filter)

        if(value === 'resetFilter'){
          setIgnoreFilters(() => parseInt(dataset.feature))
        } else if (dataset.feature && !isNaN(value)){
          if(parseInt(dataset.feature) === 23){
            setIgnoreFilters(() => null)
          }
          if(dataset.img){
            console.log('setImg',dataset.img)
          }
          sceneActions.setFeature('mScene',parseInt(dataset.feature),parseInt(value),parseInt(dataset.idelement),source.filter,itemID)
        }
  
    } 
  
    const handleFeatureChange = (e) => {
      const { dataset, value, name } = e.target;
      
      sceneActions.setFeature(table === 'initialTable' ? 'scene' : 'mScene',parseInt(dataset.feature),parseInt(value),parseInt(dataset.idelement))
      if(table === 'initialTable'){
        const columns = projectActions.calcData.columns.find(f => f.old === parseInt(dataset.feature))
        if(columns && columns.id_column && projectActions.scenes.length > 1){
          sceneActions.setFeature('mScene',columns.id_column,value,parseInt(dataset.idelement))
        }
      }
      setRefresh(!refresh)
    }

    return (
        <div className="modal-panels">
            <div className="m-left" style={{padding:'20px'}}>
                <div className='c-title' style={{fontSize:'28px'}}>{source?.label || itemData?.label} <p className="debug">{itemID}</p></div>
                {itemData && <div>
                    {itemData.properties.filter(prp => prp.user === true).filter(prp => {
                    let testProp = true;
                    if(properties && properties.length > 0){
                      testProp = source.properties.includes(prp.name);
                    }
                    return testProp
                  }).map((property,j) => {
                      const oldValue = getFeatureValue(item,property.feature)
                      const propertyValue = property.values.find(val => val.value === oldValue)
                      let itemName = '';
                      if(j === 0){
                        itemName = item.name;
                        indexedName[0] = {0:item.name};
                      }

                      if(propertyValue){
                        const propertyName = property.name !== 'style' ? `|${propertyValue.name}` : '';
                        {/* combinedName += itemName + propertyName; */}
                        indexedName.push({[property.feature]:propertyName})
                      }

                      return <PropertyInput key={j} action={handlePropertyChange} item={item} property={property} ignoreFilters={ignoreFilters}/>
                    })}                        
                </div>}
                {<Pricetag combinedName={getIndexedName(indexedName)} />}
            </div>
            <div className="m-right">
              {itemData && <div>
                {itemData.properties.filter(prp => {
                  return prp.image && prp.image === true
              }).map((property,j) => {
                  const oldValue = getFeatureValue(item,property.feature)
                  const propertyValue = property.values.find((val) => val.value === oldValue)

                  {/* console.log('====IN PROP',property,oldValue,propertyValue) */}

                  return propertyValue && <div key={j} className="m-preview">
                    {loaded === false && <PuffLoader color="#ffffff" />}
                    {propertyValue.img && <img onLoad={() => setLoaded(true)} style={{width:'100%'}} src={propertyValue.img[imgId]}/>}
                    {propertyValue.img && propertyValue.img.length > 0 && <div className='navBtnsPreview'>
                    {imgId !== 0 && <div className='navBtn left' onClick={handlePrev}><BsArrowLeftCircle size="30px"/></div>}
                    {imgId < propertyValue.img.length - 1 && <div className='navBtn right' onClick={handleNext}><BsArrowRightCircle size="30px"/></div>}
                  </div>}
                </div>

                })}
              </div>}
            </div>
        </div>
    )
}


//17,18,19,20,21,22

export default Properties