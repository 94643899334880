import { useState, useEffect, useContext } from 'react'
import { Select } from 'antd'
import QnrContext from '../../context/QnrContext'
import ProjectContext from '../../context/ProjectContext'
import SceneContext from '../../context/SceneContext'
import CalcContext from '../../context/CalcContext'
import SubstyleSelector from './SubstyleSelector'
import MaterialSelector from './MaterialSelector'
import ModelSelector from './ModelSelector'
import { getElementData, getElementOfId, getOldValue } from '../../utils/sceneUtils'
import GroupSelector from './GroupSelector'
import PropertySelect from './PropertySelect'
import ActionSelect from './ActionSelect'

const SelectorInput = ({q,o,action,itemID}) => {

    const qnrActions = useContext(QnrContext)
    const projectActions = useContext(ProjectContext)
    const sceneActions = useContext(SceneContext)
    const calcActions = useContext(CalcContext)

    const [localStyle, setLocalStyle] = useState(projectActions.project.defaults.style ?? 0)
    const [localSubstyle, setLocalSubstyle] = useState(projectActions.project.defaults.substyle ?? 0)
    const [variant, setVariant] = useState(0)
    const [material, setMaterial] = useState(null)
    const [group, setGroup] = useState(null)
    const [item, setItem] = useState(null)
    const [oldValue,setOldValue] = useState(null)

    // useEffect(() => {
    //     setStyle(() => projectActions.project.defaults.style)
    //     setSubstyle(() => projectActions.project.defaults.substyle)
    // },[projectActions.project.defaults.style,projectActions.project.defaults.substyle])

    useEffect(() => {
        console.log('IN SELECTOR',q)
        if(!q){
            q = {options:[o]}
        }

        const layer = projectActions.scenes[1].layers[0]

        if(q.group){            
            const foundGroup = layer.groups.find(group => group.id === q.group);
            if(foundGroup){ 
                setGroup(() => foundGroup)
            }
        }
    },[])

    useEffect(() => {
        setVariant(qnrActions.activeVariant)
    },[qnrActions.activeVariant])

    const handleChangeStyle = (e) => {
        const {value,dataset} = e.target
        // if(variant !== 1){  
        //     projectActions.setDefault('style',parseInt(value))
        //     projectActions.setDefault('substyle',0)
        //     setLocalStyle(parseInt(value))
        // } else {
        //     setLocalStyle(parseInt(value))
        // }



        if(sceneActions.selected !== null){
            console.log('SELECTED',getElementOfId())    
        }

        console.log('SET STYLE',value,dataset,sceneActions.selected)
        // if(groupId){
        //     sceneActions.setGroupFeature('mScene',23,parseInt(value),q.group,q.id_element)
        // } else {
            sceneActions.setFeature('mScene',23,parseInt(value),dataset.idelement,[],itemID,null,q.group)
        // }

        projectActions.setRefresh(!projectActions.refresh)
    }

    const handleChangeSubstyle = (j) => {
        // console.log(variant)
        // console.log(j)
        if(variant !== 1){            
            projectActions.setDefault('substyle',parseInt(j))
            setLocalSubstyle(parseInt(j))
        } else {
            setLocalSubstyle(parseInt(j))
        }
        projectActions.setRefresh(!projectActions.refresh)
    }

    const handleModelAction = (value,id_element,groupId) => {
        console.log('MODEL ',value,itemID,id_element)
        if(groupId){
            sceneActions.setGroupFeature('mScene',17,parseInt(value),groupId,parseInt(id_element))
        } else {
            sceneActions.setFeature('mScene',17,parseInt(value),parseInt(id_element),[],itemID,q.group ?? null)
        }
        calcActions.makeCalculations();
        calcActions.makeResults();
        projectActions.setRefresh(!projectActions.refresh)
    }

    const handleMaterialAction = (value,id_element,groupId) => {
        console.log('MAT ',value,itemID,id_element,q)
        setMaterial(value)
        if(groupId){
            sceneActions.setGroupFeature('mScene',3,parseInt(value),groupId,parseInt(id_element))
        } else {
            sceneActions.setFeature('mScene',3,parseInt(value),parseInt(id_element),[],itemID,null,q.group ?? null)
        }
        calcActions.makeCalculations();
        calcActions.makeResults();
        projectActions.setRefresh(!projectActions.refresh)
    }

    const handleActions = (actions) => {
        console.log('INSIDE',actions,q.group)

        if(actions){
            sceneActions.makeActions(q.table,actions,sceneActions.selected,q.area,q.group)
        }
    }

    const handleSelectActions = (e) => {
        const dataset = e.target.dataset 

        // console.log('====IN ACTION',dataset)

        const values = JSON.parse(dataset.values)
        const val = values[e.target.value]
        if(val){
            sceneActions.makeActions(q.table,val.actions,sceneActions.selected,q.area,q.group)
        }
    }



    const selectElement = (option) => {
        switch (option.subtype){
            case "styleSelect":
                const styles = qnrActions.qnrData.styles.map((style,i) => {return {label:style.label,value:String(i)}})
                return <select
                    className="select-property"
                    style={{
                        padding:'.8em .5em',
                        border:'1px solid #c8c8c8'
                    }}
                    onChange={handleChangeStyle}
                    data-idelement={option.id_element}
                >
                {styles.map((styl,i) => {
                    return <option key={i} value={i}>{styl.label}</option>
                })}
                </select>
                // <Select
                //     defaultValue={String(projectActions.project.defaults.style)}
                //     dropdownStyle={{
                //         borderRadius:'1em',
                //         padding:'1em .5em'
                //     }}
                //     size={'large'}
                //     style={{
                //         width: '100%',
                //     }}
                //     options={styles}
                //     onChange={handleChangeStyle}
                // />
            case "substyleSelect":
                return <SubstyleSelector 
                    styles={qnrActions.qnrData.styles} 
                    action={handleChangeSubstyle} 
                    localStyle={localStyle} 
                    localSubstyle={localSubstyle}
                />    
            case "materialSelect": 
            case "secondaryMaterialSelect":
                return <MaterialSelector 
                    option={option}
                    action={handleMaterialAction}
                    variant={qnrActions.variant} 
                    localStyle={localStyle} 
                    localSubstyle={localSubstyle}
                    itemID={itemID}
                    groupId={q.group}
                />    
            case "modelSelect":
                return <ModelSelector 
                    option={option}
                    action={handleActions}
                    variant={qnrActions.variant} 
                    localStyle={localStyle} 
                    localSubstyle={localSubstyle}
                    material={material}
                    itemID={itemID}
                    groupID={q.group}
                />  
            case "groupSelector":
                return <GroupSelector q={option} />
            case "radio":
                return <label style={{display:'flex',gap:'10px'}}><input
                style={{minWidth:'20px',width:'20px',cursor:'pointer'}}
                    type="radio"
                    name={option.id}
                    onChange={handleActions}
                    data-actions={option.actions ? JSON.stringify(option.actions) : ''}
                />{option.text}</label>
            case 'select':
                return <ActionSelect q={option} action={handleSelectActions} />
            case 'select-old':
                // const oldValue = 0;
                return <select
                        className="select-property"
                        style={{
                            padding:'.8em .5em',
                            border:'1px solid #c8c8c8'
                        }}
                        onChange={handleSelectActions}
                        data-values={option.values ? JSON.stringify(option.values) : ''}
                        // value={oldValue}
                    >
                    {option.values.map((val,i) => <option key={i} value={i}>{val.label}</option>)}
                </select>
            case 'propertySelect':
                return <PropertySelect 
                    option={option}                     
                    action={handleActions} 
                    itemID={itemID}
                    groupID={q.group}
                />
            case 'selected':
                // const foundSelected = getElementOfId(projectActions.scenes[q.table === 'integratedTable' ? 1 : 0].layers[0],null,sceneActions.selected)
                // const filtered = checkFilterItem(foundSelected,o.filter,o.id_element)
                // console.log('SELECTED',foundSelected)
                // return (foundSelected && filtered) && <div>
                //     <hr style={{marginTop:'40px'}}/>
                //     Selected: {foundSelected.label}
                // </div>

                return <div>{option.header}: {sceneActions.selected ?? 'Todos los elementos'}</div>
            default:
                return <div>{option.subtype}</div>
        }        
    } 
    return !group || (group && group.items.length > 0) 
        ? q.options.map((option,i) => <div key={i} style={{
            marginBottom:'1em'
        }}>
            {option.title && <div className='q-title'>{option.title}</div>}
            {option.label && <div className='q-subtitle' style={{fontSize:'12px',fontWeight:'500',marginBottom:'.5em'}}>
                {option.label}
            </div>}
            {/* <span>{JSON.stringify(option)}</span> */}
            {selectElement(option)}
        </div>)
        :
        <h2 style={{textAlign:'center'}}>Grupo de paredes vacias</h2>
}

export default SelectorInput