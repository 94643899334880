import {getDaysOfBudget} from "./budgetUtils";
import { getAreaOfElement } from "./sceneUtils";
  
  export const detectDecsAndWorks = (id_element,features,decisionsData) => {

    if(decisionsData){
      const filteredDecisions = decisionsData.filter(dec => dec.id_dec.split('_')[2] == id_element);  
      const foundDecisions = filteredDecisions.filter(dec => {
        let counter = 0;
        const aWorkCode = dec.code_work.split('|')
  
        // if(id_element === 12){
        //   console.log('comparing ',id_element,dec.id_dec,dec.code_work,'>>>>>',features)
        // }
  
        let res = [];
  
        for(let i = 0; i < aWorkCode.length; i++){
          if(!aWorkCode[i] || features[counter] === null || checkIfInRange(features[counter],aWorkCode[i]) === true){
            res.push(true)
          } else {
            res.push(false)
          }
          counter++
        }
        // if(id_element === 12 || id_element === 13){
        //   console.log(res,res.every(b => b === true))
        // }
        return res.every(b => b === true)
      }).filter(dec => dec.id_works !== "" && dec.id_works !== "X")
  
    //   const mappedWorks = foundDecisions.map(w => new Map(w))
  
      // console.log('in wrks',id_element,features,mappedWorks)
  
      return foundDecisions
    } else {
      return null
    }
  }

  export const getPrice = (calcData, combinedName) => {

    const foundJob = calcData.jobs_test.find(j => {
      return j.name === combinedName})

    return foundJob ? parseFloat(foundJob.price) : null
  }

  export const getIndexedName = (indexedName) => {
    
    const sorted = indexedName.sort((a,b) => {
      return Object.keys(a) - Object.keys(b)
    })

    let tempName = '';
    
    sorted.forEach((n,i) => {
      // if(i === 0){
      //   tempName += itemName
      // }
      tempName += Object.values(n)
    })

    return tempName
  }

  export const getIndexedPrice = (calcData, indexedName) => {

//17,18,19,20,21,22

    let combinedName = ''

    let orderedName = indexedName.sort()

    orderedName.forEach(name => `${combinedName} ${name ?? ''}`)

    console.log('in price',orderedName)

    const foundJob = calcData.jobs_test.find(j => {
      return j.name === combinedName})

    return foundJob ?  parseFloat(foundJob.price) : null
  }

  export const getSumResults = (projects = []) => {
    let sum = projects.reduce((total,project) => {
      const foundResult = project.results.find(res => res.active === true)
      const tempResult = foundResult ?? project.results[0] 

      return total + (tempResult ? tempResult.overall : 0)
    },0)

    return Math.round(sum * 100) / 100;
  }

  export const getSumDays = (projects = []) => {

    let days = projects.reduce((total,project) => {
      const foundResult = project.results.find(res => res.active === true)
      const tempResult = foundResult ?? project.results[0]
      let tempDays = 0
      if(tempResult && tempResult.startDate && tempResult.endDate){
        tempDays = getDaysOfBudget(tempResult.startDate,tempResult.endDate)
      }
      return total + tempDays
    },0)

    return days
  }

  export const getRangeDays = (projects = []) => {

    const tempStarts = []
    const tempEnds = []

    projects.forEach((project) => {
      project.results.filter(res => res.active === true).forEach(result => {
        if(result.startDate){
          tempStarts.push(new Date(result.startDate))
        }
        if(result.endDate){
          tempEnds.push(new Date(result.endDate))
        }
      })
    })

    const sortedStarts = tempStarts.sort((a, b) => a - b);
    const sortedEnds = tempEnds.sort((a, b) => a - b).reverse();

    const days = sortedEnds[0] - sortedStarts[0]

    // console.log('RANGE DATES',days,sortedEnds,sortedStarts)

    return days / (1000 * 3600 * 24)
  }

  export const getStartDates = (projects = [],whatDate = 'startDate') => {

    const tempStarts = []

    projects.forEach((project) => {
      project.results.filter(res => res.active === true).forEach(result => {

        if(result[whatDate]){
          tempStarts.push(new Date(result[whatDate]))
        }
      })
    })
    const sorted = tempStarts.sort((a, b) => a - b);

    return sorted.length > 0 ? sorted[0] : 'N/A'
  }

  export const getSumOfJobs = (jobs) => {
    // console.log('calculating jobs',jobs)
    
    return 100
  }

  export const getCalculation = (calcData, project, mScene) => {
  
    let mLayer = mScene.layers[0]
  
    let jobs = []

    let PEM1_execution = 0;
    let PEM1_materials = 0;
  
    let execution = 0;
    let materials = 0;
    let coeff = 1;
  
    console.log('BEFORE CALC',mLayer)
    
    if(mLayer !== null){
        mLayer.areas && mLayer.areas.forEach(area => {
            area.floor.decisions.forEach(dec => {
              jobs = jobs.concat(getJobsFromWorks(area.id,dec.id_dec,dec.id_works,area.floor.measurements,calcData,project,area.floor.features,area))        
            })
            
            area.ceiling.decisions.forEach(dec => {
              jobs = jobs.concat(getJobsFromWorks(area.id,dec.id_dec,dec.id_works,area.ceiling.measurements,calcData,project,area.ceiling.features,area))
            })
        })

        mLayer.lines.forEach(wall => {
          const area = getAreaOfElement(mLayer,wall.id)
          
          // console.log('INSIDE GET JOBS W',area)
          wall.decisions.forEach(dec => {
            jobs = jobs.concat(getJobsFromWorks(wall.id,dec.id_dec,dec.id_works,wall.measurements,calcData,project,wall.features,area))
          })
        })
  
        // const aAppliances = ['wardrobes','heatings','coolings','electrics','batheqs','bathfurs','kitcheneqs','kitchenfurs','doors','windows']
  
        mLayer.items.forEach(item => {
          const area = getAreaOfElement(mLayer,item.id)

          // console.log('decisions for ',item.id,area,item.decisions)

          if(area){
            item.decisions.forEach(dec => {
              jobs = jobs.concat(getJobsFromWorks(item.id,dec.id_dec,dec.id_works,item.measurements,calcData,project,item.features,area))
            })          
          } else {
            item.decisions.forEach(dec => {
              jobs = jobs.concat(getJobsFromWorks(item.id,dec.id_dec,dec.id_works,item.measurements,calcData,project,item.features,area))
            })            
          }
        })

        mLayer.holes.forEach(hole => {
          const area = getAreaOfElement(mLayer,hole.id)
          // console.log('decisions for HOLES',hole.id,hole.decisions)
          hole.decisions.forEach(dec => {
            // console.log('INSIDE GET JOBS H',area)
                jobs = jobs.concat(getJobsFromWorks(hole.id,dec.id_dec,dec.id_works,hole.measurements,calcData,project,hole.features,area))
            })
        })
  
        // console.log('SCENE',mScene)

        mScene.house.forEach(houseItem => {
          const area = getAreaOfElement(mLayer,houseItem.id)

          // console.log('decisions for ',houseItem.id,houseItem.decisions)
          houseItem.decisions.forEach(dec => {
            // console.log('INSIDE GET JOBS -',area)
            jobs = jobs.concat(getJobsFromWorks(houseItem.id,dec.id_dec,dec.id_works,houseItem.measurements,calcData,project,houseItem.features,area))
          })
        })
        // mLayer.getIn(['house','decisions']).forEach(dec => {
        //   jobs = jobs.concat(getJobsFromWorks('000000',dec.get('id_dec'),dec.get('id_works'),mLayer.getIn(['house','measurements']),calcData))
        // })
        // mLayer.getIn(['house','energy','decisions']).forEach(dec => {
        //   jobs = jobs.concat(getJobsFromWorks('000002',dec.get('id_dec'),dec.get('id_works'),mLayer.getIn(['house','energy','measurements']),calcData))
        // })
        // mLayer.getIn(['house','elpanel','decisions']).forEach(dec => {
        //   jobs = jobs.concat(getJobsFromWorks('000003',dec.get('id_dec'),dec.get('id_works'),mLayer.getIn(['house','elpanel','measurements']),calcData))
        // })
  
      // PEM 1 execution

      console.log('ALL JOBS',jobs)

      // console.log('IN CALC',jobs.map(j => [j,j.price,j.value]))
  
      PEM1_execution = jobs.filter(jb => jb.type !== 1).map(j => j.value).reduce((total,v) => total + v,0)
      PEM1_materials = jobs.filter(jb => jb.type == 1).map(j => j.value).reduce((total,v) => total + v,0)
    }
  
    if(PEM1_execution <= 5000){
      coeff = 1.1 * 1.07;
    } else if (PEM1_execution > 5000 && PEM1_execution <= 20000) {
      coeff = 1.1 * 1.06;
    } else if (PEM1_execution > 20000 && PEM1_execution <= 50000) {
      coeff = 1.1 * 1.04;
    } else if (PEM1_execution > 50000 && PEM1_execution <= 100000) {
      coeff = 1.1 * 1.02;
    } else {
      coeff = 1.1;
    }

    // if(PEM1_execution <= 5000){
    //   coeff = calcData.cities[project.defaults.city].coeff * 1.05;
    // } else if (PEM1_execution > 5000 && PEM1_execution <= 20000) {
    //   coeff = calcData.cities[project.defaults.city].coeff * 1.04;
    // } else if (PEM1_execution > 20000 && PEM1_execution <= 50000) {
    //   coeff = calcData.cities[project.defaults.city].coeff * 1.03;
    // } else if (PEM1_execution > 50000 && PEM1_execution <= 100000) {
    //   coeff = calcData.cities[project.defaults.city].coeff * 1.01;
    // } else {
    //   coeff = calcData.cities[project.defaults.city].coeff;
    // }
  
    // console.log('- before',calcData.cities[state.getIn(['defaults','project','city'])],calcData.cities[state.getIn(['defaults','project','city'])].coeff,jobs.map(j => {return [j.price,j.value]}))
  
    jobs.filter(jb => jb.type !== 1).map(j => {
      j.price = parseFloat(j.price) * coeff
      j.value = j.value * coeff
      return j
    })
  
    // console.log('- w/coeff ',jobs.map(j => {return {dec_id:j.dec_id,unit:j.unit,price:j.price,value:j.value}}))
  
    execution = jobs.filter(jb => jb.type !== 1).map((j,i) => {
      // console.log('----- IN EXECUTION ',j.value,' - ',coeff,' - ',j)
      return j.value}).reduce((total,v) => total + v,0)

    materials = jobs.filter(jb => jb.type == 1).map(j => {
      // console.log('----- IN MATERIAL ',j.value,' - ',coeff,' - ',j)
      return j.value}).reduce((totalMaterials,v) => {
        return totalMaterials + v},0)
  
  
    // console.log('-  after calculations',jobs.map(j => {return {j_id:j.id,dec_id:j.dec_id,unit:j.unit,price:j.price,value:j.value}}))
    // console.log('-  after calc',execution,materials,jobs) 
    
    return {jobs:jobs,overall:execution + materials,execution:execution,materials:materials}
  }

  export function getJobsFromWorks(id,dec,works,measurements,calcData,project,features,area){

    // console.log('---------------in getJobs',id,dec,calcData.works_test)

    const jobs = [];
  
    const aWorks = String(works).split(',').map(w => parseInt(w,10))
    aWorks.forEach(w => {
      const aJobs = calcData.works_test[w].code_jobs.split(',')
      aJobs.forEach(job => {

        // console.log('-----------JOB',job)

        if(job !== ""){
          const aJobCode = job.split('_')
          const id_job = parseInt(aJobCode[0])
          const coef = parseFloat(aJobCode[1])
          const unit = calcData.units[calcData.jobs_test[id_job].id_unit]// 0:m, 1:m2, 4:unit
          const measurement = measurements ? unit.feature ? measurements[unit.feature] : 1 : 1
          let price = parseFloat(calcData.jobs_test[id_job].price);

          const localizedPrice = getLocalizedPrice(calcData.jobs_test[id_job],project)

          if(localizedPrice){
            // console.log('PRICE FOR',project.defaults.city,localizedPrice,'FOR JOB:',id_job)
            price = localizedPrice;
          }

          const value = unit === '' ? price * coef : measurement * price * coef;
          // console.log('calc = job: ',id_job,job,"price:",price,"value:",value,calcData.jobs_test[id_job])
          jobs.push({
            id:id,
            id_space:calcData.jobs_test[id_job].id_space ?? null,
            room:features ? features[0] : null,
            area:area ? area.id : null,
            id_job:id_job,
            job:calcData.jobs_test[id_job],
            dec_id:dec,
            id_chapter:calcData.jobs_test[id_job].id_chapter,
            measurement:measurement * coef,
            coef:coef,
            unit:unit.tag,
            price:parseFloat(price),
            value:value,
            type:calcData.jobs_test[id_job].type,
          })
        }
      })
    })
  
    return jobs
  }

  const getLocalizedPrice = (job,project) => {
    let zip = '';
    let foundPrice = null;

    if(project.defaults.city){
      zip = project.defaults.city.slice(0,2);
      foundPrice = job['price_' + zip] ?? null 
    }
    
    return foundPrice
  }

  export const cleanResults = (results) => 
    results.map(result => ({
      active:result.active ? result.active : false,
      description : result.description ? result.description : '',
      startDate: result.startDate ? result.startDate : '',
      endDate: result.endDate ? result.endDate : '',
      expDate: result.expDate ? result.expDate : '',
      execution : result.execution,
      jobs: result.jobs.map(job => job.id == undefined ? {...job, id: "-"}: {...job, id: job.id }),
      materials: result.materials,
      date: result.date ? result.date : null,
      overall: result.overall,
      resultImages: result.resultImages || []
    })
  )
  
  export const cleanScenes = (scenes) => {
    scenes = scenes.map((scene,i) => {
      const tempLayer = scene.layers[0]

      if(i === 0){
        tempLayer.areas.map(a => {
          // a.features = a.features.slice(0,8)
          a.floor.features = a.floor.features.slice(0,8)
          a.ceiling.features = a.ceiling.features.slice(0,8)
          return a
        })
      }

      // const concated = [].concat(tempLayer.items,tempLayer.holes,tempLayer.areas,tempLayer.lines).forEach(element => {
      //   console.log('===CHECKIN',element.id,element.decisions)
      //   if(element.type === 'area'){
      //     console.log('===CHECKIN',element.floor.id,element.floor.decisions)
      //     console.log('===CHECKIN',element.ceiling.id,element.ceiling.decisions)
      //   }
      // })

      scene.layers[0] = tempLayer
      return scene
    })
    
    return scenes
  }

  function checkIfInRange (digit,range) {
    const aComa = range.split(',');
    let result = [];
    aComa.forEach((segm,i) => {
      if(segm.indexOf('-') !== -1){
        const aSeg = segm.split('-');
        if(parseInt(digit) >= parseInt(aSeg[0]) && parseInt(digit) <= parseInt(aSeg[1])){
          result.push(true);
        }
      } else if(Array.isArray(digit)){
        if (parseInt(digit[0]) == parseInt(aComa[i])) {
          result.push(true);
        }
      } else {
        if (parseInt(digit) == parseInt(aComa[i])) {
          result.push(true);
        }
      }
    })
    // console.log('single',digit,'=>',range,result.some(b => b === true));
    return result.some(b => b === true);
  }