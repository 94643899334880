import { useState, useEffect, useContext } from 'react'
import ProjectContext from '../../context/ProjectContext'
import SceneContext from '../../context/SceneContext'
import QnrContext from '../../context/QnrContext'
import { getThumb } from '../../utils/formatUtils'
import { checkValueFilter, getElementData, getElementOfId, getFeatureValue, getGroupFeatureValue } from '../../utils/sceneUtils'
import Thumb from './Thumb'
import { setGroup } from '@amplitude/analytics-browser'

const MaterialSelector = ({option, localStyle, localSubstyle, action, itemId, groupId}) => {

    const projectActions = useContext(ProjectContext)
    const sceneActions = useContext(SceneContext)
    const qnrActions = useContext(QnrContext)

    const [elementData, setElementData] = useState(getElementData(projectActions.elementsData,option.id_element))
    const [propertyData, setPropertyData] = useState(null)
    const [activeLayer, setActiveLayer] = useState(null)
    const [item, setItem] = useState(null)
    const [oldValue, setOldValue] = useState(null)
    const [group, setGroup] = useState(null)

    useEffect(() => {
        const layer = projectActions.scenes[1].layers[0]
        setActiveLayer(layer)

        if(layer.groups && groupId){
           
            const foundGroup = layer.groups.find(group => group.id === groupId);

            if(foundGroup){
                setGroup(() => foundGroup)
                setOldValue(getGroupFeatureValue(layer,foundGroup.id,3))
            }
        }

        if(parseInt(option.id_element) === 2){
            setItem(layer.areas[0].floor)
        } else if(itemId){
            setItem(getElementOfId(layer,null,itemId))
        }
        if(item){
            setOldValue(getFeatureValue(item,3))
        }
        console.log('REFRESHING',itemId,oldValue,item,group,layer)
    },[sceneActions.selected])

    useEffect(() => {
        const data = getElementData(projectActions.elementsData,parseInt(option.id_element))
        setElementData(() => data)
        setPropertyData(() => data ? data.properties.find(property => property.name === option.property) : null)
    },[localStyle,localSubstyle,projectActions.refresh])

    const handleAction = (value) => {
        console.log('ACTION ',item,value)
        if(group){
            action(value,option.id_element,group.id)
            setOldValue(value)
        } else {
            action(value,option.id_element)
            setOldValue(value)
        }
    }

    return <div><div style={{color:'red'}}>{itemId}</div>
        {/* el id: {oldValue} {option.id_element} v: {qnrActions.activeVariant} L: {localStyle} - {localSubstyle} - {itemId} */}
        {option.thumb ? <div className="thumbs">
            {propertyData && propertyData.values.filter((value) => {
                const materialFilter = [{"f":3,"v":[oldValue]}]
                const styleFilter = [{"f":23,"v":[localStyle]}]
                const testFilters = checkValueFilter(value,[...materialFilter,...styleFilter])
                return testFilters
            }).map((val,i) => {
                return <Thumb key={i} value={val} oldValue={oldValue} action={() => handleAction(val.value,option.id_element)} />
            })}
        </div>
        :
        <select>
        {propertyData && propertyData.values.filter((value) => {
                const materialFilter = [{"f":3,"v":[oldValue]}]
                const styleFilter = [{"f":23,"v":[localStyle]}]
                const testFilters = checkValueFilter(value,[...materialFilter,...styleFilter])
                return testFilters
            }).map((val,i) => {
                return <option key={i} value={val.value}>{val.name}</option>
            })}
        </select>}
    </div>
}

export default MaterialSelector