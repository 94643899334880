import React, { useEffect, useState} from "react";
import whiteSpace from "../../assets/icons/whiteSpace.png";
import { getThumb } from '../../utils/formatUtils';

/**
 * Image thumb detail(acabado) component.
 *
 * @param prop Props.
 * @param prop.jobName Job name to find image.
 * @param prop.allElements Json elements to find image.
 * @return React element ImageDetail component.
 */
const ImageDetail = ({ jobName, job, allElements }) => {
    const [imgDetail, setImgDetail] = useState(undefined);


    
    const getImageByJob = () => {
        const nameArray = jobName.split('|');
        let tempName = nameArray[0]
        switch (nameArray[0]){
            case 'Suelo':
                tempName = "floor"
            break;
            case 'Pared':
                tempName = "wall"
            break;
        }

        const elementData = allElements.find(el => el.name.includes(tempName))

        if(elementData) {
            const foundProp = elementData.properties.find(prop => prop.image  === true)

            console.log('IN IMAGE DETAIL JN:',job.name,'FP:',foundProp)

            if (foundProp) {
                let modelFromName = nameArray[1]
                switch (elementData.name){
                    case 'wall':
                    case 'floor':
                        modelFromName = nameArray[2]
                    break;
                }
                const imgObject = foundProp.values.find(val => val.name === modelFromName)
                const imgTemp = imgObject && imgObject.img && imgObject.img.length
                    ? process.env.PUBLIC_URL + getThumb(imgObject.img[0])
                    : whiteSpace;
                setImgDetail(imgTemp)
            } else {
                setImgDetail(whiteSpace)
            }
        }
    };

    useEffect(() => {        
        if(job && job.img !== ''){
            setImgDetail(process.env.PUBLIC_URL + getThumb(job.img))
        } else {
            getImageByJob() 
        }
    }, [jobName]);

    return (
        <>
            <img
                className='_image'
                src={imgDetail}
                alt={''}
            /> 
        </>
    );
}

export default ImageDetail;
