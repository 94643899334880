import { useState, useEffect, useContext } from 'react'
import ProjectContext from '../../context/ProjectContext'
import SceneContext from '../../context/SceneContext'
import QnrContext from '../../context/QnrContext'
import { checkFilterItem, checkValueFilter, getElementData, getElementOfId, getFeatureValue, getGroupFeatureValue, getItem, getOldValue } from '../../utils/sceneUtils'
import { elementIdToName } from '../../utils/formatUtils'

const ActionSelect = ({q, action, groupID, itemID}) => {
    const projectActions = useContext(ProjectContext)
    const sceneActions = useContext(SceneContext)

    const [elementData, setElementData] = useState(null)
    const [propertyData, setPropertyData] = useState(null)
    const [item, setItem] = useState(null)
    const [oldValue, setOldValue] = useState(null)


    useEffect(() => {   
        const data = getElementData(projectActions.elementsData,parseInt(q.id_element))
        const layer = projectActions.scenes[q.table === 'initialTable' ? 0 : 1].layers[0]
        let item = null;
        if(sceneActions.selected){
            item = getItem(layer,q.id_element,null,[],sceneActions.selected)
        } else if (groupID) {
            const group = layer.groups.find(g => g.id === groupID)
            if(group){
                item = getItem(layer,group.items[0])
            }
        } else if (itemID) {
            item = getItem(layer,q.id_element,null,[],itemID)
        } else {
            item = getItem(layer,q.id_element)
        }
            console.log('====SELECTER',item)
        const oldVal = getFeatureValue(item,q.column,q.id_element)
        // console.log('----IN VALUE',layer)
        setOldValue(() => oldVal ?? null)
    },[sceneActions.refresh])

    return <label>{oldValue} {groupID} {itemID}<select 
            value={oldValue}
            className="select-property"
            onChange={action}
            data-groupid={groupID}
            data-itemid={itemID}
            data-values={q.values ? JSON.stringify(q.values) : ''}
        >
        {q.values.filter((value,i) => {
            let testFilters = true
            if(value.filter && item !== null){
                testFilters = checkFilterItem(item,value.filter)
            }
            return testFilters
        }).map((val,i) => {
            return <option 
                key={i} 
                value={i}
            >{val.label}</option>
        })}
    </select>feature: {propertyData?.feature}</label>
}

export default ActionSelect