import { useEffect, useContext, useState } from 'react'
import Modal from '../widgets/Modal'
import AuthContext from '../../context/AuthContext'
import ProjectContext from '../../context/ProjectContext'
import { MdOutlineWarningAmber } from 'react-icons/md'
import { FiEdit, FiSave, FiUpload } from 'react-icons/fi'
import { AiOutlineDelete, AiOutlineInfoCircle } from 'react-icons/ai'
import { IoDocumentAttach } from 'react-icons/io5'
import { Button, Input, Checkbox, message } from 'antd'
import ImageUpload from './ImageUpload'
import Output from "../budget/Output";
import Budget from "../budget/Budget";
import { getChapters } from '../../utils/budgetUtils'
import ExcelExportBudget from "../budget/ExportBudget/ExcelExportBudget";
import { Image, DatePicker } from 'antd'
import Table from './Table'
const { RangePicker } = DatePicker;

const Results = () => {

    const authActions = useContext(AuthContext)
    const projectActions = useContext(ProjectContext)

    const [results,setResults] = useState(projectActions.results)

    useEffect(() => {
      setResults(() => projectActions.results)
    },[projectActions.results])

    return (
        <div>
            {(results !== null && results.length > 0) 
                ? <table className="r-table" style={{width:'100%',margin:'3em 0'}} border="0">
                <tbody>
                  <tr className="r-header">
                    <td>Nº</td>
                    <td>+</td>
                    <td>Precio</td>
                    <td>Duración</td>
                    <td>Fecha de inicio</td>
                    <td>Fecha de final</td>
                    <td>Fecha de confirmación</td>
                    <td>Comentarios</td>
                    <td>FloorPlan</td>
                    </tr>
                    {results.map((result,i) => {
                      return <ResultsRow result={result} key={i} i={i} />
                    })}
                    </tbody>
                </table>
                : <h3>No results found</h3>
            }
            {/* {authActions.user.profile_type === "admin" && <SceneTable />} */}
            <div>
              <div className='b-table'><Table sceneId={0}/></div>
              <div className='b-table'><Table sceneId={1}/></div>
            </div>
        </div>
    )
}

const ResultsRow = ({result,i}) => {
  const projectActions = useContext(ProjectContext)
  const { user } = useContext(AuthContext)

  const [edit, setEdit] = useState(false)  
  const [visible, setVisible] = useState(false);
  const [checked, setChecked] = useState(false);
  const [chapters, setChapters] = useState(null)
  const [form, setForm] = useState({
    startDate: result.startDate || null,
    endDate: result.endDate || null,
    expDate: result.expDate || null,
    description: result.description || null,
    active: result.active || false, 
  })
  
  useEffect(() => {
    form.active = result.active
    getChapters(result, projectActions.calcData).then((resultTemp) => setChapters(resultTemp))
  },[projectActions.results[i]])

  const handleOpenResult = (i) => {

    console.log('||||||||||||',projectActions.results[i])

    projectActions.setModalData(( 
        <div className={'flex flex-col justify-center items-center w-full h-full'}>
          <div className={'p-4 modal-budget-basic bg-white overflow-auto'}>
            <div className='c-title'>Presupuesto detallado ---
            </div>
            {projectActions.results[i].expDate && <div style={{marginBottom:'1em'}}><span className="exp-date">*Fecha inicio válida si contratas antes del {projectActions.results[i].expDate}</span></div>}
            {/* <Output wideonly={false} result={projectActions.results[i]} /> */}
            <Budget result={projectActions.results[i]} isDownload={true} isBasicBudget={false}/>
          </div>
        </div>
    ))
    projectActions.setModal(true)   
  }

  const handleDeleteResult = (i) => {
    console.log('del',i)
    const content = <div className="m-confirm">
      <div><MdOutlineWarningAmber size="2em"/> Are You sure to delete result ?</div>
        <div className='m-btns'>
          <div className="q-navbtn" onClick={handleCancel}>Cancel</div>
          <div className="q-navbtn on" onClick={() => {projectActions.deleteResult(i);handleCancel()}}>Eliminar</div>
        </div>
      </div>
    projectActions.setModalData(content)
    projectActions.setModal(true)
  }
  
  const handleUpdateResult = () => {
    if(form.endDate < form.startDate) return message.error({content:'End date cannot be before Start Date'});
    let newResults = projectActions.results

    if(form.active === true){
    //   // newResults = newResults.forEach((res,j) => {if(j !== i){res.active = false}})
    //   newResults = newResults.forEach(res => {
    //     console.log('---',res)
    //     return res
    //   })
      newResults = newResults.map((res,j) => {if(j !== i){
          res.active = false;
        }
        return res;
      })
    }
    
    newResults[i] = {...newResults[i], ...form}
    console.log('RES',form,newResults)
    projectActions.updateResult(newResults)
    setEdit(false);
  }
  
  const handleCancel = () => {
    projectActions.setModalData(null)
    projectActions.setModal(null)    
  }
  
  const convertDate = (dat) => {
    const tempDate = new Date(dat)

    const year = tempDate.getFullYear();
    let month = tempDate.getMonth()+1;
    let dt = tempDate.getDate();
    let hr = tempDate.getHours();
    let min = tempDate.getMinutes()

    if (dt < 10) {
    dt = '0' + dt;
    }
    if (month < 10) {
    month = '0' + month;
    }
    return year +'-' + month + '-'+ dt ;
  }

  const handleChange = event => {
    setForm({...form, [event.target.name]: event.target.value ? event.target.value : event.target.checked})
    console.log('changing',event.target.value,event.target.checked,form)
  }
  const handlefiles = () => {
    projectActions.setModalData(<ImageUpload files={result.resultImages ? result.resultImages : []} resultNum={i} /> )
    projectActions.setModal(true)
  }

  const handleDateChange = (event,value) => {
    console.log('Date',event,value)
  }

  const handleRangeChange = (event,value) => {
    console.log('Range',event)
  }

  const handleOnlyChoice = (event,value) => {
    setChecked(event.target.checked);
    console.log('Range',checked)
  }

  return  (
    <tr style={{
      background:result.active && result.active === true ? '#f0f0f0' : 'none'
    }}>
      <td>{result.name && result.name !== '' ? result.name : ' #' + i}</td>
      <td>
      {user.profile_type === "admin" ?
      <Checkbox
        name="active"
        disabled={!edit}
        onChange={handleChange}
        checked={form.active === true}
      /> : ''}
      </td>
      <td onClick={() => handleOpenResult(i)} style={{color: 'darkblue', cursor: "pointer"}} >{result.overall.toFixed(2)}&nbsp;€</td>
      <td>{form.startDate && form.endDate ? (new Date(form.endDate) - new Date(form.startDate))/ (1000 * 3600 * 24) + ' días' : 'NA'} </td>
      {/* <td>
      {user.profile_type === "admin" ?
        <RangePicker 
          disabled={!edit}
          onChange={handleRangeChange}
        />
        : form.startDate
      }
      </td> */}
      <td>
      {user.profile_type === "admin" ?
        <Input
          type="date"
          name="startDate"
          format='DD/MM/YYYY'
          disabled={!edit} 
          defaultValue={form.startDate}
          onChange={handleChange}
          style={{ padding: 4}}
        />
        : form.startDate
      }
      </td>      
      <td>
      {user.profile_type === "admin" ?
        <Input
          type="date"
          name="endDate"
          disabled={!edit}
          defaultValue={form.endDate}
          onChange={handleChange}
          style={{ padding: 4}}
        />
        : form.endDate
      }
      </td> 
      <td>    
      <Input
          type="date"
          name="expDate"
          disabled={!edit}
          defaultValue={form.expDate}
          onChange={handleChange}
          style={{ padding: 4}}
        />
      </td> 
      {/* <td>
      {user.profile_type === "admin" ?
        <DatePicker 
          name="expDate"
          disabled={!edit}
          defaultValue={form.expDate}
          onChange={handleDateChange}
        />
        : form.endDate
      }

      </td> */}
      <td>
        <Input
          type="text"
          name="description"
          disabled={!edit}
          defaultValue={result.description && result.description}
          onChange={handleChange}
          style={{ padding: 4}}
        />
      </td>
      <td>
        {result.resultImages && result.resultImages.length > 0 && <>
        <Image preview={{ visible: false, current: 1 }} width={80} height={80} src={result.resultImages[0].url} onClick={() => setVisible(true)}/>
          <div style={{ display: 'none' }}>
            <Image.PreviewGroup preview={{ visible, onVisibleChange: vis => setVisible(vis) }}>
              {result.resultImages.map((img,j) => <Image key={j} src={img.url} />)}
            </Image.PreviewGroup>
          </div>
        </>}
      </td>
      <td className="r-btns">
        <Button
          shape="round"
          type="primary"
          title="Edit result"
        >
          {edit ? 
            <FiSave onClick={handleUpdateResult} size={17} /> : 
            <FiEdit onClick={() => setEdit(true)} size={17} /> 
          }
        </Button>
        <Button
          shape="round"
          type="primary" 
          title="Upload Images"
          onClick={handlefiles}
        >          
          <FiUpload size={17} />
        </Button>
        <Button
          shape="round"
          type="primary"
          title="Open results"
          onClick={() => handleOpenResult(i)}
        >
          Contratar
        </Button>
        {chapters && <ExcelExportBudget jobs={chapters} />}
        <Button
          shape="round"
          type="primary"
        >
          PDF
        </Button>
        <Button
          shape="round"
          type="danger"
          onClick={() => handleDeleteResult(i)}
        > 
          <AiOutlineDelete size={17} />
        </Button>
      </td>
    </tr>
    )
}

export default Results
