import { useState, useEffect, useContext } from 'react'
import ProjectContext from '../../context/ProjectContext'
import SceneContext from '../../context/SceneContext'
import QnrContext from '../../context/QnrContext'
import { checkFilterItem, checkValueFilter, getElementData, getElementOfId, getFeatureValue, getGroupFeatureValue } from '../../utils/sceneUtils'
import { elementIdToName } from '../../utils/formatUtils'

const PropertySelect = ({option, action, itemID, groupID}) => {
    const projectActions = useContext(ProjectContext)
    const sceneActions = useContext(SceneContext)

    const [elementData, setElementData] = useState(getElementData(projectActions.elementsData,option.id_element))
    const [propertyData, setPropertyData] = useState(null)
    const [item, setItem] = useState(null)
    const [oldValue, setOldValue] = useState(null)
    const [group, setGroup] = useState(null)

    useEffect(() => {
        const data = getElementData(projectActions.elementsData,parseInt(option.id_element))
        setElementData(() => data)
        setPropertyData(() => data ? data.properties.find(property => property.name === option.property) : null)

        const layer = projectActions.scenes[1].layers[0]

        if(layer.groups && groupID){            
            const foundGroup = layer.groups.find(group => group.id === groupID);
            if(foundGroup){ 
                setGroup(() => foundGroup)
                setOldValue(() => getGroupFeatureValue(layer,foundGroup.id,propertyData?.feature))
                if(foundGroup.items.length > 0){
                    const tempItem = getElementOfId(layer,null,foundGroup.items[0])
                    setItem(tempItem)
                }
            }
        }
        
        if(parseInt(option.id_element) === 2){
            setItem(layer.areas[0].floor)
        } else if(itemID){
            setItem(getElementOfId(layer,null,itemID))
        }
        
        if(item){ 
            setOldValue(getFeatureValue(item,propertyData?.feature))
        }
        
        console.log('---BEFORE',item)

        projectActions.setRefresh(!projectActions.refresh)
    },[])

    useEffect(() => {
        const layer = projectActions.scenes[1].layers[0]
        
        if(layer.groups && groupID){            
            const foundGroup = layer.groups.find(group => group.id === groupID);
            
            if(foundGroup){
                setGroup(() => foundGroup)
                // setOldValue(() => getGroupFeatureValue(layer,foundGroup.id,(propertyData && propertyData.feature) ? propertyData.feature : null))
                if(foundGroup.items.length > 0){
                    const tempItem = getElementOfId(layer,null,foundGroup.items[0])
                    setItem(() => tempItem)
                }
                // console.log('---AFTER',foundGroup,oldValue)
            }
        }

        if(parseInt(option.id_element) === 2){
            setItem(layer.areas[0].floor)
        } else if(itemID){
            setItem(getElementOfId(layer,null,itemID))
        }

        if(item){ 
            setOldValue(() => getFeatureValue(item,propertyData?.feature))
        }

        // console.log('REFRESHING',item,oldValue,propertyData?.feature)
    },[sceneActions.selected,projectActions.refresh,elementData])

    const handleAction = (e) => {
        const {value,dataset,options,selectedIndex} = e.target
        let act = [];
        if(value){
            act = [{a:'set',e:elementIdToName(option.id_element),f:parseInt(dataset.feature),v:parseInt(value)}]
        }
        if(options[selectedIndex].dataset.connect){
            const connections = JSON.parse(options[selectedIndex].dataset.connect)
            const allConnections = connections.map(c => {return {a:'set',e:elementIdToName(option.id_element),f:c.f,v:c.v}})
            act = act.concat(allConnections)
        }        
        action(act)
        setOldValue(value)
    }

    return <label><select 
            value={oldValue}
            className="select-property"
            onChange={handleAction}
            data-groupid={groupID}
            data-itemid={itemID}
            data-feature={propertyData?.feature ? propertyData.feature : null}
        >
        {propertyData && propertyData.values.filter((value,i) => {
            let testFilters = true
            if(value.filter && item !== null){
                testFilters = checkFilterItem(item,value.filter)
            }
            return testFilters
        }).map((val,i) => {
            return <option 
                key={i} 
                value={val.value}
                data-connect={JSON.stringify(val.set)}
            >{val.label} ({val.value})</option>
        })}
    </select>feature: {propertyData?.feature}</label>
}

export default PropertySelect