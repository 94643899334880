import ProjectContext from "../../context/ProjectContext";
import React, {useContext, useEffect, useState} from "react";
import {getDateOfBudget} from "../../utils/budgetUtils";
import {currencyFormat} from "../../utils/formatUtils";

/**
 * Footer budget component.
 *
 * @param prop Props.
 * @param prop.result Results by projectActions.
 * @param prop.amountTotal Amount total of the budget.
 * @return React element FooterBudget component.
 */
const FooterBudget = ({result, amountTotal, scope}) => {
    const projectActions = useContext(ProjectContext)
    const [dateBudget, setDateBudget] = useState('');

    if (!result) result = projectActions.results[0];

    console.log('IN BUDGET',result)

    useEffect(() => {
        if (result) setDateBudget(getDateOfBudget(result.startDate, result.endDate, scope))
    }, [result])

    return (
        <div className={'flex justify-between w-full mt-4 text-lg'}>
            <div>
                {/* <span className={'font-bold'}>Duración de la Reforma: </span>
                <span className={'_textDataGrayIva'}>{dateBudget}</span> */}
            </div>
            <div className={'flex'}>
               <p className={'font-bold'}>Precio total:</p>
                &nbsp;&nbsp;
                <div>
                    <p className={'price-total font-bold m-0 text-right'}>{currencyFormat(amountTotal)}</p>
                    <p className={'_textDataGrayIva text-right'}>IVA no incluido</p>
                </div>
            </div>
        </div>
    )
}

export default FooterBudget;
