import React, {useContext} from "react";
import {Collapse, Modal} from 'antd';
import ImageDetail from "./ImageDetail";
import {currencyFormat} from "../../utils/formatUtils";
import ProjectContext from "../../context/ProjectContext";
import {useLocation} from "react-router-dom";
import ModalDescriptionJob from "./ModalDescriptionJob";
import {HiArrowRight} from "react-icons/hi";
const { Panel } = Collapse;

/**
 * Budget type list.
 *
 * @param prop Props.
 * @param prop.results Results parsed by budgetUtils.getChapters
 * @param prop.isActive Is project is active.
 * @return React element BudgetList component.
 */

const BudgetList = ({results, isActive, blur}) => {
    const projectActions = useContext(ProjectContext)
    const location = useLocation();
    const allElements = [].concat(
        projectActions.elementsData.elements,
        projectActions.elementsData.items,
        projectActions.elementsData.holes
    )

    const closeModal = () => {
        Modal.destroyAll()
    }

    const showModalDetailJob = (job) => {

        if (location.pathname.includes('summary') || location.pathname.includes('project')) {
            Modal.info({
                content: <ModalDescriptionJob
                    job={job}
                    closeModal={closeModal}
                    allElements={allElements}
                    isActive={isActive}
                />,
                centered: true,
                icon: false,
                style: {minWidth: '65%', maxWidth: '99%'},
                className: 'custom-modal-budget',
                closable: true,
                maskClosable: true,
                okButtonProps: {style: !isActive
                        ? {
                            backgroundColor: isActive ? '#fff' : '#050850FF',
                            border: 0,
                            height: 'auto',
                            padding: 14
                        }
                        : {display: "none"}
                },
                onOk: () => {
                    !isActive && window.open('https://meetings-eu1.hubspot.com/estrella-sanchez','_blank')
                },
                okText: <span style={{display: 'flex'}} className="flex items-center gap-3">
                    <span>Quiero hablar con mi asesor</span>
                    <HiArrowRight size="1em"/>
                </span>
            });
        }
    };

    return (
        <div className="custom-budget-list">
            <div className="flex items-center justify-between">
                <h3 className="truncate color-blue-dark">Fases de la reforma</h3>
                <h3 className="truncate color-blue-dark">Importe</h3>
            </div>
            <Collapse >
                {results &&
                    results.jobs &&
                    results.jobs.length &&
                    results.jobs.map((job,i) =>
                    <Panel collapsible={i > 1 && blur === true ? "disabled" : 'header'} header={
                        <span className={"flex justify-between items-center" + (i > 1 && blur === true ? ' q-blur' : '')}>
                            <span className="color-blue font-bold truncate max-w-80">{job.name_es}</span>
                            <span className="color-blue-dark text-title-lg">
                                {i > 1 && blur === true ? '999,99' : currencyFormat(job.amountTotal.toFixed(2))}
                                {/* {currencyFormat(job.amountTotal.toFixed(2))} */}
                            </span>
                        </span>
                    } key={job.key}>
                        <DetailJobList
                            jobs={job.jobs.work.data}
                            type="work"
                        />
                        <div className="line-between-jobs"></div>
                        <DetailJobList
                            jobs={job.jobs.detail.data}
                            allElements={allElements}
                            showModalDetailJob={showModalDetailJob}
                            type="detail"
                        />
                    </Panel>
                )}
            </Collapse>
        </div>
    )
}

/**
 * Detail job list.
 *
 * @param prop Props.
 * @param prop.jobs Jobs to list (work | detail)
 * @param prop.type Type list enum 'work' | 'detail').
 * @param prop.allElements Json data elements to parse static data.
 * @param prop.showModalDetailJob Callback to open modal detail job, only when type is detail.
 */
const DetailJobList = ({jobs, type, allElements, showModalDetailJob, blur}) => {

    const openModal = (job) => type === 'detail' && showModalDetailJob(job)

    return (
        <div>
            <h2 className="color-blue-dark">{type === 'detail' ? 'Acabado' : 'Obra'}</h2>

            {jobs.map((job, i) =>
                <div key={i} className={(type === 'detail' ? 'flex items-center ' : '') + (blur === true ? 'blur ' : ' ') + 'py-2'}>
                    {type === 'detail' && <div onClick={() => openModal(job,blur)}>
                        <ImageDetail allElements={allElements} job={job} jobName={job.name} />
                    </div>}
                    <div className="info-list">
                        <p className="font-bold color-blue-dark truncate text-title m-0 w-full">
                            {job.name.replace(/\/|\_|\|/g, ' ')}
                        </p> <br/>
                        <p className="text-secondary truncate m-0 w-full">
                            <span className="font-bold">Medidas: </span>
                            {job.measurement.measurement.toFixed(2)} {job.measurement.unit}
                        </p> <br/>
                        <p className="text-secondary truncate m-0 w-full">
                            <span className="font-bold">Precio und.: </span>
                            {blur === true ? '999,99' : currencyFormat(job.price.toFixed(2))}
                            {/* {currencyFormat(job.price.toFixed(2))} */}
                        </p> <br/>
                        <p className="truncate font-bold m-0 w-full">
                            <span className="text-secondary">Importe: </span>
                            <span className="text-title color-blue-dark">
                            {blur === true ? '999,99' : currencyFormat(job.amountTotal.toFixed(2))}
                            {/* {currencyFormat(job.amountTotal.toFixed(2))} */}
                        </span>
                        </p>
                    </div>
                </div>
            )}
        </div>
    )
}

export { BudgetList };
