import { useState, useContext, useEffect } from 'react'
import ProjectContext from '../../context/ProjectContext'
import CalcContext from '../../context/CalcContext'
import SceneContext from '../../context/SceneContext'
import ModalContext from '../../context/ModalContext'
import { Space, Table, Tag, Button, Form, Input } from 'antd';
import { prepareLayerForTable } from '../../utils/formatUtils';
const { Column, ColumnGroup } = Table;

const columns = [
  {
    title: 'name',
    dataIndex: 'name',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'id',
    dataIndex: 'id'
  },
  {
    title: 'type',
    dataIndex: 'type',
  },  
  {
    title: 'features',
    className:'mono',
    dataIndex: 'features',
  },
  {
    title: 'decisions',
    dataIndex: 'decisions',
  }
];

const columns2 = [
  {
    title: 'name',
    dataIndex: 'name',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'id',
    dataIndex: 'id'
  },
  {
    title: 'type',
    dataIndex: 'type',
  },  
  {
    title: 'features',
    className:'mono',
    dataIndex: 'features',
  },
  {
    title: 'decisions',
    dataIndex: 'decisions',
  }
];


const SceneTable = ({sceneId = 0}) => {

  const [form] = Form.useForm();

  const projectActions = useContext(ProjectContext)
  const sceneActions = useContext(SceneContext)
  const calcActions= useContext(CalcContext)
  const modalActions = useContext(ModalContext);
  const [actualLayer, setActualLayer] = useState(null);
  const [currentScenes, setCurrentScenes] = useState(null);

  useEffect(() => {
      // setActualLayer(() => projectActions.scenes[sceneId] ? projectActions.scenes[sceneId].layers[0] : null);
      // console.log('IN TABLE',actualLayer)
      setCurrentScenes(() => projectActions.scenes.map(scene => {
        const tempScene = JSON.parse(JSON.stringify(scene))
        scene.house = scene.house.map((homeitem,i) => {
          homeitem.key = i
          homeitem.features = homeitem.features.map(f => f === null ? '-' : f);
          return homeitem
        })
        tempScene.layers = tempScene.layers.map((layer,i) => {
          // layer = layer[i] 
          layer = prepareLayerForTable(layer)         
          return layer
        })
        return tempScene
      }))
  },[projectActions.scenes])
  
  const handleAddDecision = (e,record) => {
    console.log('ADD',record)
    modalActions.setModalData({
      heading: 'Add decision',
      subHeading: "select from the list or define new decision",
      body: (<Form form={form}>
                <Space>{record.name} {record.id}</Space>
                <Form.Item
                  label='additional dec'
                  id="name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, introduce el decision name",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
      </Form>
      )
    })
  }

  return (
    <div>
    SceneTable
    {currentScenes && currentScenes.map((scene,i) => <div key={i}>
        <Table
            key={i}
            className="scene-table"
            dataSource={scene.house}
            pagination={false}
            bordered
            title={() => scene.name}
            footer={() => <TableFooter layers={scene.layers}/>}
        >
        <Column title="Name" dataIndex="name" key="name"/>           
        <Column title="Id" dataIndex="id" key="id"/>           
        <Column title="Type" dataIndex="type" key="type"/>           
        <Column title="Features" dataIndex="features" key="features" className='mono'/>           
        <Column
          title="Action"
          key="action"
          dataIndex='id'
          render={(_, record) => (
            <Space size="middle">
              <button onClick={(e) => handleAddDecision(e,record)} style={{padding:'0 0.5em'}}>Add dec.</button>
            </Space>
          )}
        />
    </Table>
        {/* <Table
            columns={columns}
            dataSource={scene.layers[0].items}
            // expandable={{
            //     expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.description}</p>,
            //     rowExpandable: (record) => record.name !== 'Not Expandable',
            // }}
            bordered
            footer={() => 'Selected:' + scene.selectedLayer}
        /> */}
        </div>        
    )}

    </div>
  )
}

const TableFooter = ({layers}) => {

  
  return layers.map((layer,i) => {
    console.log('IN FOOTER',layer)
    return <div>
    <Table 
      key={i}
      columns={columns}
      dataSource={layer.areas}
      pagination={false}
      showHeader={false}
      footer={() => <>
      <Table 
        columns={columns2}
        pagination={false}
        dataSource={layer.lines}
      />
      <Table 
        columns={columns}
        showHeader={false}
        pagination={false}
        dataSource={layer.items}
      />
      <Table 
        columns={columns}
        showHeader={false}
        pagination={false}
        dataSource={layer.holes}
      />
      </>}
    />
    {/* <Table
      // dataSource={layers[0].lines}
    >
      <Column dataIndex='id' key="id"/>
      <Column dataIndex='type' key="type"/>
      <Column className='mono' dataIndex='features' key="id"/>
    </Table>
    <Table 
      columns={columns}
      dataSource={layers[0].items}
      pagination={false}
      showHeader={false}
    /> */}
  </div>
  })
}

export default SceneTable