import React, { useEffect, useContext, useState } from 'react'
import BigImg from './BigImg'
import PropertyInput from './PropertyInput'
import ProjectContext from '../../context/ProjectContext'
import SceneContext from '../../context/SceneContext'
import { getItem, getElementData } from '../../utils/sceneUtils'
import { getFeatureValue } from '../../utils/formatUtils'
import { getPrice } from '../../utils/calcUtils'
import PuffLoader from "react-spinners/PuffLoader";
import { BsArrowRightCircle, BsArrowLeftCircle } from 'react-icons/bs'
import Pricetag from './Pricetag'

const ProductInfo = ({table = 'initialTable',source,properties,preview}) => {

    const projectActions = useContext(ProjectContext)
    const sceneActions = useContext(SceneContext)

    const [ itemData, setItemData ] = useState(null) 
    const [ item, setItem ] = useState(null) 
    const [ layer, setLayer ] = useState(null)
    const [ refresh, setRefresh ] = useState(false)
    const [ loaded, setLoaded ] = useState(false)
    const [ imgId, setImgId ] = useState(0)
    const [ ignoreFilters, setIgnoreFilters ] = useState(null)
    let combinedName = '';
    let price = null;

    useEffect(() => {
      const layer = projectActions.scenes[table === 'initialTable' ? 0 : 1].layers[0]
      const elementData = getElementData(projectActions.elementsData,source.id_element,source.filter[0].v)
      setItemData(() => elementData)
      setLayer(layer)
      const item = getItem(layer, source.id_element,source.filter[0].v,source.filter)
      setItem(item)
    },[])

    const handleNext = () => {
      setImgId(imgId + 1)
      console.log('|||',imgId)
    }

    const handlePrev = () => {
      setImgId(imgId - 1)
      console.log('|||',imgId)
    }

    const handlePropertyChange = (e) => {
      const { dataset, value, name } = e.target;
  
      console.log('F:',dataset.feature, 'V:',value, '', name, 'ID:',dataset.itemid,source.filter)

      if(value === 'resetFilter'){
        console.log('WCHODZI',ignoreFilters,dataset)
        setIgnoreFilters(() => parseInt(dataset.feature))
      } else if (dataset.feature && !isNaN(value)){
        if(parseInt(dataset.feature) === 23){
          setIgnoreFilters(() => null)
        }
        sceneActions.setFeature('mScene',parseInt(dataset.feature),parseInt(value),parseInt(dataset.idelement),source.filter)
      }
  
    } 
  
    const handleFeatureChange = (e) => {
      const { dataset, value, name } = e.target;
      
      sceneActions.setFeature(table === 'initialTable' ? 'scene' : 'mScene',parseInt(dataset.feature),parseInt(value),parseInt(dataset.idelement))
      if(table === 'initialTable'){
        const columns = projectActions.calcData.columns.find(f => f.old === parseInt(dataset.feature))
        if(columns && columns.id_column && projectActions.scenes.length > 1){
    
          sceneActions.setFeature('mScene',columns.id_column,parseInt(value),parseInt(dataset.idelement))
        }
      }
      setRefresh(!refresh)
    }

    return (
        <div className="modal-panels">
            <div className="m-left" style={{padding:0}}>
              {itemData && <div>
                {itemData.properties.filter(prp => {
                let testProp = true;
                if(source.preview || preview){
                  testProp = preview ? preview.includes(prp.name) : source.preview.includes(prp.name);
                }
                return testProp
              }).map((property,j) => {
                  const oldValue = getFeatureValue(item,property.feature)
                  const propertyValue = property.values.find((val) => val.value === oldValue)
                  return propertyValue ? <div key={j} className="m-preview">
                    {loaded === false && <PuffLoader color="#ffffff" />}
                    {propertyValue.img ? <img onLoad={() => setLoaded(true)} style={{width:'100%'}} src={propertyValue.img[imgId]}/> : propertyValue.label}
                    {propertyValue.img && propertyValue.img.length > 0 && <div className='navBtnsPreview'>
                    {imgId !== 0 && <div className='navBtn left' onClick={handlePrev}><BsArrowLeftCircle size="30px"/></div>}
                    {imgId < propertyValue.img.length - 1 && <div className='navBtn right' onClick={handleNext}><BsArrowRightCircle size="30px"/></div>}
                  </div>}
                </div>
                :
                <div key={j} className='m-preview'>No image found</div>
                })}
              </div>}
            </div>
            <div className="m-right">
                <div className='c-title' style={{fontSize:'28px'}}>{source.label}</div>
                {itemData && <div>
                    {itemData.properties.filter(prp => prp.user === true).filter(prp => {
                    let testProp = true;
                    if(properties && properties.length > 0){
                      testProp = source.properties.includes(prp.name);
                    }
                    return testProp
                  }).map((property,j) => {
                      const oldValue = getFeatureValue(item,property.feature)
                      const propertyValue = property.values.find(val => val.value === oldValue)
                      const itemName = j === 0 ? item.name : '';

                      if(propertyValue){
                        const propertyName = property.name !== 'style' ? `|${propertyValue.name}` : '';
                        combinedName += itemName + propertyName;
                      }
                      price = getPrice(projectActions.calcData,combinedName)
                      return <PropertyInput key={j} action={handlePropertyChange} item={item} property={property} ignoreFilters={ignoreFilters}/>
                    })}                        
                </div>}
                {<Pricetag combinedName={combinedName} />}
            </div>
        </div>
    )
}

export default ProductInfo