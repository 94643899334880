import React, { useContext, useRef, useState, useEffect, Fragment } from 'react'
import { useLocation, useNavigate } from "react-router-dom"
import ProjectContext from '../../context/ProjectContext'
import QnrContext from '../../context/QnrContext'
import CalcContext from '../../context/CalcContext'
import SceneContext from '../../context/SceneContext'
import AuthContext from '../../context/AuthContext'
import ModalContext from '../../context/ModalContext'
import { motion } from 'framer-motion'
import Carousel from '../widgets/Carousel'
import Slider from '../widgets/Slider'
import StyleImg from '../widgets/StyleImg'
import Selector from '../widgets/Selector'
import ImageSlider from '../widgets/ImageSlider'
import Overlay from '../widgets/Overlay'
import Budget from '../budget/Budget'
import Results from '../results/Results'
import SceneTable from '../results/SceneTable'
import ShapePlanner from '../planner/ShapePlanner'
import ShapePlanner2 from '../planner/ShapePlanner2'
import Register from '../results/Register'
import { createElement, getItem, filteredItems, checkFilterItems, getNewFromCalcData, checkActions, getIdfromSelected, getElementOfId, checkFilterItem, getOldFromCalcData, getAreaLines, getLineOfId, getElementData, getNameFromId, getLabelFromId } from '../../utils/sceneUtils'
import { elementNameToId, formatDefaults, getItemValueLabel, getItemValueSuper, elementIdToName, checkIfConnected, getThumb } from '../../utils/formatUtils'
import { cleanResults, cleanScenes } from '../../utils/calcUtils'
import { Form, modal, Input, Checkbox, Button, Tooltip, message, Card, Carousel as AntdCarousel, Tabs} from 'antd';
import Summary from '../results/Summary'
import ZipCodeCities from '../results/ZipCodeCities'
import { verticesDistance } from '../../utils/geometry'
import { QuestionCircleOutlined } from '@ant-design/icons';
import { BsArrowLeft, BsArrowRight, BsCheck2, BsFileBreakFill, BsPlus, BsX} from 'react-icons/bs'
import { FiChevronDown, FiChevronUp, FiArrowRight, FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import FeatureInput from '../widgets/FeatureInput'
import StyleSelector from '../widgets/StyleSelector'
import BigImg from '../widgets/BigImg'
import Properties from '../widgets/Properties'
import ProductInfo from '../widgets/ProductInfo'
import { firestore as db } from "../../firebase/config";
import Meta from "antd/es/card/Meta";
import DecisionSelector from '../widgets/DecisionSelector'
import SelectorInput from '../widgets/SelectorInput'
import GroupSelector from '../widgets/GroupSelector'
import Combo from './Combo'
import Sum from '../dashboard/Sum'
import { divideResult } from '../../utils/budgetUtils'
import Rooms from '../widgets/Rooms'
import ElementsDock from '../widgets/ElementsDock'
import ElementProperties from '../widgets/ElementProperties'
import { FaChevronCircleDown } from 'react-icons/fa'
import GroupSelector2 from '../widgets/GroupSelector2'

const key = "updatable";

const Question = ({q,refreshCategory,itemId=null}) => { 

  const navigate = useNavigate()

  const { calcData, project, setDefault, saveProject, saveScene, messages, addResult } = useContext(ProjectContext)
  const { activeQ, setActiveQ, qnrData, currentQnr, setCurrentQnr, qnrCities } = useContext(QnrContext)
  
  const location = useLocation();

  const authActions = useContext(AuthContext)
  const projectActions = useContext(ProjectContext)
  const qnrActions = useContext(QnrContext)
  const sceneActions = useContext(SceneContext)
  const modalActions = useContext(ModalContext)
  
  const [ refresh, setRefresh ] = useState(false) 
  const [ edit, setEdit ] = useState(false) 
  const [ projectID, setProjectID ] = useState('')
  const [ currentValue, setCurrentValue ] = useState(null)
  const [ projectName, setProjectName ] = useState('')
  const [ activeDetail, setActiveDetail ] = useState(null)
  const [ height, setHeight ] = useState(projectActions.project.defaults.height * 100)
  const [questionColapse, setQuestionColapse] = useState(true);

  const [actualLayer, setActualLayer] = useState(null)
  const [saveOpen, setSaveOpen] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const [style, setStyle] = useState(projectActions.project.defaults.style ?? 0)
  const [substyle, setSubstyle] = useState(projectActions.project.defaults.substyle ?? 0)
      
  useEffect(() => {
    if(projectActions.scenes[0] && q.table === 'initialTable' || q.table === 'project' || q.table === 'all'){     
      setActualLayer(() => projectActions.scenes[0].layers[0])
    } else if(projectActions.scenes[1]) {
      setActualLayer(() => projectActions.scenes[1].layers[0])
    } 

  },[q.table,projectActions.scenes])

  const handleDragStart = (e) => {
    const {dataset} = e.target;
    const option = q.options[parseInt(dataset.option)]
    // console.log('starting',option);
    sceneActions.setTarget(option.target)
    e.dataTransfer.setData("o", JSON.stringify(option));
  } 
  
  const handleDragEnd = (e) => {
    if(sceneActions.over === 'canvas'){
      const {dataset} = e.target;
      const option = q.options[parseInt(dataset.option)]
      let features = new Array(8).fill(null);
      if(option.filter){
        option.filter.forEach(flt => {
          features[0] = projectActions.roomType ? projectActions.roomType : 6;
          features[flt.f] = flt.v;
          features[7] = (q.table && q.table === 'initialTable') ? null : getNewFromCalcData(calcData,option.id_element)
        })
      }

      const itemData = projectActions.elementsData.items.find(item => item.type === parseInt(dataset.typology));
      let measurements = itemData.measurements ? itemData.measurements : undefined;

      if(q.table === 'integratedTable'){
        const zeros = new Array(16).fill(0)
        features = features.slice(0,8).concat(zeros)
        
        // features[15] = 2;
      }
      console.log('End drag = ADD',dataset)
      const newElement = createElement(option.name,option.label,option.id_element,'doors',features,measurements)
      if(q.table === 'all'){
        sceneActions.addElementToAll(q.table,dataset.aid,newElement)
      } else {
        sceneActions.addElement(q.table,dataset.aid,newElement)
      }

    }  
  } 

  const handleDrop = (e) => {
    if(sceneActions.over === 'canvas'){
      const {dataset} = e.target;
      const option = q.options[parseInt(dataset.option)]
      e.dataTransfer.setData("o", JSON.stringify(option));
    }
  }

  const handleActions = (e) => {
    const { value, dataset } = e.target;


    q.options
      && q.options[parseInt(dataset.option)].buttons 
      && q.options[parseInt(dataset.option)].buttons[value].actions
      && sceneActions.performActions(null,q.options[parseInt(dataset.option)].buttons[value].actions)

    if(q.options && q.options[parseInt(dataset.option)] && q.options[parseInt(dataset.option)].actions){
      sceneActions.performActions(null,q.options[parseInt(dataset.option)].actions)
    }
    const card = document.getElementById('scroll-chapter-project');
    card && card.scroll({
      top: 0
    })
  }

  const performActions = (e) => {

    const { dataset } = e.target
    
    if(q.options){
      if(q.options[parseInt(dataset.option)]){
        const actions = q.options[parseInt(dataset.option)].actions;
        sceneActions.performActions(null,actions)
      }
    }
    
    if(dataset.actions){
      let actions = JSON.parse(dataset.actions)

      if(dataset.area){
        actions = actions.map(a => {return {...a,area:dataset.area,id:dataset.id}})
      }

      console.log('ACTIONATED',actions[0]) 

      sceneActions.makeActions(q.table,actions)
    }
  }

  const handleFormValue = (e) => {
    e.preventDefault()
    projectActions.setDefault('height',height,'height');
    sceneActions.setSceneParameter('height',height)
    refreshCategory()
    // setRefresh(!refresh)
  }

  const handleDefaultValue = (which,value) => {
    projectActions.setDefault(which,value,'int');
    projectActions.setRefresh(!projectActions.refresh)  

    console.log(which,value,'OLD',project.defaults[which])

    if(which === 'houseArea'){
      const variants = projectActions.sceneData[project.defaults.houseShape].options
      let foundVariant = variants.find(variant => value <= variant.max && value > variant.max - 10)

      if(!foundVariant){
        if(value > variants.at(-1).max){
          foundVariant = variants.at(-1)
        } else if (value < variants[0].max) {
          foundVariant = variants[0]
        }
      }

      console.log('FOUND VARIANT',foundVariant,variants,value)
  
      if(foundVariant){
          sceneActions.setNewVertices(foundVariant.vertices);
          if(foundVariant.message ){
            message.info({
              content: foundVariant.message,
              duration: 2,
            });
          }
      }
    }
  }

  const handlePropertyChange = (e) => {
    const { dataset, value, name } = e.target;

    let filters = []

    if(dataset.option && q.options[dataset.option].filter){
      filters = q.options[dataset.option].filter
    }

    console.log('F:',dataset.feature, 'V:',value, name, 'ID:',dataset.itemid)

    sceneActions.setFeature('mScene',parseInt(dataset.feature),parseInt(value),null,filters,dataset.itemid)
  }

  const handleFeatureChange = (e) => {
    const { dataset, value, name } = e.target;

    console.log('FEAT CHANGE ',q.table,dataset.feature,value,dataset.area)

    sceneActions.setFeature(q.table === 'integratedTable' ? 'mScene' : 'scene',dataset.feature,value,parseInt(dataset.idelement),[],dataset.id,dataset.area)

    if(q.table === 'initialTable'){
      // const columns = projectActions.calcData.columns.find(f => f.old === parseInt(dataset.feature))  
      const oldColumn = projectActions.calcData.columns[parseInt(dataset.feature)].old 

      console.log('----IN INITIAL TABLE',dataset.feature,oldColumn)

      if(oldColumn && projectActions.scenes.length > 0){ 

        console.log('+++++++++++++++ WCHODZI',oldColumn)

        sceneActions.setFeature('mScene',oldColumn,value,parseInt(dataset.idelement),[],dataset.id,dataset.area)
      }
    }

    if(q.table === 'all'){

        sceneActions.setFeature('mScene',dataset.feature,value,parseInt(dataset.idelement),[],dataset.id,dataset.area)
    }

    setRefresh(!refresh)
    projectActions.setRefresh(!projectActions.refresh)
  }

  const handleCreateProject = (e) => {
    console.log('CREATE')
  }
  
  const handleSaveProject = (e) => {
    if(projectID !== ''){
      sceneActions.saveScenes(projectID,projectName)
    } else {
      message.error({
        content: messages.emptyInput,
        duration: 2,
      })
    }
  }
  
  const handleSaveNewProject = () => {

    let now = new Date();

    let newName = document.getElementById('projectName').value

    console.log('IN SAVE NEW PROJECT',projectActions.scenes,authActions.selectedUser)

    db.collection("Projects")
    .add({
      name: newName !== '' ? newName : projectActions.roomType === 8 ? "Cocina Proyecto" : "Bano Proyecto",
      plan: "basic",
      initialQNR: {
        status: "incomplete",
      },
      zipCode: localStorage.getItem("city"),
      images: {
        floorplan: "",
        initialview: "",
        integratedview: "",
        photo: "",
        renders: [],
        thumbnail: "",
      },
      marketPlaceIntro: false,
      access: "pending",
      active:true,
      // user
      //   ? user.profile_type === "agency"
      //     ? "public"
      //     : "private"
      //   : "Invalid",
      userId: authActions.selectedUser ? authActions.selectedUser : authActions.currentUser.uid,
      created_At: now,
      last_Modified_At: now,
      results: cleanResults(projectActions.results),
      scope: projectActions.project.scope,
      defaults:formatDefaults(projectActions.project.defaults),
      scenes: cleanScenes(projectActions.scenes)
    })
    .then((project) => {
      message.success({
        content: 'SAVE OK',
        key,
        duration: 2,
      });
      navigate(`/summary/${authActions.selectedUser ? authActions.selectedUser : authActions.currentUser.uid}`)
      // navigate("/results/" + project.id)
    })
    .catch(err => console.log(projectActions.scenes,projectActions.project,projectActions.results))
  }

  const handleAddResult = (e) => {
      console.log('ADD RESULTS')
      projectActions.addResult(projectActions.results[0])
      // console.log(results)
  }
  
  const handleOptionChange = (e) => {
    const link = e.target.dataset.link;
    const { name:qId, value, dataset } = e.target;

    const tempQ = currentQnr[link ? link : qId];
    
    const option = q.options[value]
    
    // sceneActions.performActions(q.table === 'integratedTable' ? 'mScene' : 'scene',option.actions, [], [], dataset.aid)
    if(q.table === 'all'){
      sceneActions.performActions('scene',option.actions, [], [], dataset.aid)
      sceneActions.performActions('mScene',option.actions, [], [], dataset.aid)
    } else if (q.table === 'initialTable'){
      sceneActions.performActions('scene',option.actions, [], [], dataset.aid)
    } else {
      sceneActions.performActions('mScene',option.actions, [], [], dataset.aid)
    }
    
    // console.log(currentQnr[qId])

    setCurrentQnr({...currentQnr,[link ? link : qId]:{...tempQ,value:dataset.oid}})
  }

  const handleCheckChange = (e) => {
    const link = e.target.dataset.link;
    const { name:qId, value, dataset } = e.target;

    const tempQ = currentQnr[link ? link : qId];

    
    
    if(e.target.checked === true){
      setCurrentQnr({...currentQnr,[link ? link : qId]:{...tempQ,checked:currentQnr[qId].checked.add(value)}})
    } else { 
      const tempChecked = currentQnr[qId].checked;
      tempChecked.delete(value)

      setCurrentQnr({...currentQnr,[link ? link : qId]:{...tempQ,checked:tempChecked}})
    }

    console.log('CHCK',qId,'-',value,':',currentQnr[qId]) 
  }

  const showModal = (e) => {
    const { dataset } = e.target
    
    const option = q.options[dataset.option];

    console.log('MODAL',e.target,option.modal)

    let comp = <BigImg source={option} />

    if(option.modal && option.modal.length > 0){
      let component = <BigImg source={option} />

      comp = option.modal.map((m,i) => {
        switch (m){
          case 'BigImg':
            return <BigImg key={i} source={option} />
          case 'Properties':
            return <Properties key={i} table={q.table} source={option} properties={q.properties} preview={q.preview} itemID={dataset.id}/>
          case 'ModelInfo':
            return <ProductInfo key={i} table={q.table} source={option} properties={q.properties} preview={q.preview}/>
        }
      });
    }
    
    projectActions.setModalData(comp)

    projectActions.setModal(true)
  }

  const showProductInfo = (e) => {
    const { dataset } = e.target

    const option = q.options[dataset.option];

    console.log('MODAL',e.target,option.modal)

    let comp = <BigImg source={option} />

    if(option.modal && option.modal.length > 0){
      let component = <BigImg source={option} />

      comp = option.modal.map((m,i) => {
            return <ProductInfo key={i} table={q.table} source={option} properties={q.properties} preview={q.preview}/>
      });
    } 
    
    modalActions.setModalData({
      heading: "ProductInfo",
      body: (comp),
      buttons: [
        { text: "Cancel", type: "text", onClick: "close" },
      ]
    });    
  }

  const handleModification = (e) => {
    const { name:qId, value, dataset, checked } = e.target

    const option = q.options[dataset.option];

    sceneActions.performActions('mScene',option.actions,[dataset.idelement],[{f:1,v:dataset.typology}])
 
    setCurrentQnr({...currentQnr,[qId]:{...currentQnr[qId],value:option.id}}) 
  }

  const handleModButton = (e) => {
    const { name:qId, value, dataset, checked } = e.target

    if(dataset.id){
      sceneActions.performActions(q.table === 'initialTable' ? '' : 'mScene',q.buttons[dataset.which].actions ?? JSON.parse(dataset.actions) ,[dataset.idelement],null,null,dataset.id)
    } else {
      sceneActions.performActions(q.table === 'initialTable' ? '' : 'mScene',q.buttons[dataset.which].actions ?? JSON.parse(dataset.actions) ,[dataset.idelement],[{f:1,v:dataset.typology}])
    }

    if(dataset.actions){
      let actions = JSON.parse(dataset.actions)
      actions = actions.map(a => {
        return {...a,idelement:dataset.idelement}
      })
      console.log('ACTIONS',actions,dataset)
      sceneActions.makeActions(q.table,actions,dataset.id)
      return
    }

    if (q.options && q.options[dataset.option] && q.options[dataset.option].actions){     
      q.options[dataset.option].actions.forEach(a => {
        if(a.a === 'add'){
          sceneActions.performActions('mScene',q.buttons[dataset.which].actions,[elementNameToId(a.e)],[{f:1,v:a.v}],a.id)
        }
      })
    }
  }

  const handleItemBox = (e) => {
    const { dataset, checked, id, value } = e.target;
    const option = q.options[value]

    if(checked){
      let features = new Array(10).fill(null);
      if(option.filter){
        option.filter.forEach(flt => {
            features[flt.f] = flt.v;
            features[7] = (q.table === 'initialTable') ? null : getNewFromCalcData(calcData,parseInt(option.id_element))
        })
      }
      const itemData = projectActions.elementsData.items.concat(projectActions.elementsData.holes)
        .find(item => item.type === parseInt(option.filter[0].v));

      let measurements = itemData.measurements ? itemData.measurements : undefined;
      if(q.table === 'integratedTable'){
        const zeros = new Array(16).fill(0)
        features = features.slice(0,8).concat(zeros) 
        // features[15] = 2;
      }

      features[0] = projectActions.roomType ?? 6;
      const newElement = createElement(option.name,option.label,option.id_element,elementIdToName(option.id_element),features,measurements)
    
      if(q.table === 'all'){
        sceneActions.addElementToAll(q.table,dataset.aid,newElement,option.target)
      } else {
        sceneActions.addElement(q.table,dataset.aid,newElement,option.target)
      }

      if(option.actions){
        option.actions.forEach(a => {
          if(a.a === 'add'){ 
            const addFeatures = [...features];
            addFeatures[parseInt(a.f)] = parseInt(a.v);
            let target = null
            if(a.target === 'wall'){

            }
            const newAdd = createElement(a.name,a.label,option.id_element,elementIdToName(option.id_element),addFeatures,measurements)
            if(q.table === 'all'){
              
              sceneActions.addElementToAll(q.table,dataset.aid,newAdd,option.target)
            } else {
              sceneActions.addElement(q.table,dataset.aid,newAdd,dataset.target)
            }
          } else {
            sceneActions.performSingleAction(q.table,option.actions,option.id_element)
          }
        })
      }

      
      // const addAction = {a: "add",e:elementIdToName(option.id_element),typology:option.filter[0].v,target:"wall"}

      // sceneActions.makeActions(q.table,[addAction])
    } else {
      removeElement(e)
    }

    setRefresh(!refresh)
  }

  const addRoom = (e) => {
    const { dataset } = e.target
    const option = q.options[dataset.option]
    const banos = actualLayer.areas.filter(room => checkFilterItem(room,[{f:0,v:6}]));
    const dormitorios = actualLayer.areas.filter(room => checkFilterItem(room,[{f:0,v:4}]));

    let banosNumber = banos.length
    let dormitoriosNumber = dormitorios.length

    if(banosNumber < 5 && dataset.option === '1'){
      banosNumber++
    }

    if(dormitoriosNumber < 6 && dataset.option === '0'){
      dormitoriosNumber++
    }

    if(banosNumber === 1 && dormitoriosNumber === 1){
      projectActions.setDefault('houseShape',0,'int');
      sceneActions.setCurrentScene(project.defaults.houseShape);
    } else if (banosNumber === 2 && dormitoriosNumber === 1){
      projectActions.setDefault('houseShape',1,'int');
      sceneActions.setCurrentScene(project.defaults.houseShape);
    } else if (banosNumber === 1 && dormitoriosNumber === 2) {
      projectActions.setDefault('houseShape',2,'int');
      sceneActions.setCurrentScene(project.defaults.houseShape);
    } else if (banosNumber === 2 && dormitoriosNumber === 2) {
      projectActions.setDefault('houseShape',3,'int');
      sceneActions.setCurrentScene(project.defaults.houseShape);      
    } else if (banosNumber === 3 && dormitoriosNumber === 2) {
      projectActions.setDefault('houseShape',4,'int');
      sceneActions.setCurrentScene(project.defaults.houseShape);         
    } else if (banosNumber === 1 && dormitoriosNumber === 3) {
      projectActions.setDefault('houseShape',5,'int');
      sceneActions.setCurrentScene(project.defaults.houseShape);         
    } else if (banosNumber === 2 && dormitoriosNumber === 3) {
      projectActions.setDefault('houseShape',6,'int');
      sceneActions.setCurrentScene(project.defaults.houseShape);         
    } else if (banosNumber === 3 && dormitoriosNumber === 3) {
      projectActions.setDefault('houseShape',7,'int');
      sceneActions.setCurrentScene(project.defaults.houseShape);         
    } else if (banosNumber === 4 && dormitoriosNumber === 3) {
      projectActions.setDefault('houseShape',8,'int');
      sceneActions.setCurrentScene(project.defaults.houseShape);         
    } else if (banosNumber === 2 && dormitoriosNumber === 4) {
      projectActions.setDefault('houseShape',9,'int');
      sceneActions.setCurrentScene(project.defaults.houseShape);         
    } else if (banosNumber === 3 && dormitoriosNumber === 4) {
      projectActions.setDefault('houseShape',10,'int');
      sceneActions.setCurrentScene(project.defaults.houseShape);         
    } else if (banosNumber === 4 && dormitoriosNumber === 4) {
      projectActions.setDefault('houseShape',11,'int');
      sceneActions.setCurrentScene(project.defaults.houseShape); 
    } else if (banosNumber === 2 && dormitoriosNumber === 5) {
      projectActions.setDefault('houseShape',12,'int');
      sceneActions.setCurrentScene(project.defaults.houseShape); 
    } else if (banosNumber === 3 && dormitoriosNumber === 5) {
      projectActions.setDefault('houseShape',13,'int');
      sceneActions.setCurrentScene(project.defaults.houseShape); 
    } else {
      if(banosNumber > banos.length){
        if(banosNumber < 5){
          message.error({
            content: messages.addDormitoriosFirst,
            duration: 2,
          });             
        } else {
          message.error({
            content: messages.tooManyBathrooms,
            duration: 2,
          });   
        }
      }
      if(dormitoriosNumber > dormitorios.length){
        if(dormitoriosNumber < 5){
          message.error({
            content: messages.addBanosFirst,
            duration: 2,
          });             
        } else {
          message.error({
            content: messages.tooManyDormitorios,
            duration: 2,
          });   
        }
      }
      // projectActions.setDefault('houseShape',20,'int');
    }

    console.log('ADD SHAPE:',project.defaults.houseShape,'o:',dataset.option,'b:',banosNumber,'d:',dormitoriosNumber)
  }

  const delRoom = (e) => {
    const { dataset } = e.target
    const option = q.options[dataset.option]
    const banos = actualLayer.areas.filter(room => checkFilterItem(room,[{f:0,v:6}]));
    const dormitorios = actualLayer.areas.filter(room => checkFilterItem(room,[{f:0,v:4}]));

    let banosNumber = banos.length
    let dormitoriosNumber = dormitorios.length

    if(banosNumber > 1 && dataset.option === '1'){
      banosNumber--
    }

    if(dormitoriosNumber > 1 && dataset.option === '0'){
      dormitoriosNumber--
    }

    if(banosNumber === 1 && dormitoriosNumber === 1){
      projectActions.setDefault('houseShape',0,'int');
      sceneActions.setCurrentScene(project.defaults.houseShape);
    } else if (banosNumber === 2 && dormitoriosNumber === 1){
      projectActions.setDefault('houseShape',1,'int');
      sceneActions.setCurrentScene(project.defaults.houseShape);
    } else if (banosNumber === 1 && dormitoriosNumber === 2) {
      projectActions.setDefault('houseShape',2,'int');
      sceneActions.setCurrentScene(project.defaults.houseShape);
    } else if (banosNumber === 2 && dormitoriosNumber === 2) {
      projectActions.setDefault('houseShape',3,'int');
      sceneActions.setCurrentScene(project.defaults.houseShape);      
    } else if (banosNumber === 3 && dormitoriosNumber === 2) {
      projectActions.setDefault('houseShape',4,'int');
      sceneActions.setCurrentScene(project.defaults.houseShape);         
    } else if (banosNumber === 1 && dormitoriosNumber === 3) {
      projectActions.setDefault('houseShape',5,'int');
      sceneActions.setCurrentScene(project.defaults.houseShape);         
    } else if (banosNumber === 2 && dormitoriosNumber === 3) {
      projectActions.setDefault('houseShape',6,'int');
      sceneActions.setCurrentScene(project.defaults.houseShape);         
    } else if (banosNumber === 3 && dormitoriosNumber === 3) {
      projectActions.setDefault('houseShape',7,'int');
      sceneActions.setCurrentScene(project.defaults.houseShape);         
    } else if (banosNumber === 4 && dormitoriosNumber === 3) {
      projectActions.setDefault('houseShape',8,'int');
      sceneActions.setCurrentScene(project.defaults.houseShape);         
    } else if (banosNumber === 2 && dormitoriosNumber === 4) {
      projectActions.setDefault('houseShape',9,'int');
      sceneActions.setCurrentScene(project.defaults.houseShape);         
    } else if (banosNumber === 3 && dormitoriosNumber === 4) {
      projectActions.setDefault('houseShape',10,'int');
      sceneActions.setCurrentScene(project.defaults.houseShape);         
    } else if (banosNumber === 4 && dormitoriosNumber === 4) {
      projectActions.setDefault('houseShape',11,'int');
      sceneActions.setCurrentScene(project.defaults.houseShape); 
    } else if (banosNumber === 2 && dormitoriosNumber === 5) {
      projectActions.setDefault('houseShape',12,'int');
      sceneActions.setCurrentScene(project.defaults.houseShape); 
    } else if (banosNumber === 3 && dormitoriosNumber === 5) {
      projectActions.setDefault('houseShape',13,'int');
      sceneActions.setCurrentScene(project.defaults.houseShape); 
    } else {
            message.error({
              content: messages.invalidRooms,
              duration: 2,
            });
      // projectActions.setDefault('houseShape',20,'int');
    }

    console.log('DEL SHAPE:',project.defaults.houseShape,'o:',dataset.option,'b:',banos.length,'d:',dormitorios.length)
    projectActions.setRefresh(!projectActions.refresh)
  }

  const addElement = (e) => {
    console.log('ADD',e.target)
    const { dataset } = e.target
    let itemCategory = 'items';
    switch(dataset.idelement){
      case '4':
      case '5':
      case '6':
        itemCategory = 'holes'
      break;
      default:
        itemCategory = 'items'
    }
    const itemData = projectActions.elementsData[itemCategory].find(item => item.type === parseInt(dataset.typology));

    let measurements = itemData.measurements ? itemData.measurements : undefined;

    let features = new Array(8).fill(null);
    features[0] = projectActions.roomType ? projectActions.roomType : 6;
    features[1] = parseInt(dataset.typology)
    if(q.table === 'integratedTable'){     
      const zeros = new Array(16).fill(0)
      features[7] = getNewFromCalcData(calcData,parseInt(dataset.idelement))
      features = features.slice(0,8).concat(zeros)
      // features[15] = 2;
    }

    const newElement = createElement(dataset.name,dataset.label,dataset.idelement,elementIdToName(dataset.idelement),features,measurements);

    console.log('ADD',dataset.name,dataset.label,q.table,newElement,features)

    if(q.table === 'all'){
      sceneActions.addElementToAll(q.table,dataset.aid,newElement,dataset.target)
    } else {
      sceneActions.addElement(q.table,dataset.aid,newElement,dataset.target)
    }
  }

  const removeElementNum = (e) => {
    const { dataset } = e.target

    let itemCategory = 'items';
    switch(dataset.idelement){
      case '4':
      case '5':
      case '6':
        itemCategory = 'holes'
      break;
      default:
        itemCategory = 'items'
    }

    const foundElement = actualLayer[itemCategory].find(item => {
      return item.features[1] === parseInt(dataset.typology)
    })

    if(foundElement){
      sceneActions.removeElement(q.table,null,parseInt(dataset.idelement), dataset.typology, foundElement.id)
    }

  }

  const removeElement = (e) => {
    const { dataset } = e.target

    const option = q.options[dataset.option];
 
    const foundItem = getItem(actualLayer,parseInt(dataset.idelement),dataset.typology,option.filter)

    console.log('-----REMOVING',foundItem)

    sceneActions.removeElement(q.table,null,parseInt(dataset.idelement), dataset.typology, foundItem ? foundItem.id : null)

    if(option.actions){
        let addFound = []
        if(option.filter){
          const tempFound = filteredItems(actualLayer.items,[{"f":option.filter[0].f,"v":option.filter[0].v}])
          if(tempFound){
            addFound = addFound.concat(tempFound)
          }
        }      
        option.actions.forEach(a => {
        if(a.a === 'add'){
          const tempFound2 = filteredItems(actualLayer.items,[{"f":a.f,"v":a.v}])
          if(tempFound2){
            addFound = addFound.concat(tempFound2)
          }
          console.log('IN REMOVE',a,actualLayer,addFound)
        }
        if(addFound.length > 0){
          addFound.forEach(removeItem => {
            sceneActions.removeElement(q.table,null,parseInt(dataset.idelement), removeItem.features[1], removeItem.id)
          })
        }
      })
    }
  }

  const handleElementChange = (e) => {

    console.log('changing element')
  }

  const handleProjectValue = (e) => {
    const { value, name, dataset = null } = e.target;

    projectActions.setDefault(name,value,dataset ? dataset.datatype : null)

    q.actions && q.actions.forEach(action => {
      console.log('===============action',value,action,project.defaults)
      switch(action.a){
        case 'refreshScene':
          sceneActions.setCurrentScene(value,project.defaults.houseShape);
        break;
        case 'refreshVertices':
          sceneActions.setNewVertices(projectActions.sceneData[value].options[0].vertices);
        break;
        case 'refreshLines':
          sceneActions.replaceLines(projectActions.sceneData[value].scene.layers[0])
        break;
        case 'refreshSceneVertices':
          sceneActions.replaceVertices(projectActions.sceneData[value].scene.layers[0].vertices)
        break;
        case 'copyScene':
          sceneActions.copyScene()
        break;
        default:
      }
    })

    setRefresh(!refresh)
  }

  const handleSetVertices = (e) => {
    const { value } = e.target;
    
    console.log('VERTS',projectActions.sceneData[projectActions.project.defaults.houseShape].options[value]);

    if(projectActions.sceneData[projectActions.project.defaults.houseShape].options){   
      sceneActions.setNewVertices(projectActions.sceneData[projectActions.project.defaults.houseShape].options[value].vertices);
    }

    setRefresh(!refresh)
  }

  const handleLevelChange = (e) => {
    const { value, name, dataset = null } = e.target;

    console.log('lvl',value, name)

    sceneActions.performActions(q.scene,q.options[value].actions, q.id_element)
  }

  const btnAction = (action) => {
    console.log(action)
    switch (action){
      case 'nextSlide':
        setActiveQ(activeQ + 1)
      break;      
      default:
    }
  }

  const question = (refr) => {
    const elements = sceneActions.scene !== null ? sceneActions.scene.scene.layers[0].lines : [];

    switch (q.type){
      case "input_":
        return <div className="q-inputs">
          {q.options && q.options.map((o,i) => {
            
          })}
        </div>
      case 'tab':
      case 'tabs':
        return <SelectorInput table={q.table} q={q} itemID={itemId}/>
      case 'combo':
      case 'combos':
        return <Combo table={q.table} q={q} itemID={itemId}/>
        // return <div>{q.table} {itemId} <span>{JSON.stringify(q)}</span></div>
      case "bigBtn":
        return <div className='big-btns'>
          {q.options && q.options.map((o,i) => <div key={i} className="q-navbtn on" onClick={() => btnAction(o.a)}>{o.label}</div>)}
        </div>
      case 'defaultValue':
        return <div className="q-inputs">
        {q.options && q.options.map((o,i) => {
          let oldValue = projectActions.project.defaults[o.id] ?? '';
          switch(o.id){
            case 'height':
              oldValue = oldValue * 100
          }

          return q.button ? <form key={i} style={{display:'flex',justifyContent:'space-between',width:'100%',alignItems:'center'}} onSubmit={handleFormValue}>
            <label>
              {o.label && <div>{o.label}</div>}
              <input type="text" name={o.id} value={height} className="q-input size" onChange={(e) => setHeight(e.target.value)}/><br/>
              <span className="q-description">{o.description}</span>
            </label>
            <button type="submit" className="btncheck off" style={{border:'none',height:'2.5em',cursor:'pointer',padding:'.5em 1em'}}>Guardar</button>
          </form> 
          :
          <label key={i}>
              {o.label && <div>{o.label}</div>}
              <input placeholder={o.placeholder ?? ''} className="q-input size" type="number" style={{width:'30px',textAlign:'right'}} name={o.id} value={!isNaN(oldValue) ? oldValue : ''} onChange={(e) => handleDefaultValue(o.id,e.target.value)}/>
                {o.unit && <span dangerouslySetInnerHTML={{__html: ' ' + o.unit}} />}
              <br/>
              <span className="q-description">{o.description}</span>
          </label>
        })}
        </div>
      case 'title':
        return <>
          {q.q_title && <div className='c-title'>{q.q_title}</div>}
          {q.q_description && <div className='q-description'>{q.q_description}</div>}
        </>
      case 'subtitle':
        return <>
          {q.q_title && <div className='c-title' style={{fontSize:'28px'}}>{q.q_title}</div>}
          {q.q_description && <div className='q-description'>{q.q_description}</div>}
        </>
      case 'text':
        return <div className="q-inputs">
        {q.options && q.options.map((o,i) => {
          return <label key={i}>
            <input type="text" name={o.name} className="q-input size" placeholder={o.placeholder} onChange={handleProjectValue}/><br/>
            <span className="q-description">{o.description}</span>
          </label>
        })}
        </div>
      case 'number':
        return <div className="q-sizes">
        {q.options && q.options.map((o,i) => {
          let oldVal = null;
          switch (q.table){
            case 'project':
              oldVal = project.defaults[o.name]
            break;
            default:
          }
          return <label key={i}>
            <input type="number" name={o.name} className="q-input" placeholder={o.placeholder} onChange={handleProjectValue} value={oldVal}/><br/>
            <span className="q-description">{o.description}</span>
          </label>
        })}
        </div>
      case 'rangenum':
        return <div className="q-sizes">
        {q.options && q.options.map((o,i) => {
          let oldVal = null;
          switch (q.table){
            case 'project':
              oldVal = parseFloat(project.defaults[o.name])
            break;
            default:
          }
          return <label key={i} style={{width:'100%'}}>
            <input type="number" name={o.name} className="q-newsize" placeholder={o.placeholder} 
              onChange={handleProjectValue}
              min="200"
              max="300"
              data-datatype="height" 
              value={parseInt(oldVal * 100)}
            />cm
            <input type="range" name={o.name} className='q-size'
              style={{width:'100%'}}
              value={parseInt(oldVal * 100)}
              min="200"
              max="300"
              data-datatype="height"
              onChange={handleProjectValue}
            />
            <span className="q-description">{o.description}</span>
          </label>
        })}
        </div>
      case 'rooms':
        return <Rooms />
      case 'roomsize':
        const currentRoom =  actualLayer ? actualLayer.areas[0].floor : null;
        let currentArea = null;
        let options = null;
        if(projectActions.sceneData[projectActions.project.defaults.houseShape] && currentRoom !== null){
          currentArea = currentRoom.measurements.area;
          options = projectActions.sceneData[projectActions.project.defaults.houseShape].options
        }

        return options && <div className="q-roomsizes">
          {q.subtitle && <p className="q-title" dangerouslySetInnerHTML={{__html: q.subtitle}} />}
           {options.map((o,i) => {
             let testArea = currentArea < (o.area + .4) && currentArea > (o.area - .5)  
             if(i === 0){
               testArea = parseFloat(currentArea) < (o.area + .4)  
             } else if (i === options.length - 1){
               testArea = parseFloat(currentArea) > (o.area - .5)   
             }
             return <label key={i} style={{padding:'0.5em'}} >
               <input onChange={handleSetVertices} value={i} name="roomsize" type="radio" checked={testArea}/>
               <div>{o.label}<sup>2</sup>{")"} {o.area}</div>
             </label>
           }
        )}
        </div>
      case 'size':
        return <div>
          <label style={{display:'flex',flexDirection:'row',alignItems:'center',marginBottom:'1em'}}>
            <input style={{display:'inline',maxWidth:'30px',minWidth:'30px'}} checked={edit} type="checkbox" onChange={() => setEdit(!edit)}/>
            {q.edittitle ? q.edittitle : "Editar"}
          </label>
          <div className="q-sizes">       
          {edit && actualLayer.lines.filter(elem => elem.label).map((el,i) => {
            const vertices = actualLayer.vertices;
            return <label key={i}>
              <span className="q-description">Pared {el.label}</span>
              <input type="number" className={edit ? 'q-newsize editable' : 'q-newsize'}
                max="500"
                id={el.id} 
                value={parseInt(verticesDistance(vertices[el.v1],vertices[el.v2]) * 100)} 
                name={el.label} 
                disabled={!edit}
                onInput={sceneActions.changeSize}/><br/>
              <span>cm</span>
              {edit && <input type="range" className='q-newsize'
                max="500"
                min="10"
                id={el.id} 
                step="1"
                value={parseInt(verticesDistance(vertices[el.v1],vertices[el.v2]) * 100)} 
                name={"range_" + el.label} 
                onInput={sceneActions.changeSize}/>}
            </label>
          })}
        </div>
        </div>
      case 'collage':
        const positions=[
          [75,66],
          [50,40],
          [33,60],
        ]
        return <div className="q-collage">
          {q.options && q.options.map((img,i) => {
            const pos = {x:img.pos[0],y:img.pos[1]} 
            return <img style={{
              left:pos.x + '%',
              top:pos.y + '%'
            }} key={i} src={process.env.PUBLIC_URL + img.img} />
          })}
        </div>
      case 'activeImage':
        const pos=[
          [75,66],
          [50,40],
          [33,60],
        ]

        return qnrActions.activeImage ? 
          <div className="h-full" style={{
            backgroundImage:'url(' + process.env.PUBLIC_URL + qnrActions.activeImage.url + ')',
            backgroundRepeat:'no-repeat',
            backgroundSize:qnrActions.activeImage.size ?? 'cover',
            backgroundPosition:'center',
            width: "100%"
          }}>
          {qnrActions.activeImage.label && <div style={{
            position:'absolute',
            bottom:'10%',
            color:'#007DFF',
            textAlign:'center',
            width:'100%'
          }}>{qnrActions.activeImage.label}</div>}
          </div>
          :
          <div className="q-collage">
            {q.options[0] && q.options[0].defaultImage.map((img,i) => {
            const pos = {x:img.pos[0],y:img.pos[1]}
            return <img style={{
              left:pos.x + '%',
              top:pos.y + '%'
            }} key={i} src={process.env.PUBLIC_URL + img.img} />
          })}
        </div>
      case 'label':
        return <div className='q-labels'>{q.options && q.options.map((o,i) => {
          let oldVal = null;
          switch (q.table){
            case 'project':
              oldVal = project.defaults[o.name];
            break;
            default:
          }
          return <label key={i} className={oldVal == o.value ? "q-label on" : "q-label off"}>
            {o.img && <img className={oldVal !== o.value ? "q-icon" : "q-icon on"} src={o.img} alt={o.label}/>}
            {o.label && <div className="small center">{o.label}</div>}
            <input
              type="radio" 
              className='debug' 
              checked={oldVal == o.value} 
              name={o.name} value={o.value} 
              data-datatype={o.dataType} 
              onChange={handleProjectValue}
            />
          </label>
        })}</div>
      case 'addElement':
        return <ElementsDock q={q} handleDragStart={handleDragStart} handleDrop={handleDrop}/>
      case 'groupSelector':
        return <GroupSelector q={q}/>
      case 'groupSelector2':
        return <GroupSelector2 q={q}/>
      case 'styles':
        return <StyleSelector q={q} styles={qnrData.styles} />
      case 'styleimg':
        return <div style={{minWidth:'1000px'}}><StyleImg styles={qnrData.styles}/></div>
      case 'itemboxes':
        return <div className="q-itemboxes">{q.options && q.options.map((o,i) => {
          const itemCheck = checkFilterItems(actualLayer,parseInt(o.id_element),o.filter);
          return <div key={i} className={itemCheck && itemCheck.length > 0 ? "q-itembox on" : "q-itembox off"}>
            <label className="q-label">
              <div className={'flex items-center'}>
                {o.img && <img className="box-icon bg-white" src={o.img} alt={o.label}/>}
                {o.label && <div className="midtext">{o.label}</div>}
              </div>
              {itemCheck && itemCheck.length > 0
                  ? <span className="btncheck on"><BsCheck2 size="20px"/>
                    {/*<span className="box-btn-label">Añadido</span>*/}
                </span>
                  : <span className="btncheck off"><BsPlus size="20px"/>
                    {/*<span className="box-btn-label">&nbsp;&nbsp;Añadir&nbsp;&nbsp;</span>*/}
                </span> }
              <input type="checkbox"
                name={o.label}
                id={o.id}
                value={i}
                className="debug hide"
                onChange={handleItemBox}
                checked={itemCheck && itemCheck.length > 0}
                data-idelement={o.id_element}
                data-typology={o.filter ? o.filter[0].v : null}
                data-option={i}
                data-aid={q.area ?? null}
              />
            </label>
          </div>
        })}</div>
      case 'selectElementFeature':
        return <div>
          {q.options && q.options.map((o,i) => {
            const elementData = projectActions.elementsData.elements.find(item => item.id_element == o.id_element)
            return <Fragment key={i}>
              <div className='q-title' style={{margin:0}}>{o.title}</div>
              <FeatureInput table={q.table} action={handleFeatureChange} elementData={elementData} property={o} />
            </Fragment>
          })}

        </div>
      case 'selected':
        const foundSelected = getElementOfId(projectActions.scenes[q.table === 'integratedTable' ? 1 : 0].layers[0],null,sceneActions.selected)
        const filtered = checkFilterItem(foundSelected,q.filter,q.id_element)
        console.log('SELECTED',foundSelected)
        return (foundSelected && filtered) && <div>
          <hr style={{marginTop:'40px'}}/>
          Selected: {foundSelected.label}
        </div>
      case 'selectedProperties':
        return sceneActions.selected && <ElementProperties id={sceneActions.selected} q={q} sceneid={q.table === 'initialTable' ? 0 : 1}/> 
      case 'tabbedCoverings':
        return <Tabs defaultActiveKey="0">
          {q.options && q.options.map((o,i) => {
            return <Tabs.TabPane style={{flex:1}} tab={o.label} key={i}> 
              {o.title && <div className="q-small-title">{o.title}</div>}
              {o.description && <div className="q-description">{o.description}</div>}
            </Tabs.TabPane>
          })}
        </Tabs>
      case 'addDecision':
        const selectedItem = getElementOfId(actualLayer,null,sceneActions.selected)        
        return <div>
          Decisions: {sceneActions.selected}
          {q.options && q.options.map((o,i) => {
            const elementData = projectActions.elementsData.elements.find(item => item.id_element == o.id_element)
            return <Fragment key={i}>
              <div className='q-title' style={{margin:0}}>{o.title}</div>
              {/* <FeatureInput table={q.table} action={handleFeatureChange} elementData={elementData} property={o} /> */}
              <DecisionSelector id_element={o.id_element} />
            </Fragment>
          })}

        </div>
      case 'draggableElement':
        return <div className="q-details">{q.options && q.options.map((o,i) => {
          const existCheck = checkFilterItems(actualLayer,o.id_element,o.filter);
          return <div key={i} className={existCheck.length > 0 ? "q-detail on" : "q-detail off"}> 
            {o.icon && <div className='q-detail-item'
              draggable={true} 
              onDragStart={handleDragStart} 
              onDragEnd={handleDragEnd}
              data-option={i}
              data-name={o.name}
              data-label={o.label}
              data-typology={o.filter[0].v}
            >
            <img draggable={false} className="box-icon" src={o.icon} alt={o.label}/></div>}
            <div className="q-detail-content">
              {o.label && <div className="midtext">
                {o.label}
                {/* {q.optionsLabel && item && itemCheck && itemCheck.length > 0 && 
                  <div className='q-link' data-option={i} onClick={showModal}>{q.optionsLabel} <FiArrowRight /></div>} */}
              </div>}
            </div>
          </div>}
        )}</div>
      case 'itemchange':
        return <div className="q-itemboxes">
          {(q.list && q.list === 'room' && actualLayer) && actualLayer.areas.filter(a => {
            let testArea = true
            const tempLines = getAreaLines(actualLayer,a.id)
            {/* if(tempLines){
              testArea = tempLines.some(l=> l.holes.length > 0)
            } */}
            return testArea
          }).map((area,i) => {
            return <Fragment key={i}><div className='q-details'>{area.label}
              {area.lines.map((lineID,l) => {
                const tempLine = getLineOfId(actualLayer,lineID)
                return tempLine && <div key={l}>
                  {tempLine.holes.map((h,j) => {
                  const foundItem = getElementOfId(actualLayer,null,h)
                  const foundLabel = getItemValueLabel(projectActions.elementsData,foundItem,17)
                  let elementData = getElementData(projectActions.elementsData,q.id_element[0],foundItem.features[1])
                  const newValue = getNewFromCalcData(calcData,foundItem.id_element)
                  return foundItem && foundItem.id_element === q.id_element[0] && <div key={j} className="q-detail">
                  <div className='q-detail-item'>
                    {elementData.icon && <img className="box-icon" src={elementData.icon} alt={elementData.label}/>}
                  </div>
                  <div className="q-detail-content">
                    {foundItem.label && <div className="midtext" style={{fontSize:'12px',padding:'0 1em',margin:0,width:'180px',color:'#04073F'}}>
                      {foundItem.label ?? elementData.label} 
                      {q.modelaction && <div className='q-link' data-option={0} data-id={foundItem.id} onClick={showModal}>{foundLabel ?? q.optionsLabel}<FiArrowRight /></div>}
                    </div>}
                  </div>
                  <div className="d-btns">
                  {foundItem.features[7] === newValue 
                        ?
                        <span className="q-navbtn border"
                          onClick={() => sceneActions.makeActions('integratedTable',[{a:'del',id:foundItem.id}])}
                        >DELETE</span>
                        :
                      q.buttons && q.buttons.map((btn,k) => {
                        const checklist = checkActions(foundItem,btn.actions);

                        return <label key={k}>
                          <span className={checklist === true ? "bordercheck on" : "bordercheck off"}
                            style={{
                              color:btn.label === "Eliminar" ? "white" : "",
                              background:btn.label === "Eliminar" ? "#c00000" : "",
                            }}
                          >
                          {/* {checklist === true 
                            ? <BsCheck2 size="20px"/> 
                            : btn.label === 'Eliminar' ? <BsX size="20px"/> : <BsPlus size="20px"/>} */}
                          <span className="box-btn-label">{btn.label}</span></span>
                          <input type="radio"
                            className='debug'
                            name={'element_' + i}
                            data-which={j}
                            data-id={foundItem.id}
                            data-option={i}
                            data-idelement={foundItem.id_element}
                            data-typology={foundItem.features[1]}
                            data-actions={JSON.stringify(btn.actions)}
                            onClick={handleModButton}
                          />
                        </label>
                      })}   
                      {/* <div className='q-link' data-option={i} onClick={showModal}>{label ? label : q.optionsLabel}<FiArrowRight /></div>  */}
                      {q.actionbuttons && <div style={{display:'flex',justifyContent:'end',gap:'5px'}}>
                        {q.actionbuttons.map((btn,k) => {
                          return <button 
                            key={k} 
                            className='q-navbtn border' 
                            data-area={area.id} 
                            data-id={foundItem.id}
                            style={{padding:'5px',height:'30px'}} 
                            onClick={performActions} 
                            data-actions={JSON.stringify(btn.actions)}
                          >{btn.label}</button>
                        })}
                      </div>}                    
                  </div>
                  </div>
                })}
                {tempLine.items.map((it,j) => {
                  const foundItem = getElementOfId(actualLayer,null,it)
                  const foundLabel = getItemValueLabel(projectActions.elementsData,foundItem,17)
                  let elementData = getElementData(projectActions.elementsData,q.id_element[0],foundItem.features[1])
                  const newValue = getNewFromCalcData(calcData,foundItem.id_element)

                  return foundItem && foundItem.id_element === q.id_element[0] && <div key={j} className="q-detail">
                  <div className='q-detail-item'>
                    {elementData.icon && <img className="box-icon" src={elementData.icon} alt={elementData.label}/>}
                  </div>
                  <div className="q-detail-content">
                    {foundItem.label && <div className="midtext" style={{fontSize:'12px',padding:'0 1em',margin:0,width:'180px',color:'#04073F'}}>
                      {foundItem.label ?? elementData.label} 
                      {q.modelaction && <div className='q-link' data-option={0} data-id={foundItem.id} onClick={showModal}>{foundLabel ?? q.optionsLabel}<FiArrowRight /></div>}
                    </div>}
                  </div>
                      <div className="d-btns">

                      {foundItem.features[7] === newValue 
                        ?
                        <span className="q-navbtn border"
                          onClick={() => sceneActions.makeActions('integratedTable',[{a:'del',id:foundItem.id}])}
                        >DELETE</span>
                        :
                        q.buttons && q.buttons.map((btn,k) => {
                        const checklist = checkActions(foundItem,btn.actions);

                        {/* console.log('IN CHECK LIST',checklist,foundItem,btn.actions) */}

                        return <label key={k}>
                          <span className={checklist === true ? "bordercheck on" : "bordercheck off"}
                            // style={{background:btn.label === "Eliminar" ? "#c00000" : ""}}
                          >
                          {/* {checklist === true 
                            ? <BsCheck2 size="20px"/> 
                            : btn.label === 'Eliminar' ? <BsX size="20px"/> : <BsPlus size="20px"/>} */}
                          <span className="box-btn-label">{btn.label}</span></span>
                          <input type="radio" 
                            className='debug'
                            name={'element_' + i}
                            data-which={j}
                            data-id={foundItem.id}
                            data-idelement={foundItem.id_element}
                            data-option={i}
                            data-actions={JSON.stringify(btn.actions)}
                            data-typology={foundItem.features[1]}
                            onClick={handleModButton}
                          />
                        </label>
                      })}   
                      {/* <div className='q-link' data-option={i} onClick={showModal}>{label ? label : q.optionsLabel}<FiArrowRight /></div>  */}

                      {q.actionbuttons && <div style={{display:'flex',justifyContent:'end',gap:'5px'}}>
                        {q.actionbuttons.map((btn,k) => {
                          return <button 
                            key={k} 
                            className='q-navbtn border' 
                            data-area={area.id} 
                            data-id={foundItem.id}
                            style={{padding:'5px',height:'30px'}} 
                            onClick={performActions} 
                            data-idelement={foundItem.id_element}
                            data-actions={JSON.stringify(btn.actions)}
                          >{btn.label}</button>
                        })}
                      </div>}                    
                  </div>
                  
                  </div>
                })}
                
              </div>
            })}
            {area.floor.items.map((itemID,j) => {
              const foundItem = getElementOfId(actualLayer,null,itemID)
              const foundLabel = getItemValueLabel(projectActions.elementsData,foundItem,17)
              let elementData = getElementData(projectActions.elementsData,q.id_element[0],foundItem.features[1])

              return foundItem && foundItem.id_element === q.id_element[0] && <div key={j} className="q-detail">
                <div className='q-detail-item'>
                  {elementData.icon && <img className="box-icon" src={elementData.icon} alt={elementData.label}/>}
                </div>
                <div className="q-detail-content">
                  {foundItem.label && <div className="midtext" style={{fontSize:'12px',padding:'0 1em',margin:0,width:'180px',color:'#04073F'}}>
                    {foundItem.label ?? elementData.label} 
                    {q.modelaction && <div className='q-link' data-option={0} onClick={showModal} itemID={itemID}>{foundLabel ?? q.optionsLabel}<FiArrowRight /></div>}
                  </div>}
                </div>
                <div className="d-btns">
                      {q.buttons && q.buttons.map((btn,k) => {
                        const checklist = checkActions(foundItem,btn.actions);

                        return <label key={k}>
                          <span className={checklist === true ? "bordercheck on" : "bordercheck off"}
                            // style={{background:btn.label === "Eliminar" ? "#c00000" : ""}}
                          >
                          {/* {checklist === true 
                            ? <BsCheck2 size="20px"/> 
                            : btn.label === 'Eliminar' ? <BsX size="20px"/> : <BsPlus size="20px"/>} */}
                          <span className="box-btn-label">{btn.label}</span></span>
                          <input type="radio"
                            className='debug'
                            name={'element_' + i}
                            data-which={j}
                            data-id={foundItem.id}
                            data-option={i}
                            data-idelement={foundItem.id_element}
                            data-typology={foundItem.features[1]}
                            onClick={handleModButton}
                          />
                        </label>
                      })}   
                      {/* <div className='q-link' data-option={i} onClick={showModal}>{label ? label : q.optionsLabel}<FiArrowRight /></div>  */}
                      {q.actionbuttons && <div style={{display:'flex',justifyContent:'end',gap:'5px'}}>
                        {q.actionbuttons.map((btn,k) => {
                          return <button 
                            key={k} 
                            className='q-navbtn border' 
                            data-area={area.id} 
                            data-id={foundItem.id}
                            data-idelement={foundItem.id_element}
                            style={{padding:'5px',height:'30px'}} 
                            onClick={performActions} 
                            data-actions={JSON.stringify(btn.actions)}
                          >{btn.label}</button>
                        })}
                      </div>}                    
                  </div>
              </div>
            })}
            </div>
            {q.roombuttons && <div style={{display:'flex',justifyContent:'end',gap:'5px'}}>
              {q.roombuttons.map((btn,m)  => {
                return <button 
                  key={m} 
                  className='q-navbtn border' 
                  data-area={area.id}
                  style={{padding:'5px',height:'30px'}} 
                  onClick={performActions} 
                  data-actions={JSON.stringify(btn.actions)}
                >{btn.label}</button>
              })}
            </div>}
          </Fragment>
        })}
        </div>
      case 'details':
        return <div className="q-details">{q.options && q.options.map((o,i) => {
          const itemCheck = checkFilterItems(actualLayer,o.id_element,o.filter)

          let item = null
          let label = null;
          let superscript = null
          let checkEliminar = true;
          let itemNew = getNewFromCalcData(calcData,o.id_element)

          if(actualLayer){
            item = getItem(actualLayer,o.id_element,null,o.filter)
            label = item ? getItemValueLabel(projectActions.elementsData,item,17) : null
            superscript = item ? getItemValueSuper(projectActions.elementsData,item,17) : null          
          }

          if(itemCheck.length > 0 && q.buttons && item){
            checkEliminar = checkActions(item,q.buttons[0].actions)
          } 

          return <div key={i} className={itemCheck.length > 0 ? "q-detail on" : "q-detail off"}>
              <div className='q-detail-item'>
                {o.img && <img className="box-icon" src={o.img} alt={o.label}/>}
              </div>
              <div className="q-detail-content">
              {o.label && <div className="midtext" style={{fontSize:'12px',padding:'0 1em',margin:0,width:'180px',color:itemCheck && itemCheck.length > 0 ? '#007DFF' : '#04073F'}}>
                {o.label}
                {q.optionsLabel && item && itemCheck && itemCheck.length > 0 && checkEliminar === false &&
                  <>
                    <div className='q-link' data-option={i} data-id={item.id} onClick={showModal}>{label ? label : q.optionsLabel}<FiArrowRight /></div>
                  </>}
              </div>}
              {/* ---{item?.id}-{itemNew}-{item?.features[7]} */}
              {!item || item?.features[7] === itemNew
              ? <label>
                {itemCheck && itemCheck.length > 0 
                  ? <span className="btncheck on"><BsCheck2 size="20px"/></span> 
                  : <span className="btncheck off"><BsPlus size="20px"/></span>}
                <input type="checkbox" 
                  name={o.label} 
                  id={o.id}
                  value={i} 
                  className="debug"
                  onChange={handleItemBox}
                  checked={itemCheck && itemCheck.length > 0}
                  data-idelement={o.id_element}
                  data-typology={o.filter[0].v}
                  data-option={i}
                /></label>
              :
              <div className="d-btns">
                {q.buttons && q.buttons.map((btn,j) => {
                  const checklist = checkActions(item,btn.actions);
                  return <label key={j}>
                    <span className={checklist === true ? "bordercheck on" : "bordercheck off"}
                      // style={{background:btn.label === "Eliminar" ? "#c00000" : ""}}
                    >
                    {/* {checklist === true 
                      ? <BsCheck2 size="20px"/> 
                      : btn.label === 'Eliminar' ? <BsX size="20px"/> : <BsPlus size="20px"/>} */}
                    <span className="box-btn-label">{btn.label}</span></span>
                    <input type="radio"
                      className='debug'
                      name={'element_' + i}
                      data-which={j}
                      data-option={i}
                      data-idelement={item.id_element}
                      data-typology={o.filter[0].v}
                      onClick={handleModButton}
                    />
                  </label>
                })}    
              </div>}
              </div>
            </div>
          })}
        </div>
      case 'detailnums':        
        return <div className="q-details">
          {q.options && actualLayer && q.options.map((o,i) => {
            let item = null
            let label = null;
            let superscript = null;

            const quantity = actualLayer.items.filter(item => {
              return item.features[1] == o.filter[0].v
            })
            const itemData = projectActions.elementsData.items.find(item => item.type == o.filter[0].v)

            label = quantity[0] ? getItemValueLabel(projectActions.elementsData,quantity[0],17) : null
            superscript = item ? getItemValueSuper(projectActions.elementsData,item,17) : null

            return <div key={i} className='q-detail'>
            <div className='q-detail-item'>
                <img className='box-icon' src={o.img} alt={o.label} />
                <div className="q-itemnum">
                  <div className="midtext">{o.label}                  
                  {q.optionsLabel && itemData && quantity.length > 0 &&
                    <div className='q-link'  data-option={i} onClick={showModal}>{label ? label : q.optionsLabel}<FiArrowRight /></div>}
                  </div>
                </div>
                </div>
                <div className="plusminus">
                  <div className="sq-btn" data-idelement={o.id_element} data-typology={o.filter[0].v} data-name={o.name} data-label={o.label} data-option={i} onClick={removeElementNum}>-</div>
                  <div className="num">{quantity.length}</div>
                  <div className="sq-btn" data-idelement={o.id_element} data-typology={o.filter[0].v} data-name={o.name} data-label={o.label} data-option={i} onClick={addElement}>+</div>
                </div>
                </div>
            })}
        </div>
      case 'itemlist':
        return <div className="q-itemlist">
          {actualLayer && actualLayer.items.filter(it => {

            let test = []

            if(q.filter && q.filter.length > 0){
              q.filter.forEach(filt => {
                if(it.features[filt.f] === filt.v) {
                  test.push(true)
                } else {
                  test.push(false)
                }
              })
            }

            return q.id_element.includes(it.id_element) && test.some(t => t === true);
          }).map((item,i) => {
            return <span className="q-itemtab" key={i}>{item.name}<button
              onClick={removeElementNum}
              data-idelement={item.id_element}
              data-typology={item.features[1]}
            >x</button></span>
          })}
        </div>
      case 'itemmodlist':
        const modcount = actualLayer.items.filter(item => {
          let testFilter = []

          q.filter.forEach(ff => {
            if(item.features[ff.f] === ff.v){
              testFilter.push(true)
            } else {
              testFilter.push(false)
            }
          })

          return testFilter.some(fil => fil === true)
        }).length
        return <div className="q-itemmods">
          {actualLayer ? actualLayer.items.filter(item => {
          let testFilter = []

          q.filter.forEach(ff => {
            if(item.features[ff.f] === ff.v){
              testFilter.push(true)
            } else {
              testFilter.push(false)
            }
          })

          return testFilter.some(f => f === true)
        }).map((item,i) => {
            return <div key={i}>
              <span className="q-itemtab">{item.name}</span>
              {q.options && <div className='q-radios'>
                {q.options.map((o,j) => {
                  const checklist = checkActions(item,o.actions);

                  return <label key={j} className={checklist === true ? "q-radiobtn checked" : "q-radiobtn"} >
                  <input type="radio" id={o.id} name={'item_' + i} value={o.actions[0].v}
                    data-oid={o.id}
                    onChange={handleModification}
                    // checked={checklist}
                    className="debug"
                    data-option={j}
                    data-idelement={item.id_element}
                    data-typology={item.features[1]}
                  />{o.label}</label>})}
              </div>}
            </div>
          })
          :
          <h5>{q.emptyMessage}</h5>}
        </div>
      case 'itemnums':
        return <div className="q-itemboxes">
          {q.options && actualLayer && q.options.map((o,i) => {
            let itemCategory = 'items'
            switch(o.id_element){
              case 4:
              case 5:
              case 6:
                itemCategory = 'holes'
              break;
              default:
                itemCategory = 'items'
            }
            const quantity = actualLayer[itemCategory].filter(item => {
              return item.features[1] == parseInt(o.filter[0].v)
            })

            return <div key={i} className="q-itembox"><label>
                {o.img && <img className='box-icon' src={o.img} alt={o.label} />}
                <div style={{display:'flex',paddingLeft:'1em',width:'100%',justifyContent:'space-between',alignItems:'center'}}>
                  <div className="font-bold" title={q.table}>{o.label}</div>
                  <div className="plusminus">
                      <div className="sq-btn" data-name={o.name} data-label={o.name} data-idelement={o.id_element} data-typology={o.filter[0].v} data-option={i} onClick={removeElementNum}>-</div>
                      <div className="num">{quantity.length}</div>
                      <div className="sq-btn" data-name={o.name} data-label={o.name} data-idelement={o.id_element} data-typology={o.filter[0].v} data-option={i} onClick={addElement}>+</div>
                </div>
              </div></label>
            </div>
          })}
        </div>
      case 'addroom':
        return <div className="q-itemboxes">
          {q.options && actualLayer && q.options.map((o,i) => {
            const rooms = actualLayer.areas.filter(room => checkFilterItem(room,o.filter))
            return <div key={i} className="q-itembox"><label>
                {o.img && <img className='box-icon' src={o.img} alt={o.label} />}
                <div style={{display:'flex',paddingLeft:'1em',width:'100%',justifyContent:'space-between',alignItems:'center'}}>
                  <div className="font-bold" title={q.table}>{o.label}</div>
                  <div className="plusminus">
                      <div className="sq-btn" data-name={o.name} data-label={o.name} data-idelement={o.id_element} data-typology={o.filter[0].v} data-option={i} onClick={delRoom}>-</div>
                      <div className="num">{rooms.length}</div>
                      <div className="sq-btn" data-name={o.name} data-label={o.name} data-idelement={o.id_element} data-typology={o.filter[0].v} data-option={i} onClick={addRoom}>+</div>
                </div>
              </div></label>
            </div>
          })}
        </div>
      case 'btns':
        return <div>
          {q.name && <div>{q.name}</div>}
          <div  className="q-radiobtns">
          {q.options && q.options.map((o,i) => {
            const optchck = currentQnr[q.id].value === o.id
            return <label key={i} className="q-btn"
              style={{
                backgroundImage:o.img ? 'url(' + process.env.PUBLIC_URL + o.img + ')' : 'none',
                backgroundSize:'contain',
                minWidth:o.img ? '100px' : '48%',
                width:o.img ? '100px' : '48%',
                height:'55px',
                borderColor:optchck ? '#007DFF' : '#04073F',
                color:optchck ? '#007DFF' : '#04073F',
                fontSize:'12px',
                fontWeight:'500',
                paddingLeft:'10px'
              }}
            >
            <input type="radio" id={o.id} name={q.id} value={i}
                data-oid={o.id}
                data-aid={q.area ?? null}
                onChange={handleOptionChange}
                checked={optchck} 
                className="debug"
              />
              {o.label && o.label}
            </label>
          })}
          </div>
        </div>
      case 'sqrbtns':
        return <div>
          {q.name && <div>{q.name}</div>}
          <div  className="q-radiobtns">
          {q.options && q.options.map((o,i) => {
            const optchck = currentQnr[q.id].value === o.id
            return <label key={i} className={optchck === true ? "q-radiobtn checked" : "q-radiobtn"}
              style={{
                backgroundImage:o.img ? 'url(' + process.env.PUBLIC_URL + o.img + ')' : 'none',
                backgroundSize:'cover',
                borderColor:optchck ? '#007DFF' : '#D7D3CE',
                width:'100px',
                height:'100px',
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-end',
                padding:0,
                position:'relative'
              }}>
              {optchck && <div className="btncheck on" style={{position:'absolute',top:'5px',right:'5px',alignSelf:'flex-end'}}><BsCheck2 size="10px"/></div>}
              <input type="radio" id={o.id} name={q.id} value={i}
                data-oid={o.id}
                data-aid={q.area ?? null}
                onChange={handleOptionChange}
                checked={optchck} 
                className="debug"
              />
              {o.label && <div style={{
                padding:'5px 10px',
                borderBottomLeftRadius:'10px',
                borderBottomRightRadius:'10px',
                background:'linear-gradient(#0000,#0007,#0008)',
                color:optchck ? '#C7DEFC' : 'white',
                width:'100%'
              }}>
                {o.label}
              </div>}
              </label>
          })}
          </div>
        </div>
      case 'optbtns':
        return <div>
          {q.name && <div>{q.name}</div>}
          <div  className="q-radiobtns">
          {q.options && q.options.map((o,i) => {
            {/* const actualId = q.link ? q.link : o.id; */}

            const optchck = currentQnr[q.id].value === o.id
            return <label key={i} className={optchck === true ? "q-radiobtn checked" : "q-radiobtn"}
              style={{
                backgroundImage:o.img ? 'url(' + process.env.PUBLIC_URL + o.img + ')' : 'none',
                backgroundSize:'cover',
                minWidth:'48.5%',
                height:'140px',
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-end',
                borderColor:optchck ? '#007DFF' : '#D7D3CE',
                padding:0,
                position:"relative"
              }}>
                            {optchck && <div className="btncheck on" style={{position:'absolute',top:'5px',right:'5px',alignSelf:'flex-end'}}><BsCheck2 size="10px"/></div>}

              <input type="radio" id={o.id} name={q.id} value={i}
                data-oid={o.id}
                data-aid={q.area ?? null}
                onChange={handleOptionChange}
                checked={optchck} 
                className="debug"
              />
              {o.label && <div style={{
                padding:'5px 10px',
                borderBottomLeftRadius:'10px',
                borderBottomRightRadius:'10px',
                background:'linear-gradient(#0000,#0007,#0008)',
                color:optchck ? '#C7DEFC' : 'white',
                width:'100%'
              }}>
                {o.label}
              </div>}
              </label>
          })}
          </div>
        </div>
      case 'option':
        return <div>
          {q.name && <div>{q.name}</div>}
          <div  className="q-radiobtns">
          {q.options && q.options.map((o,i) => {
            {/* const actualId = q.link ? q.link : o.id; */}

            const optchck = currentQnr[q.id].value === o.id
            return <label key={i}>
              <input type="radio" id={o.id} name={q.id} value={i}
                data-oid={o.id}
                data-aid={q.area ?? null}
                onChange={handleOptionChange}
                checked={optchck}
              />{o.label}</label>
          })}
          </div>
        </div>
      case 'widebtns':
        return <>
          {q.name && <div>{q.name}</div>}
          <div className="q-widebtns" style={{
            flexDirection:q.wrap ? 'row' : 'column',
            flexWrap:'wrap'
          }}>
          {q.options && q.options.map((o,i) => {
            const optchck = currentQnr[q.id].value === o.id
            return <div key={i} style={{
                position:'relative',
                width: q.wrap ? '48%' : '100%'
              }}>
              <label className={optchck === true ? "q-widebtn checked" : "q-widebtn"}>
                {o.icon && <img style={{alignSelf:'flex-start'}} src={o.icon} alt={o.label}/>}
              <div style={{width:'100%'}}>
                {o.label && <div className="midtext">{o.label}</div>}
                <div className='q-description'>{o.description}</div>
                {o.img && <div style={{height:'2em'}} />}
                  <input type="radio" id={o.id} name={q.id} value={i}
                    data-oid={o.id}
                    data-aid={q.area ?? ''}
                    onChange={handleOptionChange}
                    checked={optchck} 
                    className="debug"
                /></div>
                {optchck ? <span className="btncheck on"><BsCheck2 size="20px"/></span> 
                  : <span className="btncheck off"><BsPlus size="20px"/></span> }
                </label>     
              {o.img && o.modal && <div className="q-link inside" data-option={i} onClick={showModal}>Ver imagen <FiArrowRight /></div>}
            </div>
          })}
          </div>
        </>
      case 'widecheckboxes':
        return <>
          {q.name && <div>{q.name}</div>}
          <div className="q-widebtns" style={{
            flexDirection:q.wrap ? 'row' : 'column',
            maxWidth:'440px',
            flexWrap:'wrap'
          }}>
          {q.options && q.options.map((o,i) => {

            {/* console.log('------',o.id_element,currentQnr[q.id]) */}

            const optchck = currentQnr[q.id].checked ? currentQnr[q.id].checked.has(o.id) : false;
            {/* const optchck = currentQnr[q.id].checked.has(o.id) */}
            return o.id_element === 19
            ? 
              actualLayer.areas.filter(room => checkFilterItem(room,o.filter)).map((room,j) => {
                const aId = o.id.split('-');
                const newId = aId[0] + '-' + aId[1] + '-' + (parseInt(aId[2]) + j)
                const newoptchck = currentQnr[q.id].checked ? currentQnr[q.id].checked.has(newId) : false;
                return <div key={j} style={{position:'relative',width:q.wrap ? '20%' : '100%'}}><label className={newoptchck === true ? "q-widebtn checked" : "q-widebtn"}>
            <div className={'flex items-center'}>
                {o.icon && <img className="box-icon bg-white" src={o.icon} alt={o.label}/>}
                {o.label && <div className="midtext">{room.label}</div>}
              </div>
              <div>
                <div className='q-description'>{o.description}</div>
                {o.img && <div style={{height:'2em'}} />}
                  <input type="checkbox" id={newId} name={q.id} value={newId}
                    data-oid={newId}
                    data-aid={q.area ?? null}
                    onChange={handleCheckChange}
                    checked={newoptchck} 
                    className="debug"
                /></div>
                {newoptchck ? <span className="btncheck on"><BsCheck2 size="20px"/></span> 
                  : <span className="btncheck off"><BsPlus size="20px"/></span> }
                </label>     
              {o.img && o.modal && <div className="q-link inside" data-option={i} onClick={showModal}>Ver imagen <FiArrowRight /></div>}
            </div>
              })
            : <div key={i} style={{position:'relative',width:q.wrap ? '48.5%' : '100%'}}><label className={optchck === true ? "q-widebtn checked" : "q-widebtn"}>
            <div className={'flex items-center'}>
                {o.icon && <img className="box-icon bg-white" src={o.icon} alt={o.label}/>}
                {o.label && <div className="midtext">{o.label}</div>}
              </div>
              <div>
                <div className='q-description'>{o.description}</div>
                {o.img && <div style={{height:'2em'}} />}
                  <input type="checkbox" id={o.id} name={q.id} value={o.id}
                    data-oid={o.id}
                    data-aid={q.area ?? null}
                    onChange={handleCheckChange}
                    checked={optchck} 
                    className="debug"
                /></div>
                {optchck ? <span className="btncheck on"><BsCheck2 size="20px"/></span> 
                  : <span className="btncheck off"><BsPlus size="20px"/></span> }
                </label>     
              {o.img && o.modal && <div className="q-link inside" data-option={i} onClick={showModal}>Ver imagen <FiArrowRight /></div>}
            </div>
          })}
          </div>
        </>
      case 'selectbtns':
        return <div>
          {q.name && <div>{q.name}</div>}
          <div  className="q-widebtns">
          {q.options && q.options.map((o,i) => {
            const optchck = currentQnr[q.id].value === o.id
            return <label key={i} className={optchck === true ? "q-widebtn checked" : "q-widebtn"}>
              <div><div className='q-label'>{o.label}</div>
              <div className='q-description'>{o.description}</div>
              <input type="radio" id={o.id} name={q.id} value={i}
                data-oid={o.id}
                onChange={handleOptionChange}
                checked={optchck} 
                className="debug"
              /></div>
              {optchck  ? <span className="btncheck on"><BsCheck2 size="20px"/></span> 
                : <span className="btncheck off"><BsPlus size="20px"/></span> }
              </label>
          })}
          </div>
        </div>
      case 'lvlbtns':
        return <div style={{margin:'1em 0'}}>
          {q.name && <div style={{marginBottom:'-0.5em'}}>{q.name}</div>}
          <div  className="q-lvlbtns">
          {q.options && q.options.map((o,i) => {
            const optchck = currentQnr[q.id].value === o.id
            return <label key={i} className={optchck === true ? "q-radiobtn checked" : "q-radiobtn"}>
              <input type="radio" id={o.id} name={q.id} value={i}
                data-oid={o.id}
                onChange={handleOptionChange}
                checked={optchck} 
                className="debug"
              />{o.label}</label>
          })}
          </div>
        </div>
      case 'cards':
        return <div className="q-cards">
          {q.options && q.options.map((o,i) => {
            const actualId = q.link ? q.link : q.id 
            const cardChck = currentQnr[actualId].value === o.id
            const cardCss = {
              background:o.color ? o.color : 'gray'
            }
            {/* console.log('in panel',actualId) */}
            return <label key={i} className="q-card" style={cardCss} >
              <div>
                {o.icon && <img className="box-icon" src={o.icon} alt={o.label}/>}
                {o.label && <div className="q-cardtitle" dangerouslySetInnerHTML={{__html: o.label}} />}
                {o.description && <div className="q-description">{o.description}</div>}
                <input type="radio" name={q.id} value={i}
                  data-oid={o.id}
                  data-link={q.link}
                  onChange={handleOptionChange}
                  checked={cardChck}
                  className="debug"                    
                />
              </div>
              {cardChck === true && <Overlay />}
            </label>
          })}
        </div>
      case 'infocards':
         return <div className="q-info-cards" style={{
          flexDirection:q.column ? 'column' : 'row'
         }}>
          {q.options && q.options.map((o,i) => {
            const actualId = q.link ? q.link : q.id 
            const cardChck = currentQnr[actualId].value === o.id
            const iCardCss = {
              background:o.color ? o.color : 'gray',
              cursor:'default',
              width:q.size && '100%'
            }
            const iCss = {
              fontSize:'12px',
              paddingTop:'10px',
              position:'absolute',
              top:'-20px',
              left:'-20px',
              background:'#007DFF',
              width:'40px',
              height:'40px',
              borderRadius:'50%',
              textAlign:'center',
              overflow:'visible',
              color:'white'
            }

            const listCss = {
              color:'#04073F',
              listStyle:'none',
              margin:'2em',
              listStyle:'square',
              display:'none'
            }
            
            return <div key={i} style={{position:'relative',marginTop:'2em',marginLeft:'20px',width:q.size && '100%'}}>
                  <div className="q-card qnr-card-custom flex gap-4 qnr-h-auto" style={iCardCss}>
                    {o.icon && <img className="box-icon" src={o.icon} alt={o.label} style={{background:'white',width:'72px',height:'72px'}}/>}
                    <div>
                      {o.label && <div className="q-small-title pb-2 my-0" dangerouslySetInnerHTML={{__html: o.label}} />}
                      {o.description && <div className="q-description" style={{color:' #777786',opacity:1,minHeight:'4em'}}>{o.description}</div>}
                      {o.list && <ul style={listCss} className='checkpoint-list'>
                        {o.list.map((li,j) => <li key={j}>
                          {li.title && <div style={{
                            textDecoration:li.checked ? 'line-through' : 'none'
                          }}>{li.title}</div>}
                          {li.description && <div style={{
                            color:'#555',
                            fontSize:'0.8em',
                            marginBottom:'1em'
                          }}>{li.description}</div>}
                        </li>)}
                      </ul>}
                      {o.buttons && o.buttons.map((btn,k) => <Button className="q-btn" style={{
                          width:'100%',
                          borderRadius:'10px',
                          padding:'1em 0',
                          height:'4em'
                        }}
                         data-option={i}
                         value={k}
                         onClick={handleActions}
                         key={k}
                      >{btn.label}</Button>)}
                    </div>

                </div>
                {q.numbers && <div style={iCss}>0{i + 1}</div>}
              </div>
          })}
        </div>
      case 'infopanel':
        return <div className="q-cards" style={{justifyContent:'space-around',marginBottom:'5em',flexWrap:'wrap'}}>
          {q.options && q.options.map((o,i) => {
            const actualId = q.link ? q.link : q.id 
            const cardChck = currentQnr[actualId].value === o.id
            const iCardCss = {
              background:o.color ? o.color : 'gray',
              minHeight:'15em',
              cursor:'default'
            }
            const iCss = {
              fontSize:'12px',
              paddingTop:'10px',
              position:'absolute',
              top:'-20px',
              left:'-20px',
              background:'#007DFF',
              width:'40px',
              height:'40px',
              borderRadius:'50%',
              textAlign:'center',
              overflow:'visible'
            }
            const listCss = {
              color:'#04073F',
              listStyle:'none',
              margin:'2em',
              listStyle:'square'
            }
            return <div key={i} style={{position:'relative',marginTop:'3em'}}>
                  <div className="q-panel" style={iCardCss}>
                    {o.icon && <img className="box-icon" src={o.icon} alt={o.label} style={{background:'white'}}/>}
                    {o.label && <div className="q-small-title" dangerouslySetInnerHTML={{__html: o.label}} />}
                    {o.description && <div className="q-description" style={{color:' #777786',opacity:1,minHeight:'7em'}}>{o.description}</div>}
                    {o.list && <ul style={listCss} className='checkpoint-list'>
                      {o.list.map((li,j) => <li key={j}>
                        {li.title && <div style={{
                          textDecoration:li.checked ? 'line-through' : 'none'
                        }}>{li.title}</div>}
                        {li.description && <div style={{
                          color:'#555',
                          fontSize:'0.8em',
                          marginBottom:'1em'
                        }}>{li.description}</div>}
                      </li>)}
                    </ul>}
                    {o.buttons && o.buttons.map((btn,k) => <Button style={{
                      width:'100%',
                      borderRadius:'10px',
                      padding:'1em 0',
                      height:'4em'
                    }} className='q-btn' key={k}>{btn.label}</Button>)}
                </div>
                {q.numbers && <div style={iCss}>0{i + 1}</div>}
              </div>
          })}
        </div>
      case 'squares':
        return <div className="q-squares">
          {q.options && q.options.map((o,i) => {
            const actualId = q.link ? q.link : q.id 
            const cardChck = currentQnr[actualId].value === o.id
            const cardCss = {
              background:o.color ? o.color : 'gray'
            }
            return <label key={i}
              className={(location.pathname.includes('cocina') ? 'q-square-kitchen ' : '') + 'q-square'}
              style={{...cardCss,
                backgroundColor:'white',
                backgroundImage:'url(' + process.env.PUBLIC_URL + o.img[0] + ')',
                backgroundPosition:'center',
                backgroundSize:'cover',
                backgroundRepeat:'no-repeat',
                borderRadius:'1em',
                display:'relative',
              }}
            >
              {cardChck === true && <Overlay />}
            {o.label && <div className="q-cardtitle" dangerouslySetInnerHTML={{__html: o.label}} />}
            {o.description && <div className="q-description">{o.description}</div>}
              <input type="radio" name={q.id} value={i}
                data-oid={o.id}
                data-link={q.link}
                onChange={handleOptionChange}
                checked={cardChck}
                className="debug"
              />
            </label>
          })}
        </div>
      case 'squares_':
        return <div className={'h-full p-4 flex flex-col justify-center items-center'}>
          <div className={'flex w-full justify-end gap-4 pb-4 wideonly'}>
            <span className="cursor-pointer"
              onClick={() => {
                document.getElementById('qnr-square').scrollLeft -= 370;
              }}
            >
              <BsArrowLeft size={20} />
            </span>
            <span className="cursor-pointer"
              onClick={() => {
                document.getElementById('qnr-square').scrollLeft += 370;
              }}
            >
              <BsArrowRight size={20} />
            </span>
          </div>
          <div className={'qnr-square'} id="qnr-square">
            {q.options && q.options.map((o,i) => {
              const actualId = q.link ? q.link : q.id
              const cardChck = currentQnr[actualId].value === o.id
              return <label key={i} className={'qnr-square-card'}>
                <img alt="" className={'rounded'} src={process.env.PUBLIC_URL + o.img[0]} />
                <div>
                  <div className="flex justify-between p-4 items-center color-blue-dark qnr-square-title">
                    <h3 className={'font-bold m-0 truncate'}>{o.label}</h3>
                    <h3 className="font-bold m-0 flex gap-3 items-center wideonly">
                      <span className={'truncate'}>Ver detalles</span>
                      <BsArrowRight size={25} />
                    </h3>
                  </div>

                  <div className="flex gap-3 justify-between items-center px-4 pb-4">
                    <p className={'m-0 text-secondary'}>{o.description}</p>
                    <div className="check-selected-mobile">
                      {cardChck
                        ? <span className="btncheck on"><BsCheck2 size="20px"/></span>
                        : <span className="btncheck off"><BsPlus size="20px"/></span>
                      }
                    </div>
                  </div>
                </div>
                {cardChck && <div className={'check-selected'}><Overlay /></div>}
                <input type="radio" name={q.id} value={i}
                   data-oid={o.id}
                   data-link={q.link}
                   onChange={handleOptionChange}
                   checked={cardChck}
                   className="debug hide"
                />
              </label>
            })}
          </div>
        </div>
      case 'address':
        return <div>
          <ZipCodeCities citiesData={qnrCities} refresh={refresh} setRefresh={setRefresh}  messages={messages}/>
          {/* {authActions.currentUser && <div>
          <hr style={{margin:'2em'}}/>
            <input placeholder='Nombre del proyecto' id="projectName" name="Nombre del proyecto" />
            <div className="q-navbtn border" onClick={handleSaveNewProject} >Guardar</div>
          </div>} */}
        </div>
      case 'imgconnect':
        // const styleImg = project.defaults.style ? q.options[project.defaults.style].img : q.options[0].img;
        console.log(projectActions.styles,project.defaults,project.defaults.style,project.defaults.substyle ?? 0)
        return <div className="q-fullImg" style={{
            background:'url(' + process.env.PUBLIC_URL + projectActions.styles[project.defaults.style ?? 0].substyles[project.defaults.substyle ?? 0].url + ')',
            backgroundSize:'cover',
            width: "100%"
          }}>
          </div>
      case 'imgbig':
        return <div className="q-fullImg" style={{
            backgroundImage:'url(' + process.env.PUBLIC_URL + q.options[0].img + ')',
            backgroundRepeat:'no-repeat',
            backgroundSize:'cover',
            width: "100%"
          }}>
          </div>
      case 'imgfull':
        return <div className='styleImg'
            style={{
              background:`url(${process.env.PUBLIC_URL}'/icons/preloader.gif')`,
              backgroundSize:'cover',
              backgroundPosition:'center', 
              minWidth:'700px',
              backgroundRepeat:'no-repeat'                
            }}>
            <div style={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-end',
                height:'100%',
                backgroundImage:`url(${process.env.PUBLIC_URL}${projectActions.styles[project.defaults.style ?? 0].substyles[project.defaults.substyle ?? 0].url})`,
                backgroundSize:'cover',
                backgroundPosition:'center',
                color:'white',
                borderRadius:'1em'
            }}>
          </div>
        </div>
      case 'carousel':
        return <Carousel id={q.id} q={q} action={handleOptionChange} />
      case 'slider':
        const actualId = q.link ? q.link : q.id
        const old = currentQnr[actualId].value
        return <Slider id={q.id} old={old} q={q} action={handleOptionChange} />
      case 'confirm':
        return <div className="mt-1 q-description">
          {q.options && q.options.map((o,i) => {
            switch (o.type){
              case 'checkbox':
                return <input key={i} type="checkbox" name="confirm" />
              case 'link':
                return <a key={i} href={o.actions[0].link}>{o.content}</a>
              default:
                return <span key={i}>{o.content}</span>
            }
          })}
        </div>
      case 'tabbedPanel':
        const elementData = q.options.length > 0 ? projectActions.elementsData.elements.find(item => item.id_element == q.options[activeTab].e) : null
        const id = getIdfromSelected(actualLayer,sceneActions.selected )
        return <div>
          <div style={{
            // position:'absolute'
          }}> 
            {q.options && q.options.map((o,i) => 
              <button key={i} className={i === activeTab ? 'q-navbtn on' : 'q-navbtn'} onClick={() => {setActiveTab(i)}}>{o.label}</button>
            )}
          </div>
          <div>
            {q.options.length > 0 && <div style={{paddingTop:'0.5em'}}>
              {q.options[activeTab].type === 'shapePlanner' && <ShapePlanner q={q} />}
              {q.options[activeTab].type === 'imageSlider' && <ImageSlider refresh={projectActions.refresh} actualLayer={actualLayer} o={q.options[activeTab]} elementData={elementData}/>}
              {q.options[activeTab].type === 'wallFloorModels' && q.options[activeTab].children && q.options[activeTab].children.map((child,j) => {
                const edata = projectActions.elementsData.elements.find(item => item.id_element == child.e)
                  return <Selector key={j} src={child} refresh={projectActions.refresh} actualLayer={actualLayer} elementData={edata} setActiveTab={setActiveTab}/>
                }
              )}
            </div>}
          </div>
        </div>
      case 'roomproperties':
        return q.options.map((o,i) => {
          return <div key={i}>{o.label}</div>
        })
      case 'shapePlanner':
        return <ShapePlanner q={q} />
      case 'shapePlanner2':
        return <ShapePlanner2 q={q}/>
      case 'performAction':
        return q.options.map((o,i) => {
          let inp = null;
          switch (o.input){
            case 'link':
              inp = <div className={o.access && o.access === 'debug' ? "q-link debug" : "q-link"} data-option={i} onClick={performActions}>{o.label} <FiArrowRight /></div>
            break; 
            case 'button':
              inp = <button className={o.access && o.access === 'debug' ? "q-button debug" : "q-button"} data-option={i} onClick={performActions}>{o.label} <FiArrowRight /></button>
            break;
            default:
          }          
          return <div key={i} style={{marginBottom:'20px'}}>
            {inp} <hr/>
          </div>})
      case 'thumbs':
          return <div className="thumbsCanvas">
            {q.options && q.options.map((o,i) => {
              return <canvas key={i} width="150" height="100" className="thumb"></canvas>
            })}
          </div>
      case 'results':
        const dividedResult = divideResult(projectActions.scenes[1],projectActions.results)

        console.log('DIVIDED RESULTS',dividedResult)

        return projectActions.calcData && (
            <div style={{padding:'1em 0'}} className={`bg-white w-full h-full ${q.wideonly ? 'wideonly' : ''} ${q.mobileonly ? 'mobileonly' : ''}`}>
              <div className={'qnr-budget w-full overflow-auto'}>
              {dividedResult.map((result,i) => {
                return result.jobs.length > 0 ? <Fragment key={i}>
                <h5 className='c-title' style={{fontStyle:'italic',fontSize:"24px",marginTop:'40px',textDecoration:'underline'}}>{result.title}</h5>
                <Budget result={result} isBasicBudget={true} blur={q.blur ?? true} isHeader={false} isFooter={false}/>
                </Fragment>
               : null})}
              </div>
            </div>
        )
      case 'table':
        return null
      case 'resultlist':
        return projectActions.calcData && <Results />
      case 'actionbtn':
        const foundSum = q.options.find(o => o.icon === 'sum')
        return <div className='q-actionbtns' style={{
          alignItems:'center',
          position:q.position && q.position === 'bottom' ? 'fixed' : 'static',
          background:q.position && q.position === 'bottom' ? 'white' : 'none',
          bottom:q.position && q.position === 'bottom' ? '1em' : '',
          justifyContent:foundSum ? 'space-between' : 'flex-end'
        }}>
          {q.options && q.options.map((o,i) => <div key={i} className={o.icon === 'sum' ? "m-sum-display" : "q-navbtn grey"} data-option={i} onClick={handleActions}>
            {o.label}&nbsp;&nbsp;{o.icon && o.icon === 'arrow' && <FiArrowRight />}
            {o.icon === 'sum' && <Sum />}
          </div>)}
        </div>
      case 'summary':
        return <Summary q={q} messages={messages} />
      case 'register':
        return <Register q={q} messages={messages} />
      case 'saveProject':

      console.log('========================',projectActions.project.defaults)

        return <div className='save-panel'>
            <input placeholder='Nombre del proyecto' id="projectName" name="Nombre del proyecto" />
            <div className="debug">{authActions.selectedUser}</div>
            <div className="q-navbtn border primary" onClick={handleSaveNewProject}>Guardar</div>
        </div>
      case 'save':
        const debugCss = {
              width:'100%',
              margin:'1em 0 0 0',          
        }
        return <div className="debug" style={{
          border:'3px solid red',
          borderRadius:'5px',
          padding:'1em',
          marginTop:'2em'
        }}>
          <Button onClick={() => setSaveOpen(!saveOpen)}><FiChevronDown/>UPDATE EXISTING PROJECT<FiChevronDown/></Button>
          {saveOpen && <div>
          <input name="projectID" placeholder="project ID here..." onChange={(e) => setProjectID(e.target.value)}/>
          <input name="projectName" placeholder="project Name here... (optional)" onChange={(e) => setProjectName(e.target.value)}/>
            <Button onClick={handleSaveProject} style={debugCss}>UPDATE PROJECT</Button>
            <Button onClick={handleCreateProject} style={debugCss}>CREATE NEW PROJECT</Button>        
            <Button onClick={handleAddResult} style={debugCss}>ADD RESULT</Button>
          </div>}    
        </div>
      default:
        return <div>Q type: {q.type}</div>
    }
  } 

  const hidClass = (q.blur && q.blur === 0) ? 'q-quest q-blur' : 'q-quest'; 

  return (checkIfConnected(currentQnr,q) &&
    <div 
      className={`q-question ${q.wideonly ? 'wideonly' : ''} ${q.mobileonly ? 'mobileonly' : ''}`}         
    >
      <span className="debug" style={{position:'absolute',right:'10px'}}>{q.id}</span>
      {q.title && <p className="q-title" dangerouslySetInnerHTML={{__html: (q.title2 && edit) ? q.title2 : q.title}} />}
      {q.colapsable && <div style={{cursor:'pointer'}} onClick={() => setQuestionColapse(!questionColapse)}>{questionColapse === false ? <FiChevronUp size="2em"/> : <FiChevronRight size="2em"/>}</div>}
      {(!q.colapsable || questionColapse === false) && <>
      {q.description && <p className="q-description" dangerouslySetInnerHTML={{__html: q.description}} />}
        <div className={hidClass} style={{
          position:'relative'
        }}>{question(refresh)}</div>
        {q.message && <div className="q-message">
          {(q.message && q.message.icon) && <span className="q-icon">{q.message.icon}</span>}
          <p dangerouslySetInnerHTML={{__html: q.message.text}} />
        </div>}
        {q.q_tooltip && <div style={{position:'absolute',top:0,right:0}}><Tooltip title={
          <><QuestionCircleOutlined /> 
            {q.q_tooltip}
          </>} color={"blue"} >
          <QuestionCircleOutlined />
        </Tooltip>
        </div>}
      </>}   
    </div>
  )
}

export default Question
