import { useState, useContext, useEffect } from 'react'
import ProjectContext from '../../context/ProjectContext'
import SceneContext from '../../context/SceneContext'
import { getElementOfId } from '../../utils/sceneUtils'
import PropertyInput from './PropertyInput'
import PropertyInput2 from './PropertyInput2'

const ElementProperties = ({q,id,sceneid}) => {
    const projectActions = useContext(ProjectContext)
    const sceneActions = useContext(SceneContext)

    const [elementProperties, setElementProperties] = useState([])
    const [itemProperties, setItemProperties] = useState([])
    const [item, setItem] = useState(null) 
    
    useEffect(() => {
        const layer = projectActions.scenes[sceneid ?? 0].layers[0]
        const selectedItem = getElementOfId(layer,null,sceneActions.selected)
        setItem(() => selectedItem ?? null)
        const elementData = projectActions.elementsData.elements.find(item => item?.id_element === selectedItem.id_element)
        let itemData = null

        if(selectedItem.id_element >= 4 && selectedItem.id_element <= 6){
            itemData = projectActions.elementsData.holes.find(item => item.id_element === selectedItem.id_element)
        } else if (selectedItem.id_element !== 1 && selectedItem.id_element !== 19){
            itemData = projectActions.elementsData.items.find(item => item.id_element === selectedItem.id_element)
        }
        setElementProperties(() => elementData.properties ?? []) 

        if(itemData){
            setItemProperties(() => itemData.properties ?? []) 
        } else {
            setItemProperties(() => []) 
        }
        console.log('IN PROPS',elementData,selectedItem)
    },[sceneActions.selected])

    const handleAction = (e) => {
        const {dataset} = e.target
        const newValue = e.target.value
        if(dataset.measurement){
            console.log('Changing measurement',dataset,newValue)
            sceneActions.setAreaScale('all',0,item.id,newValue)
        } else if (dataset.feature){
            console.log('Changing Feature',dataset,newValue)
            // sceneActions.setFeature(null,dataset.feature,newValue,dataset.idelement,[],dataset.itemid)
            sceneActions.setFeatureOfAll('all',0,dataset.itemid,dataset.feature,newValue)
        } else if (dataset.property){
            console.log('Changing prop',dataset,newValue)
            sceneActions.setProperty('all',dataset.property,newValue,dataset.id_element,dataset.itemid)
        }
        sceneActions.setRefresh(!sceneActions.refresh)
    }

  return (
    <div style={{marginTop:'2em'}}>
    <hr />
    <p className="q-title" style={{textDecoration:'underline'}}>{item && item.label} Properties:</p>
        {elementProperties.filter(pr => {
            return !pr.mode || q.mode === pr.mode
        }).map((prop,i) => {
            return <div key={i}><PropertyInput2 action={handleAction} property={prop} item={item} areaID={null}/></div>
        })}
        {itemProperties.filter(pr => {
            return !pr.mode || q.mode === pr.mode
        }).map((prop,i) => {
            return <div key={i}><PropertyInput2 action={handleAction} property={prop} item={item} areaID={null}/></div>
        })}
    </div>
  )
}

export default ElementProperties