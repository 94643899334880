import { useState, useEffect, useContext } from 'react'
import ProjectContext from '../../context/ProjectContext'
import SceneContext from '../../context/SceneContext'
import QnrContext from '../../context/QnrContext'
import { checkFilterItem, checkValueFilter, getElementData, getElementOfId, getFeatureValue, getGroupFeatureValue } from '../../utils/sceneUtils'
import Thumb from './Thumb'
import { elementIdToName } from '../../utils/formatUtils'

const ModelSelector = ({option, localStyle = 0, localSubstyle = 0, action, material = 17, itemID, groupID}) => {

    const projectActions = useContext(ProjectContext)
    const sceneActions = useContext(SceneContext)
    const qnrActions = useContext(QnrContext)

    const [elementData, setElementData] = useState(getElementData(projectActions.elementsData,option.id_element))
    const [propertyData, setPropertyData] = useState(null)
    const [activeLayer, setActiveLayer] = useState(null)
    const [item, setItem] = useState(null)
    const [group, setGroup] = useState(null)
    const [oldValue, setOldValue] = useState(null)

    useEffect(() => {
        const data = getElementData(projectActions.elementsData,parseInt(option.id_element))
        setElementData(() => data)
        setPropertyData(() => data ? data.properties.find(property => property.name === option.property) : null)
        
        const layer = projectActions.scenes[1].layers[0]

        if(layer.groups && groupID){            
            const foundGroup = layer.groups.find(group => group.id === groupID);
            if(foundGroup){ 
                setGroup(() => foundGroup)
                setOldValue(() => getGroupFeatureValue(layer,foundGroup.id,(propertyData && propertyData.feature) ? propertyData.feature : null))
                if(foundGroup.items.length > 0){
                    const tempItem = getElementOfId(layer,null,foundGroup.items[0])
                    setItem(tempItem)
                }
            }
        }
        
        if(parseInt(option.id_element) === 2){
            setItem(layer.areas[0].floor)
        } else if(itemID){
            setItem(getElementOfId(layer,null,itemID))
        }
        
        if(item){ 
            setOldValue(getFeatureValue(item,propertyData?.feature))
        }
        
        console.log('---BEFORE MODEL',item)

        projectActions.setRefresh(!projectActions.refresh)
    },[])

    useEffect(() => {
        const layer = projectActions.scenes[1].layers[0]
        
        if(layer.groups && groupID){            
            const foundGroup = layer.groups.find(group => group.id === groupID);
            if(foundGroup){
                setGroup(() => foundGroup)
                if(foundGroup.items.length > 0){
                    const tempItem = getElementOfId(layer,null,foundGroup.items[0])
                    setItem(() => tempItem)
                }
                // console.log('---AFTER',foundGroup,oldValue)
            }
        }

        if(parseInt(option.id_element) === 2){
            setItem(layer.areas[0].floor)
        } else if(itemID){
            setItem(getElementOfId(layer,null,itemID))
        }

        if(item){ 
            setOldValue(() => getFeatureValue(item,propertyData?.feature))
        }

        
        // console.log('REFRESHING MODEL',groupID,item,oldValue,propertyData?.feature)
    },[sceneActions.selected,projectActions.refresh,elementData])


    const handleAction = (value) => {  
        console.log('ModelAction',value,propertyData?.feature)

        const act = [{a:'set',e:elementIdToName(option.id_element),f:parseInt(propertyData?.feature),v:parseInt(value)}]

        action(act)
        setOldValue(value)
    }

    let count = 0;

    return <div>
        {/* {item?.id} : {propertyData?.values.length} - GS:{projectActions.project.defaults.style} LS:{localStyle}:{localSubstyle}_M:{material}_V:{oldValue} eID:{option.id_element} v: {qnrActions.activeVariant}  {itemID} */}
        {propertyData && <div className="thumbs">
            {propertyData && propertyData.values.filter((value,i) => {
                let testFilters = true
                if(value.filter && item !== null){
                    testFilters = checkFilterItem(item,value.filter)
                }
                {/* console.log('IN MODEL',item,value.filter,testFilters) */}
                return testFilters
            }).map((val,i) => {
                return <Thumb key={i} value={val} oldValue={oldValue} action={() => handleAction(val.value,option.id_element)} shape={'circle'}/>
            })}
        </div>}
    </div>
}

export default ModelSelector