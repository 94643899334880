import { useEffect, useContext, useState } from 'react';
import ProjectContext from '../../context/ProjectContext'
import SceneContext from '../../context/SceneContext'
import QnrContext from '../../context/QnrContext'
import { getStyle } from '../../utils/sceneUtils';

const StyleImg = ({styles,scope}) => {

  const qnrActions = useContext(QnrContext)
  const projectActions = useContext(ProjectContext)

  return (styles && <div className='styleImg'
    style={{
      background:`url(${process.env.PUBLIC_URL}'/icons/preloader.gif')`,
      backgroundSize:'cover',
      backgroundPosition:'center', 
      backgroundRepeat:'no-repeat'                
    }}>
    <div style={{
        display:'flex',
        flexDirection:'column',
        justifyContent:'flex-end',
        height:'100%',
        backgroundImage:`url(${process.env.PUBLIC_URL + styles[projectActions.project.defaults?.style ?? 0].substyles[projectActions.project.defaults?.substyle ?? 0].url})`,
        backgroundSize:'cover',
        backgroundPosition:'center',  
        color:'white',
        borderRadius:'1em'
    }}>
      <div className='summary-title-style-card' style={{marginLeft:'20px'}}>
          <div>Estilo seleccionado</div>
          {/* <div>{styles[imageScope].styles[active ?? 0].substyles[activeSubstyle ?? 0].url}</div> */}
          {/* <img src={process.env.PUBLIC_URL + '/' + styles[imageScope].styles[active].substyles[activeSubstyle].url}/> */}
          <div className="c-title" style={{color:'white'}} dangerouslySetInnerHTML={{__html:styles[projectActions.project.defaults?.style ?? 0].label}}></div>
      </div>
    </div>
  </div>
  )
}

export default StyleImg 