import { createContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { database, firestore as db, storage } from '../firebase/config'
import { message } from 'antd';
import { cleanResults, cleanScenes } from '../utils/calcUtils'
import { convertScene } from '../utils/formatUtils';

const ProjectContext = createContext();

let key = 'message-popup'

export const ProjectProvider = ({ children }) => {

    let params = useParams();

    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [messages, setMessages] = useState(null);
    const [popups, setPopups] = useState(null);
    const [mode, setMode] = useState(null);
    const [calcData, setCalcData] = useState(null);
    const [results, setResults ] = useState([]);
    const [scenes, setScenes ] = useState([]);
    const [elementsData, setElementsData] = useState(null)
    const [sceneData, setSceneData] = useState(null)
    const [zipChange, setZipChange] = useState(false)
    const [popup, setPopup] = useState(null)
    const [modal, setModal] = useState(null)
    const [modalData, setModalData] = useState(null)
    const [modalParams, setModalParams] = useState(null)
    const [refresh, setRefresh] = useState(false)
    const [roomType, setRoomType] = useState(null)
    const [styles, setStyles] = useState([])

    const [project, setProject] = useState({
        initialTable:null,
        integratedTable:null,
        results: results,
        defaults: null,
        scope:null
    })

    const setDefaults = (newDefaults) => {
        console.log('DEF',newDefaults)
        const tempProject = project;
        tempProject.defaults = newDefaults;
        setProject(tempProject)
    }

    const setDefault = (name, value, dataType = null) => {
        switch (dataType) {
            case 'int':
                value = parseInt(value)
            break;
            case 'float':
                value = parseFloat(value)
            break;
            case 'height':
                value = parseFloat(value / 100).toFixed(2)
            break;
            default:
        }     

        // console.log('====SET DEFAULT:',value)

        const tempProject = project;
        const tempDefaults = tempProject.defaults;
        tempProject.defaults = {...tempDefaults,[name]:value};  

        
        setProject(() => tempProject)

        // console.log(name,value,project.defaults[name])
    }

    const updateResult = newResults => {
        saveProject(null, project.projectName, newResults)
        setResults(newResults)
    }

    const deleteResult = (id) => {
        const tempResults = results.filter((result,i) => i !== id)
        setResults(tempResults)

        saveProject(null,'',tempResults)
    }

    const createProject = (projectName = '') => {
        console.log('CREATE',projectName);
    }

    const loadProject = (params) => {
        setLoaded(false)
        db.collection("Projects").doc(params.projectID).onSnapshot(res => {
            if(res.exists){
                const projectData = res.data()
                
                if(!projectData.version){
                    console.log('CONVERTING TO NEW PROJECT')
                    projectData.scenes.forEach(scene => convertScene(scene))
                }

                console.log('--------------LOADED:',projectData.scenes)

                setDefaults(projectData.defaults)
                setResults(() => projectData.results)
                setScenes(() => projectData.scenes)
                setProject(() => ({
                    ...project,
                    projectId:params.projectID,
                    access:projectData.access,
                    scope:projectData.scope,
                    zipCode:projectData.scope,
                    name:projectData.name,
                    images:projectData.images,
                    active:projectData.active,
                    created_At:projectData.created_At,
                    userId:projectData.userId
                }))
                setLoaded(() => true)


                return {...ProjectContext.project,projectId:params.projectID}
            } else{
                setResults([]);
                setLoaded(() => false);
                setError(0)
                message.error({content:'no project with id :' + params.projectID + ' found'})
            }
        })
    }

    const updateProjectProperty = (projectID = null,property,value,form) => {

        try{
            db.collection("Projects")
                .doc(projectID !== null ? projectID : project.projectId)
                .update({
                    [property]:value
                })
                .then(() => {
                    // message.info({content:'UPDATE SUCCESS', key})
                    setPopup(null)
                    setRefresh(!refresh);
                })
        } catch (err) {
                    console.error('ERR',err)
                    message.error({content:'UPDATE ERROR'})
        }            

        message.info({content:'UPDATING', key})
    }

    const saveProject = (projectID = null,projectName = '',tempResults = null) => {

        console.log('SAVE',scenes,tempResults,results,project)      

        if(scenes[1]){
            try{
                db.collection("Projects")
                    .doc(projectID ? projectID : project.projectId)
                    .update({
                        name: projectName !== '' ? projectName : 'Updated Project',
                        version:2,
                        results: cleanResults(tempResults !== null ? tempResults : results),
                        scenes: cleanScenes(scenes),
                        scope:project.scope,
                        defaults:project.defaults,
                        access:project.access ? project.access : 'pending',
                        active:project.active ? project.active : true
                    })
                    .then(() => {
                        message.info({content:'¡Guardado!', key})
                        setPopup(null)
                    })
                    .catch((err) => {
                        message.error({content:'Save error', key})
                        console.log('ERROR',err);
                    })
    
            } catch (err) {
                console.error('ERR',err)
                message.error({content:'SAVE ERROR'})
            }
        } else {
            message.error({content:'MISSING TABLE'})
        }

        // message.info({content:'Guardando', key})
    }

    const updateProject = (projectID,form) => {
        console.log('UPDATING ',projectID,form.getFieldsValue(),'---',scenes)
        // if(scenes[1]){
            try{
                db.collection("Projects")
                    .doc(projectID ? projectID : project.projectId)
                    .update(form.getFieldsValue())
                    .then(() => {
                        message.info({content:'¡Guardado!', key})
                        setPopup(null)
                        setRefresh(!refresh)
                    })
            } catch (err) {
                        console.error('ERR',err)
                        message.error({content:'SAVE ERROR'})
            }
        //  else {
        //     message.error({content:'MISSING TABLE'})
        // }
        // 
    }

    const deleteProject = (projectID = null, area, project = null) => {
        console.log('DEL',projectID,area,project)
        if(projectID !== null){
            if(!area || area === 'General'){
                db.collection("Projects")
                   .doc(projectID)
                   .delete()
                   .then(() => {
                       message.info({content:'DELETE PROJECT OK'})
                       setRefresh(!refresh);
                   })
                   .catch(err => {
                       console.log(err)
                       message.error({content:'DELETE PROJECT ERROR'})
                   })           
            } else if (area && project){
                console.log('DELETE SUBPROJECT',projectID, area, project.scenes)
                project.scenes = project.scenes.map((scene,i) => {
                    if(i > 0){
                        let tempLayer = scene.layers[0]
                        tempLayer.areas = tempLayer.areas.map(a => {
                            console.log('==== WCHODZI',area,a.id,a.features)
                            if(a.id === area.id){
                                a.features[16] = 0
                            }
                            return a
                        })
                        scene.layers[0] = tempLayer
                    }
                    return scene
                })

                replaceProject(projectID,project)
                setRefresh(!refresh);
            }
        }
    }

    const replaceProject = async (projectID,project) => {
        const replaced = await db.collection("Projects")
            .doc(projectID)
            .update(project)
            .then(() => {
                message.info({content:'DELETE SUBPROJECT'})
                setRefresh(!refresh)
            })
            .catch((err) => {
                message.error({content:'DELETE SUBPROJECT ERROR'})
            })
    }

    const addResult = (newResult,description = null) => {

        return new Promise((resolve, reject) => {
            let tempResult = newResult

            if(description !== null){
                tempResult = {...tempResult,description:description}
            }

            const newDate = new Date();

            tempResult = {...tempResult,date:newDate.toISOString()}

            const newResults = [...results,tempResult]

            console.log('IN ADD',newResults)
            
            setResults([...newResults])
            
            saveProject(null,'',newResults)
        })
    }

    const uploadResultImage = async(image, i) => {
        let timestamp = new Date().getTime();
        let ref = storage.ref().child(`Results/${project.projectId}/${timestamp}-${image.name}`)
        message.loading({ content:'UPLOADING', key })
        let response = await ref.put(image)
        if(response.state == 'success'){
            message.loading({ content:'SAVING IMAGE', key })
            let downloadURL = await response.ref.getDownloadURL()
            if(downloadURL){
                message.success({ content:'SAVING SUCCESSFUL', key })
                let newResults = results
                if(newResults[i].resultImages && Array.isArray(newResults[i].resultImages)){
                    newResults[i].resultImages.push({url: downloadURL, name: `${timestamp}-${image.name}`})
                }
                else {
                    newResults[i].resultImages = [{url: downloadURL, name: `${timestamp}-${image.name}`}]
                }
                saveProject(null,'',newResults)
                return {
                    name: image.name,
                    url: downloadURL,
                    status: 'success',
                }
            }
            else {
                message.error({ content:'UPLOAD ERROR', key })
                console.log("error no downloadURL")
                return {
                    name: image.name,
                    url: downloadURL,
                    status: 'error',
                }
            }
        }
        
        return {
            name: image.name,
            url: null,
            status: 'error',
        }
    }

    const removeImageFromStorage = (file, resultID) => {
        console.log('REMOVE',file)
        let fileref = storage.refFromURL(file.url)
        console.log("removeImageFromStorage", file, fileref)
        fileref.delete()
        .then(() => {
            let newResults = [...results]
            newResults[resultID].resultImages = results[resultID].resultImages.filter(resultImage => {
                console.log(resultImage.name, file.name, resultImage.name != file.name)
                return resultImage.name != file.name
            })
            console.log('DELETE FROM',results[resultID], newResults[resultID])
            saveProject(null,'',newResults)
            message.info({ content:'DELETED SUCCESSFULLY!', key})
        })
        .catch(err => {
            console.log(err)
            message.error({ content:'DELETING ERROR!', key})
        })
      }
    // const getPaginatedProjects = async (action = '', point = null, numberOfEntries = 20, orderBy = 'created_At', order = 'desc') => {
    //     var data = db.collection("Projects")
    //     if(action === 'next'){
    //         data = db.collection("Projects").orderBy(orderBy, order).startAfter(point).limit(numberOfEntries)
    //     } else{
    //         data = db.collection("Projects").orderBy(orderBy, order).limit(numberOfEntries)
    //     }
    //     let response = await data.get()
    //     var lastVisible;
    //     if(response.docs.length < numberOfEntries){
    //         lastVisible = 'end';
    //     }
    //     else{
    //         lastVisible = response.docs[response.docs.length-1];
    //     }

    //     let projs = []
    //     response.forEach(proj => {
    //       projs.push({...proj.data(), projectId:proj.id})
    //     });
    //     return {
    //         response: projs,
    //         next: lastVisible
    //     }
    // }

    const testData = () => {
        return new Promise((resolve) => {
            setTimeout(() => {
                console.log('1. get user data');
                resolve({ name: 'John' })
            },600)
        })
    }

    return (
        <ProjectContext.Provider value={{
            refresh, setRefresh,
            sceneData, setSceneData,
            styles, setStyles,
            elementsData, setElementsData,
            roomType, setRoomType,
            loaded,setLoaded,
            testData,
            deleteProject,updateProject,updateProjectProperty,
            messages, setMessages,
            popups, setPopups,
            popup, setPopup,
            modal, setModal, modalData, setModalData, modalParams, setModalParams,
            calcData,setCalcData,
            mode,setMode,
            // getPaginatedProjects,
            project, setProject, createProject, loadProject, saveProject, 
            addResult, updateResult, deleteResult, 
            uploadResultImage, removeImageFromStorage,
            scenes,setScenes,
            setDefault, setDefaults,
            results, setResults,
            zipChange, setZipChange,
        }}>{children}</ProjectContext.Provider>
    )
}

export default ProjectContext;