import { createContext, useState, useContext } from 'react';
import { elementNameToId, elementIdToName } from '../utils/formatUtils'
import { checkIfInArea, convertToNew, createElement, createGroup, detachArea, getAreaLines, getAreaOfId, getAreaOfLine, getAreaVertices, getAreaVerticesID, getElementData, getElementOfId, getLineOfId, getNewFromCalcData, refreshLayer, refreshVertices, removeAllFromArea, setElementProperty } from '../utils/sceneUtils'
import { getOldFromCalcData, addElementToLayer, removeElementFromLayer, checkFilterItem, } from '../utils/sceneUtils'
import { angleBetweenTwoPoints, extendLine, pointsScale, pointsShift, verticesDistance } from '../utils/geometry'
import areapolygon from 'area-polygon';

import ProjectContext from './ProjectContext'
import QnrContext from './QnrContext'
import CalcContext from './CalcContext'

const SceneContext = createContext();

export const SceneProvider = ({ children }) => {

    const { calcData, project, setDefault, saveProject ,setScenes } = useContext(ProjectContext)

    const projectActions = useContext(ProjectContext)
    const qnrActions = useContext(QnrContext)
    const calcActions = useContext(CalcContext)

    const [scene, setScene] = useState(null)
    const [mScene, setMScene] = useState(null)
    const [layer, setLayer] = useState(null)
    const [elements, setElements] = useState(null)
    const [refresh, setRefresh] = useState(false)
    const [viewMode, setViewMode] = useState(false)
    const [tool, setTool] = useState('idle')

    const [selected, setSelected] = useState(null)
    const [activeRoom, setActiveRoom] = useState(null)
    const [active, setActive] = useState(null)
    const [over, setOver] = useState(null)
    const [currentArea, setCurrentArea] = useState(null)
    const [target,setTarget] = useState(null)

    // useEffect(() => {
    //   if(projectActions.sceneData !== null && projectActions.project.defaults){
    //     projectActions.setScenes([projectActions.sceneData[projectActions.project.defaults.houseShape].scene])
    //   }      

    // },[projectActions.sceneData])

    const saveScenes = (projectID,projectName) => {

      setScenes(projectActions.scenes)
      saveProject(projectID,projectName)
    }

    const setCurrentScene = (id,option = null) => {  

      console.log('IN SCENES',projectActions.sceneData)

      projectActions.setScenes([projectActions.sceneData[id].scene])
    }

    const replaceLines = (layer) => {
          
      const tempScenes = projectActions.scenes

      tempScenes.map((scene,i) => {
        const tempLayer = JSON.parse(JSON.stringify(layer));
        const oldLayer = scene.layers[0];

        const currentLines = scene.layers[0].lines;

        tempLayer.lines.map((line,j) => {

          // console.log('LINE',line,i)

          if(currentLines[j]){
            line.features = i === 0 ? currentLines[j].features.slice(0,7) : currentLines[j].features;
          } else if (currentLines[0]){
            line.features = i === 0 ? currentLines[0].features.slice(0,7) : currentLines[0].features;
          } else {
            if(i !== 0){
              const zeros = new Array(8).fill(0)
              line.features = line.features.concat(zeros);
            }         
          }

          if(i !== 0){
              line.features[16] = 1;
          }

          return line;
        })

        tempLayer.areas = oldLayer.areas;
        tempLayer.items = oldLayer.items;
        tempLayer.holes = oldLayer.holes;

        scene.layers[0].vertices = layer.vertices;

        return scene.layers[0] = tempLayer;
      })

      projectActions.setScenes([...tempScenes])
    }

    const replaceVertices = (vertices) => {
      const tempScenes = projectActions.scenes
      
      tempScenes.map((scene,i) => {
        const tempLayer = tempScenes[i].layers[0];

        tempLayer.vertices = vertices;

        tempLayer.lines.map(line => {
            
            const length = verticesDistance(vertices[line.v1],vertices[line.v2])
            const height = parseFloat(project.defaults.height)
            line.measurements = {length:length,height:height,area:parseFloat(length * height).toFixed(2)}

          return line
        })

        tempLayer.areas.map(area => {
          const polygon = vertices.map(v => [v.x,v.y])

          let areaSize = areapolygon(polygon, false);

          area.floor.measurements = {area:parseFloat(areaSize).toFixed(2)}
          area.ceiling.measurements = {area:parseFloat(areaSize).toFixed(2)}

          return area
        })

      
        return scene.layers[0] = tempLayer
      })

      projectActions.setScenes([...tempScenes])  
    }

    const setSceneParameter = (parameter,value) => {

      const tempScenes = projectActions.scenes

      switch(parameter){
        case 'height':
            tempScenes.map(scene => {
              const tempLayer = scene.layers[0]
              console.log(tempLayer)
              tempLayer.lines.map(line => {
                line.measurements[parameter] = (value/100).toFixed(2)
              })
            })
            projectActions.setScenes([...tempScenes])  
        break;
        default:
      }
    }

    const addVertex = (v) => {
      projectActions.setScenes(scenes => {
        scenes.map((scene,i) => {
          let tempLayer = scene.layers[0]
          if(Array.isArray(v)){
            tempLayer.vertices = tempLayer.vertices.concat(v)
          } else {
            tempLayer.vertices.push(v)
          }
          return scene
        })
        return scenes
      })
    }

    const addLine = (line) => {
      projectActions.setScenes(scenes => {

        scenes.map((scene,i) => {
          const tempLayer = JSON.parse(JSON.stringify(scene.layers[0]));
          const tempLine = JSON.parse(JSON.stringify(line))

          if(i > 0){
            const zeros = new Array(16).fill(0)
            tempLine.features = [...line.features,...zeros]
          }

          tempLayer.lines.push(tempLine)
          scene.layers[0] = tempLayer
          
          return scene
        })
        return scenes
      })
    }

    const addRoom = (areaID, area, features) => {
      projectActions.setScenes(scenes => {
        scenes.map((scene,i) => {
          const tempLayer = JSON.parse(JSON.stringify(scene.layers[0]));
          const tempArea = JSON.parse(JSON.stringify(area))

          if(i > 0){
            const zeros = new Array(24).fill(0)
            tempArea.features = [10]
            tempArea.floor.features = [10,null,null,37,47,null,50,53,null,null,37,47,null,50,0,0,0,0,0,0,0,0,0,0]
            tempArea.ceiling.features = [10,55,57,60,66,null,67,70,55,57,60,66,null,67,0,0,0,0,0,0,0,0,0,0]
          } else {
            const zeros = new Array(8).fill(0)
            tempArea.features = [10]
            tempArea.floor.features = [10,null,null,35,47,null,50,null]
            tempArea.ceiling.features = [10,55,57,60,66,null,67,null]
          }
          tempLayer.areas.push(tempArea)

          // const refreshedLayer = refreshLayer(tempLayer)
          
          const refreshedLayer = refreshVertices(tempLayer,projectActions.defaults)

          console.log('REF',tempArea,refreshedLayer)

          scene.layers[0] = refreshedLayer
          // setNewVertices(refreshedLayer.vertices)
          return scene
        })
        return scenes
      })


      setRefresh(!refresh)
    }

    const removeRoom = (areaID) => {
      projectActions.setScenes(scenes => {
        scenes.map((scene,i) => {
          let tempLayer = scene.layers[0];  
          
          const foundArea = tempLayer.areas.find(a => a.id === areaID)

          if(foundArea){
            tempLayer.items.filter(item => {
              const testItems = foundArea.items.includes(item.id)
              const testFloor = foundArea.floor.items.includes(item.id)
              const testCeiling = foundArea.ceiling.items.includes(item.id)

              return !testItems && !testFloor && !testCeiling 
            })

            tempLayer.lines = tempLayer.lines.filter(line => {
              const testOthers = tempLayer.areas.some(a => {
                return a.id !== areaID && a.lines.includes(line.id)
              })

              return !foundArea.lines.includes(line.id) && testOthers === true
            })
          }

          tempLayer.areas = tempLayer.areas.filter((a,i) => {
            return a.id !== areaID
          })


          console.log('BEFORE REMOVE',areaID,tempLayer.vertices)        
          
          scene.layers[0] = refreshLayer(tempLayer)
          
          console.log('AFTER REMOVE',areaID,scene.layers[0].vertices)

          return scene
        })
        return scenes
      })

      setRefresh(!refresh)
    }

    const setNewVertices = (vertices) => {
      const tempScenes = projectActions.scenes

      tempScenes.map((scene,i) => {
      
        const tempLayer = tempScenes[i].layers[0];

        tempLayer.vertices = vertices;

        tempLayer.lines.map(line => {
          if(line.type === 'wall'){
            const length = verticesDistance(vertices[line.v1],vertices[line.v2])
            if(length === 0){
              console.log('LINE',line.v1,line.v2,line.id,vertices[line.v1],vertices[line.v2])
            }
            const height = parseFloat(project.defaults.height)
            line.measurements = {length:length,height:height,area:parseFloat(length * height).toFixed(2)}
          }
          return line
        })

        tempLayer.areas.map(area => {
          
          // const areaLines = getAreaLines(tempLayer,area.id);

          const areaVertices = getAreaVertices(tempLayer,area.id)

          // console.log(areaVertices)
                    
          const polygon = areaVertices.map(v => [v.x,v.y])
          // const polygon = areaVertices
          
          let areaSize = areapolygon(polygon, false);

          // let areaSize = calculateArea([[10,3],[11.5,3],[11.5,6],[10,6]].reverse())
          
          area.floor.measurements = {area:parseFloat(areaSize).toFixed(2)}
          area.ceiling.measurements = {area:parseFloat(areaSize).toFixed(2)}
          area.measurements = {area:parseFloat(areaSize).toFixed(2)}

          return area
        })

        return scene.layers[0] = tempLayer
      })

      projectActions.setScenes([...tempScenes])  
    }

    const setAreaScale = (table, layer = 0, areaID, newAreaValue) => {

      let oldLayer = projectActions.scenes[0].layers[layer]    
      
      const oldArea = getAreaOfId(oldLayer, areaID)

      console.log('BEFORE SCALE',oldArea.measurements, newAreaValue,oldLayer.vertices,oldLayer.lines.map(l => [l.id,l.v1,l.v2]))
      
      if(oldArea && newAreaValue > 1){          
        const oldAreaValue = parseFloat(oldArea.floor.measurements.area)
        const ratio = newAreaValue / oldAreaValue ?? 1    
                
        projectActions.scenes.map(scene => {

          const oldLayer = scene.layers[layer]

          let tempLayer = detachArea(oldLayer,areaID)

          const areaVerticesID = getAreaVerticesID(tempLayer, areaID)

          const areaVertices = getAreaVertices(JSON.parse(JSON.stringify(tempLayer)), areaID)

          const scaledPoints = pointsScale(areaVertices,ratio)
          
          areaVerticesID.forEach((av,j) => {
            tempLayer.vertices[av] = scaledPoints[j]
          })
          
          console.log('SCALED POINTS',ratio,scaledPoints,areaVerticesID,tempLayer.vertices,tempLayer.lines.map(l => [l.id,l.v1,l.v2]),tempLayer.areas)

          scene.layers[layer] = refreshLayer(tempLayer)

          return scene
        })

      }

    }
    
    const moveArea = (areaID, vectors, startPos, newPos) => {
      let oldLayer = projectActions.scenes[0].layers[0]

      const oldArea = getAreaOfId(oldLayer, areaID)

      if(oldArea){
        projectActions.scenes.map(scene => {
        const oldLayer = scene.layers[0]

        let tempLayer = detachArea(oldLayer,areaID)

        const areaVerticesID = getAreaVerticesID(tempLayer, areaID)

        const areaVertices = getAreaVertices(JSON.parse(JSON.stringify(tempLayer)), areaID)

        const movedPoints = pointsShift(areaVertices,vectors,startPos,newPos)

          areaVerticesID.forEach((av,j) => {
            tempLayer.vertices[av] = movedPoints[j]
          })

          return scene
        })
      }

      setRefresh(!refresh)
    }

    const moveElement = (sceneID = null,itemID,vector,startPos,newPos) => {
     
      projectActions.setScenes(scenes => {
        scenes.map((scene,i) => {
          const tempLayer = scene.layers[0];
          const foundItem = tempLayer.items.find(it => it.id === itemID)
          
          if(foundItem && vector.x){ 
            console.log('changing position',itemID,vector.x)
            foundItem.position = {x:newPos.x - vector.x,y:newPos.y - vector.y}
          }

          scene.layers[0] = tempLayer

          return scene
        })
        return scenes
      }) 

      // setRefresh(!refresh)
    }

    const delSelected = () => {

      console.log('DEL', selected)

      // const foundSelected = getElementOfId(selected)

      // if(foundElement){
        removeElement('all',null,null,null,selected)
      // }

      // setRefresh(!refresh)
    }

    const addElement = (table='integratedTable',areaID,element,target) => {

        const sceneId = table === 'integratedTable' ? 1 : 0;

        projectActions.setScenes(scenes => {
          if(sceneId === 1){
            // element.features[7] = getNewFromCalcData(calcData,element.id_element)
            element.features[15] = 2
          }

          const tempLayer = scenes[sceneId].layers[0];
          scenes[sceneId].layers[0] = addElementToLayer(tempLayer, areaID ?? currentArea, target, element)

          return scenes
        })

        setRefresh(!refresh)
    }

    const shiftActive = (active,start,end) => {
      console.log(active,start,end)

      // const activeLine = layer.lines[active.split('_')[1]]
      // const verts = 

      console.log(layer)
    }

    const addElementToAll = (table,areaID,element,target) => {
      console.log('IN ADD ALL',areaID, element, projectActions.project)

      projectActions.setScenes(scenes => {

        scenes.map((scene,i) => {
          let tempLayer = scene.layers[0]
          if(i > 0){
            const zeros = new Array(16).fill(0)
            const tempElement = {...element};
            
            tempElement.features = [...tempElement.features,...zeros]
            tempElement.features[7] = getOldFromCalcData(calcData,tempElement.id_element)
            // console.log('in add all',tempElement.features)
            tempElement.features[15] = 2

            tempLayer = addElementToLayer(tempLayer, areaID ?? currentArea, target ,tempElement)
          } else {
            element.features[7] = null;
            tempLayer = addElementToLayer(tempLayer, areaID ?? currentArea, target ,element)
          }
          scene.layers[0] = tempLayer
          
          // console.log(i,tempLayer.lines.map(l => l.items))

          return scene
        })

        return scenes
      })

      setRefresh(!refresh)
    }

    const removeElement = (table = 'integratedTable',areaID,id_element = null, typology = null, itemID = null) => {//todo - add filter to remove multiple elements

      console.log('remove',projectActions.scenes)

      projectActions.setScenes(scenes => {
        const tempScenes = scenes
        const sceneId = table === 'integratedTable' ? 1 : 0;
        if(table === 'all'){
          scenes.map((scene,i) => {
            const tempLayer = scene.layers[0];
            const foundElement = getElementOfId(tempLayer,null,itemID)

            console.log('trying to remove',itemID,foundElement,tempLayer.items)

            if(foundElement){
              id_element = foundElement.id_element
              if(id_element !== 19){
                scene.layers[0] = removeElementFromLayer(tempLayer,areaID,id_element,itemID)
              } else {
                removeRoom(foundElement.id)
              }
            } else {

            }
          })
        } else {

          console.log('---REMOVE ALL IN SCENE',scenes,itemID,areaID,id_element)

          const tempLayer = scenes[sceneId].layers[0]
          const foundElement = getElementOfId(tempLayer,null,itemID)
          if(foundElement){
            id_element = foundElement.id_element
            if(id_element !== 19){
              scenes[sceneId].layers[0] = removeElementFromLayer(tempLayer,areaID,id_element,itemID)
            } else {
              removeRoom(foundElement.id)
            }
          } else {

          }
        }
        
        return scenes
      })

      setRefresh(!refresh)
    }

    const attachElement = (table = 'all',layer,targetType,target,item) => {

      const targetElement = getElementOfId(layer,null,target)

      console.log('adding to ',layer,target)

      if(targetElement){
        projectActions.setScenes(scenes => {
          scenes.map((scene,i) => {
              const sceneId = table === 'integratedTable' ? 1 : 0;

              const tempLayer = scene.layers[0]

              switch(item.id_element){
                case 4:
                case 5:
                case 6:
                  tempLayer.holes.push(item)
                  tempLayer.lines.map(l => {
                    if(l.id === targetElement.id){
                      l.holes.push(item.id)
                    }
                  })
                break;
                default:
                  tempLayer.items.push(item)
                  switch(targetType){
                    case 'wall':
                    tempLayer.lines.map(l => {
                      if(l.id === targetElement.id){
                        l.items.push(item.id)
                      }
                    })
                    break;
                    case 'ceiling':
                    case 'floor':
                      tempLayer.areas.map(a => {
                        if(a.id === target){
                          setFeature(i === 1 ? 'mScene' : 'scene',0,a.features[0],null,[],item.id)
                          if(targetType === 'floor'){
                            a.floor.items.push(item.id)
                          } else if (targetType === 'ceiling'){
                            a.ceiling.items.push(item.id)
                          } else {
                            a.items.push(item.id)
                          }
                        }
                     })
                    break;
                  }                 
              }

              console.log(tempLayer.holes,tempLayer.items)

              scene.layers[0] = tempLayer
              return scene
              })
            return scenes
          })
      }

      // setRefresh(!refresh)
    }

    const detachElement = (table = 'all',layer,targetType,target,item) => {
      
    }

    const toggleBetweenGroups = (table = 'integratedTable',itemId,groupId,groupId1,groupId2) => {
        const sceneId = table === 'integratedTable' ? 1 : 0;  
        
        console.log('GROUP',itemId,groupId,groupId1,groupId2)

        projectActions.setScenes(scenes => {
          const tempLayer = scenes[sceneId].layers[0];

          tempLayer.groups = tempLayer.groups ?? [createGroup(groupId1,[],groupId1),createGroup(groupId2,[],groupId2)]


          
          tempLayer.groups = tempLayer.groups.map(group => {
            group.items = group.items.filter(item => item !== itemId);
            // if(group.items.includes(itemId)){
              // group.items = group.items.filter(item => item.id !== itemId);
            // } else {
              if(group.id === groupId){
                  group.items.push(itemId)
              }
            // }

            return group
          })

          // tempLayer.groups.forEach(gr => gr.id === groupId && gr.items.push(itemId))

          scenes[sceneId].layers[0] = tempLayer

          // console.log('IN TOGGLE GROUPS',tempLayer.groups.map(gr => gr.items),itemId,groupId1,groupId2)

          return scenes
        })
    }

    const moveToGroup = (table,itemId=null,groupId=null,groupName=null) => {
      console.log('MoveToGroup',itemId,groupId,groupName)

      if(itemId){

        projectActions.setScenes(scenes => {
          scenes.map((scene,i) => {

          const tempLayer = scenes[i].layers[0];
          let tempGroups = tempLayer.groups

          if(!tempGroups){
            tempGroups = []
          }

          tempGroups.forEach(group => {
            group.items = group.items.filter(item => item !== itemId)
          })

          let foundGroup = tempLayer.groups.find(gr => gr.id === groupId);

          if(!foundGroup){
            console.log('group not found, create one')
            const newGroup = createGroup(groupId,[],groupName)
            tempGroups.push(newGroup)
            foundGroup = tempGroups.find(gr => gr.id === groupId);
          }

          if(foundGroup){
            const tempItems = new Set(foundGroup.items)
            tempItems.add(itemId)
                    
            foundGroup.items = [...tempItems]

            tempLayer.groups = tempGroups
            }
            scenes[i].layers[0] = tempLayer

            console.log('===IN GROUPS',tempLayer.groups.map(g => g.items))

            return scene
          })
          return scenes
        })

        console.log('inGroups',)

        setRefresh(!refresh)
      }
    }

    const removeFromGroup = (itemId,group) => {
      console.log('removeFromGroup',itemId,group)
    }

    const resetGroups = (group=null) => {
      console.log('resetGroups',group)
    }

    const setGroupFeature = (scene=null, column, value, groupId, id_element) => {
      const sceneId = scene === null ? 1 : scene === 'scene' ? 0 : 1;

      const layer = projectActions.scenes[0].layers[0]

      const foundGroup = layer.groups ? layer.groups.find(gr => gr.id === groupId) : null;
      
      if(foundGroup){
        console.log('IN GROUP CHANGE ',sceneId,projectActions.scenes[0].layers[0].groups.map(g => g.items))
        
        foundGroup.items.forEach(itemId => {
          const item = getElementOfId(layer,null,itemId)
          if(item){
            setFeature('mScene',column,parseInt(value),parseInt(id_element),[],itemId)
          }
        })
      }

      setRefresh(!refresh)
    }

    const setProperty = async (scene='all', property, value, id_element, itemID, areaID, filters=[]) => {

      console.log('SET ',scene, property)

        projectActions.setScenes(scenes => {
          const tempScenes = scenes

          if(scene === 'all'){
            scenes.map((scene,i) => {
              const tempLayer = scene.layers[0];
              
              let foundArea = tempLayer.areas.find(a => a.id === areaID)
              if(foundArea){
                tempLayer.areas = tempLayer.areas.map(a => a.id === areaID ? setElementProperty(a,property,value) : a)
              } else {
                const foundItem = getElementOfId(tempLayer,null,itemID)
                if(foundItem){
                  switch (foundItem.id_element){
                    case 19:
                      tempLayer.areas = tempLayer.areas.map(a => a.id === itemID ? setElementProperty(a,property,value) : a)
                    break;
                    default:
                      console.log(foundItem)
                  }
                }
              }
              
              scene.layers[0] = tempLayer
            })
          } else {
            const sceneId = scene === 'all' ? 1 : 0;          
            const tempLayer = scenes[sceneId].layers[0]

            scenes[sceneId].layers[0] = tempLayer
          }
          return scenes
        })

    }

    const setFeature = (scene=null, feature, value, id_element, filters=[], itemID=null, areaID=null, groupID=null) => {       
      
      console.log('SET FEATURE',scene, 'AID:',areaID,'id_element:',id_element, 'itemID:',itemID,'feature:',feature,'value:',value, filters, groupID)

      const aFeature = feature.toString().split(',')
      const aValue = value.toString().split(',')
      
      const elId = isNaN(id_element) ? elementNameToId(id_element) : id_element;
        let tempItems = null; 
        const sceneId = scene === null ? 1 : scene === 'scene' ? 0 : 1;
        projectActions.setScenes(scenes => {
          const tempScene = scenes[sceneId];

          // console.log('IN SET FEATURE',feature, value,'SCENE:',sceneId,id_element,elId,'-',itemID,'AID:',areaID)

          switch (parseInt(elId)){
            case 1:
                tempScene.layers[0].lines
                  .filter(el => {
                    let tempLayer = tempScene.layers[0]
                    let testArea = true
                    let testOthers = true

                    if(areaID){
                      if(areaID === 'others'){
                        const tempArea = getAreaOfLine(tempLayer,el.id)

                        // console.log('FOUND AREA:',tempArea)

                        testOthers = tempArea ? (tempArea.features[0] !== 8 && tempArea.features[0] !== 6) : false
                      } else if (areaID === 'wetrooms') {
                        const tempArea = getAreaOfLine(tempLayer,el.id)
                        testOthers = tempArea ? (tempArea.features[0] === 8 && tempArea.features[0] === 6) : false
                      }
                      const areaLines = getAreaLines(tempLayer,areaID)
                      testArea = areaLines.find(l => el.id === l.id)
                    }

                    if(selected !== null){
                      const areaLines = getAreaLines(tempLayer,selected)
                       if(areaLines){
                        testArea = areaLines.find(l => el.id === l.id)
                      }

                      if(itemID === el.id){
                        testArea = true
                      }
                    }
                    return testArea !== undefined && testOthers === true
                  }).map(line => {
                  if(itemID !== null){
                    aFeature.forEach((feat,i) => {
                      if(line.id === itemID){
                        line.features[parseInt(feat)] = parseInt(aValue[i])
                      }                      
                    })
                  } else {
                    aFeature.forEach((feat,i) => {
                      line.features[parseInt(feat)] = parseInt(aValue[i])
                    })
                  }
                  return line
                })
            break;
            case 2:
              aFeature.forEach((feat,i) => {
                tempScene.layers[0].areas
                .filter(el => {
                  let testArea = true
                  if(areaID){
                    if(areaID !== 'others'){
                      testArea = el.id === areaID
                    } else if(areaID === 'others'){
                      testArea = el.features[0] !== 8 && el.features[0] !== 6;
                    } else {
                      testArea = false;
                    }
                  }
                  if (selected !== null && selected !== el.id) {
                    testArea = false;
                  }
                  // console.log('CHECKING FLOOR ',areaID,el.id, el.features[0],testArea)
                  return testArea
                }).map(area => {

                  console.log('CHECKING FLOOR ',area.id,feat,parseInt(aValue[i])) 

                  return area.floor.features[parseInt(feat)] = parseInt(aValue[i])
                });
              })
            break;
            case 3:
              aFeature.forEach((feat,i) => {
                tempScene.layers[0].areas.filter(el => {
                  let testArea = true
                  if(areaID){
                    if(areaID !== 'others'){
                      testArea = el.id === areaID
                    } else if(areaID === 'others'){
                      testArea = el.features[0] !== 8 && el.features[0] !== 6;
                    } else {
                      testArea = false;
                    }
                  }
                  if (selected !== null && selected !== el.id) {
                    testArea = false;
                  }

                  return testArea
                }).map(area => area.ceiling.features[parseInt(feat)] = parseInt(aValue[i])); 
              })
            break;
            case 4:
            case 5:
            case 6:
              tempScene.layers[0].holes
                .filter(el => {
                  let testArea = true
                  if(areaID){
                    const foundArea = getAreaOfId(tempScene.layers[0],areaID)
                    testArea = foundArea.holes.find(h => el.id === h)
                  }
                  return testArea
                })
                .filter(h => h.id_element === parseInt(elId))
                .filter(ho => itemID ? ho.id === itemID : true)
                .filter(it => checkFilterItem(it,filters))
                .map(hole => {
                  const tempFeatures = hole.features;
                  aFeature.forEach((feat,i) => {
                    tempFeatures[parseInt(feat)] = parseInt(aValue[i])
                  })
                  hole.features = tempFeatures;
                  return hole
                }); 
            break;
            case 8:
            case 10:
            case 11:
            case 12:
            case 15:
            case 16:
              tempScene.layers[0].items
                .filter(el => {
                  let testArea = true
                  if(areaID){
                    testArea = checkIfInArea(tempScene.layers[0],areaID,el)
                  }
                  return testArea === true
                })
                .filter(it => it.id_element === parseInt(elId))
                .filter(it => checkFilterItem(it,filters))
                .filter(it => {
                  return  itemID !== null ? it.id === itemID : true;
                })
                .map(it => {
                  const tempFeatures = it.features;
                  aFeature.forEach((feat,i) => {
                    tempFeatures[parseInt(feat)] = parseInt(aValue[i])
                  })
                  it.features = tempFeatures;
                  return it
                });
            break;
            case 19:
              tempScene.layers[0].areas
                .filter(a => {
                  let testArea = true
                  if(areaID){
                    testArea = a.id === areaID
                  }
                  return testArea === true
                })
                .map(area => {
                  area.features[parseInt(feature)] = parseInt(value);
                  return area
                })

                console.log('-----IN AREA ',tempScene.layers[0].areas)
            case 22:
              aFeature.forEach((feat,i) => {
                tempScene.house[2].features[parseInt(feat)] = parseInt(aValue[i]);
              })
            break;
            default:
             tempScene.layers[0].items
             .filter(el => {
                let testArea = true
                if(areaID){
                  const foundArea = getAreaOfId(tempScene.layers[0],areaID)
                  if(foundArea){
                    testArea = foundArea.items.find(itemID => el.id === itemID)
                  }
                }
                return testArea
              })
             .filter(it => {
                return  itemID !== null ? it.id === itemID : true;
              }).map(item => {
                if(filters && filters.length > 0){
                  const test = [];
                    filters.forEach(fil => {                              
                      if(item.features[fil.f] === parseInt(fil.v)){
                        test.push(true)
                      } else {
                        test.push(false)
                      }
                    })

                    // console.log('Changing',item.id,test,filters.length)              

                    if(test.every(t => t === true)){
                      aFeature.forEach((feat,i) => {
                        item.features[parseInt(feat)] = parseInt(aValue[i]);
                      })
                    }                            
                } else {
                  aFeature.forEach((feat,i) => {
                    item.features[parseInt(feat)] = parseInt(aValue[i]);
                  })
                }
            }) 
            // console.log('after',tempScene)
          }

          return scenes
        })

        setRefresh(!refresh)
    }

    const setMeasurements = (table = 'integratedTable', measurements, itemID = null, area = null) => {
      return new Promise ((resolve) => projectActions.setScenes((scenes) => {
        console.log('SETTING MEASUREMENT', table, measurements, itemID, area)
        const sceneId = table === 'integratedTable' ? 1 : 0;

        scenes.map((scene,i) => {
          if(table === 'all' || sceneId === i){
            const tempLayer = scene.layers[0] 

            tempLayer.items.map(item => {
              if(item.id === itemID){
                const field = measurements.length * measurements.depth
                item.measurements = {area:field,length:measurements.length,depth:measurements.depth}
              }

              return item
            })

            scene.layers[0] = tempLayer
          }

          return scene
        })

        resolve(scenes);
        
        return scenes
      }))
    }

    const setItemFeature = (table,itemID,feature,value) => {
      return new Promise ((resolve) => projectActions.setScenes((scenes) => {
        const sceneId = scene === null ? 1 : scene === 'scene' ? 0 : 1;

        scenes.map((scene,i) => {

          if(table === 'all' || sceneId === i){
            const tempLayer = scene.layers[0] 

            tempLayer.areas = tempLayer.areas.map(a => {
              if(a.id === itemID){
                 a.features[feature] = value
                 a.floor.features[feature] = value
                 a.ceiling.features[feature] = value
              }

              return a
            })

            tempLayer.lines = tempLayer.lines.map(l => {
              if(l.id === itemID){
                 l.features[feature] = value
              }

              return l
            })

            tempLayer.items = tempLayer.items.map(item => {
              if(item.id === itemID){
                 item.features[feature] = value
              }

              return item
            })

            tempLayer.holes = tempLayer.holes.map(h => {
              if(h.id === itemID){
                 h.features[feature] = value
              }

              return h
            })
          }  
        
        return scene
      })

        resolve(scenes);

        return scenes
      }))
  }

    const setFeatureOfAll = (table='all', layerID=0, areaID=null, feature, value=0) => {

      console.log('Setting all',table, layerID, areaID, feature, value)
     
      const aFeature = feature.toString().split(',')
      const aValue = value.toString().split(',')
      
      if(projectActions.scenes){
        
        const sceneId = table === 'mScene' ? 1 : 0;
        
        return new Promise ((resolve) => projectActions.setScenes((scenes) => {
          scenes.map((scene,i) => {

            if(table === 'all' || sceneId === i){
              const tempScene = scene;            

              // console.log('ALL',feature,value,areaID,tempScene);
              
              let templayer = tempScene.layers[layerID] ?? tempScene.layers[0];
              
              // console.log('IN SET ALL',templayer,feature,value)
              
              if(!areaID){
                aFeature.forEach((feat,i) => {
                  tempScene.house.forEach((houseItem) => {
                    houseItem.features[parseInt(feat)] = parseInt(aValue[i]);
                  })
                })
              }

              aFeature.forEach((feat,i) => {
                tempScene.layers[layerID].areas.filter(a => {  
                  let testArea = true
                  if(areaID === 'others'){
                    testArea = a.features[0] !== 6 && a.features[0] !== 8 
                  } else if (areaID === 'cocinas'){
                    testArea = a.features[0] === 8
                  } else if (areaID === 'banos') {  
                    testArea = a.features[0] === 6
                  } else if (areaID) {
                    testArea = a.id === areaID
                  }
                  
                  return testArea
                }).map(area => {
                  area.features[parseInt(feat)] = parseInt(aValue[i])
                  area.floor.features[parseInt(feat)] = parseInt(aValue[i])

                  tempScene.layers[layerID].items.filter(item => area.floor.items.includes(item.id)).map(item => {
                    aFeature.forEach((feat,i) => {
                      item.features[parseInt(feat)] = parseInt(aValue[i]);
                    })
                  })               

                  area.ceiling.features[parseInt(feat)] = parseInt(aValue[i])

                  tempScene.layers[layerID].items.filter(item => area.ceiling.items.includes(item.id)).map(item => {
                    aFeature.forEach((feat,i) => {
                      item.features[parseInt(feat)] = parseInt(aValue[i]);
                    })
                  })               

                  const areaLines = getAreaLines(tempScene.layers[layerID],areaID)
                  
                  areaLines.map(line => {

                    aFeature.forEach((feat,i) => {
                      line.features[parseInt(feat)] = parseInt(aValue[i])
                    })
                    
                    tempScene.layers[layerID].items
                      .filter(item => line.items.includes(item.id)).map(item => {
                      aFeature.forEach((feat,i) => {
                        item.features[parseInt(feat)] = parseInt(aValue[i]);
                      })
                    })   
                    
                    tempScene.layers[layerID].holes.filter(hole => line.holes.includes(hole.id)).map(hole => {
                      aFeature.forEach((feat,i) => {
                        hole.features[parseInt(feat)] = parseInt(aValue[i])
                      })
                      return hole
                    });  

                    return line
                  })

                  tempScene.layers[layerID].items.filter(item => area.items.includes(item.id)).map(item => {
                    aFeature.forEach((feat,i) => {
                      item.features[parseInt(feat)] = parseInt(aValue[i]);
                    })
                  }) 
                  
                  return area
                })
              })
              
              // console.log('after',projectActions.scenes[1].layers[layer])
            }
            return scene})

          resolve(scenes);

          return scenes
        }))
      }
    }
 
    const resetScenes = () => {

      projectActions.setScenes(scenes => {
        const tempScene = scenes[0]

        tempScene.layers[layer].items = projectActions.sceneData[projectActions.project.defaults.houseShape].scene.layers[layer].items

        scenes[0] = tempScene

        return scenes
      })

      copyScene();
    }

    const deleteScene = (sceneId) => {
      new Promise((resolve, reject) => {
        projectActions.setScenes(scenes => {
          return scenes.filter((scene,i) => i === 0)
        })
      }).then(() => console.log('DEL OK'))
    }

    const copyScene = () => {

      console.log('IN COPY SCENE',projectActions.scenes,calcData)

      return new Promise((resolve, reject) => {
        const tempLayer = JSON.parse(JSON.stringify(layer))

        const zeros = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]

        if(calcData){
        projectActions.setScenes(scenes => {
          const tempInitialScene = JSON.parse(JSON.stringify(scenes[0]))

          tempInitialScene.house = tempInitialScene.house.map(houseItem => {
            houseItem.features = houseItem.features.slice(0,8).concat(zeros)
            return houseItem
          })
 
          const currentLayer = tempInitialScene.layers[0]

          currentLayer.areas.map(area => {
            const floorFeatures = [53,null,null,37,47,null,50]
            const oldTypology = area.ceiling.features[1]
            const ceilingFeatures = [70,oldTypology,57,60,66,null,67]

            area.features = area.features.slice(0,8).concat(zeros)
            const oldValue = calcData.elements.find(el => el.id_element === area.id_element)
            area.features = area.features.slice(0,8).concat(zeros)
            area.features[7] = oldValue ? oldValue.old : null;

            area.floor = {...area.floor,features:area.floor.features.slice(0,7).concat(floorFeatures,zeros).slice(0,24)}
            area.ceiling = {...area.ceiling,features:area.ceiling.features.slice(0,7).concat(ceilingFeatures,zeros).slice(0,24)}

            if(area.floor.features[5]){
              const floorArea = area.floor.measurements.area;

              let areaOption = 0

              // if(parseFloat(floorArea) > 5 && parseFloat(floorArea) <= 8){areaOption = 1}
              // if(parseFloat(floorArea) > 7){areaOption = 2}

              // const values = [
              //   [1,2,3],
              //   [4,5,6],
              //   [7,8,9],//size 2
              //   [10,11,12]//size 2
              // ]

              // console.log('areaOption:',areaOption,' | floor feature:',area.floor.features[5],' FROM ARRAY:',values[areaOption]);

              // area.floor.features[15] = values[area.floor.features[5] - 1][areaOption];
              // area.floor.features[15] = area.floor.features[5];
              area.floor.features[5] = null;
            }
            return area
          })

          currentLayer.lines.map(line => {
            const oldValue = calcData.elements.find(el => el.id_element === line.id_element)
            const filler = [null,null,29,32,12,14,line.features[3],null,null,29]
            line.features = line.features.slice(0,4).concat(filler,zeros).slice(0,24)
            return line
          })

          currentLayer.items.map(item => {
            const itemData = calcData.elements.find(el => el.id_element === item.id_element)
            const oldTypology = item.features[1]
            item.features = item.features.slice(0,8).concat(zeros)
            item.features[7] = itemData ? itemData.old : null;
            item.features[8] = oldTypology;
            if(item.id_element === 15){
                item.features[7] = 174;
            }
            return item
          })    

          currentLayer.holes.map(hole => {
            const holeData = calcData.elements.find(el => el.id_element === hole.id_element)
            const oldTypology = hole.features[1]
            hole.features = hole.features.slice(0,8).concat(zeros)
            hole.features[7] = holeData ? holeData.old : null;
            hole.features[8] = oldTypology;
            if(hole.id_element === 4){
                hole.features[7] = 76;
            }
            return hole
          })  

          currentLayer.groups = currentLayer.groups ? currentLayer.groups.map(group => {
            return group
          }) : [];
          
          console.log('AFTER COPY ',projectActions.scenes)   

          return [scenes[0],tempInitialScene]
        })

      }
      resolve()

      console.log('COPIED ',projectActions.scenes)           

        calcActions.makeCalculations()
        calcActions.makeResults()

        projectActions.setRefresh(!projectActions.refresh)


        
      })
    }

    const getNewElement = (scenename,id_element = null,type = null,typology,name,label,sentfeatures=null,measurements=null,position=null) => {

      console.log('-----MAKING NEW ELEMENT',scenename,id_element,type,typology,name,label,sentfeatures,measurements)

 
      if(type === null){
        type = elementIdToName(id_element)
      } 

      if(id_element === null){
        id_element = elementNameToId(type)
      }

      const elementData = getElementData(projectActions.elementsData,id_element,typology)

      console.log('NEW ELEMENT',elementData,id_element,typology)

      if(!measurements && elementData.measurements){
        measurements = elementData.measurements
        if(!measurements.area){
          measurements.area = measurements.length * measurements.height
        }
        console.log(measurements)
      }

      let features = new Array(8).fill(null);

      features[0] = projectActions.roomType ? projectActions.roomType : 6;
      features[1] = parseInt(typology);

      if(scenename === 'mScene'){
        const zeros = new Array(16).fill(0)
        
        features = features.slice(0,8).concat(zeros)
        features[7] = getNewFromCalcData(calcData,id_element)
        // features[15] = 2
        // console.log('WCHODZI',features)
      }

      const newElement = createElement(name ?? elementData.name,label ?? elementData.label,id_element,type,features ?? elementData.features,measurements,position)

      return newElement
    }

    const performSingleAction = (scenename,action,id_element = null,filters=[],areaId=null,id) => {
      const table = scenename === 'mScene' ? 'integratedTable' : 'initialTable';
      console.log('|||||||||||||||||| PERFORMING ACTION',action,id_element)
      switch(action.a){
        case 'closePopup':
          projectActions.setPopup(null)
          projectActions.setModal(null)
          projectActions.setModalData(null)
          projectActions.setModalParams(null)
        break;
        case 'copyScene':
          copyScene();
        break;
        case 'set':
          setFeature(scenename,action.f, action.v, id_element !== null ? id_element : parseInt(elementNameToId(action.e)),filters,id,areaId)
        break;
        case 'setAll':
          setFeatureOfAll(scenename,0,action.area,action.f, action.v, id_element !== null ? id_element : parseInt(elementNameToId(action.e)),filters,null)
        break;
        case 'setDefault': 
          projectActions.setDefault(action.e,action.v)
          // setFeature(scenename,action.f, action.v, id_element !== null ? id_element : parseInt(elementNameToId(action.e)),filters)
        break;
        case 'setChapter':
          qnrActions.setChapter(action.v)
        break;
        case 'setImg':
          console.log('SAT IMAGE',action)
          qnrActions.setActiveImage({url:action.v,size:action.size,label:action.label})
        break;
        case 'resetImg':
          qnrActions.setActiveImage(null)
        break;
        case 'showModal':
        if(action.params){
          projectActions.setModalParams(action.params)
          projectActions.setModal(true)
        } else {
          projectActions.setModal(action.v)
        }
        break;
        case 'add':
          addElement( table,areaId,getNewElement(scenename,id_element ?? elementNameToId(action.e),null,parseInt(action.v),action.name,action.label,null,action.measurements))
        break;
        case 'addGroup':
          switch (action.driver){
            case 'roomSize':
              const actualLayer = projectActions.scenes[table === 'initialTable' ? 0 : 1].layers[0];
              const area = actualLayer.areas.find(a => a.id === 'cocina').floor.measurements.area;

              let areaOption = 0;
              if(parseFloat(area) > 5.5 && parseFloat(area) <= 8){areaOption = 1}
              if(parseFloat(area) > 8){areaOption = 2}
              action.variants[areaOption].elements.forEach(el => {
                addElement( table,el.area,getNewElement(scenename,elementNameToId(el.e),null,el.v,el.name,el.label,null,el.measurements))
              })
              
            break;
            default:
          }
        break;
        case 'actionsGroup':
          switch (action.driver){
            case 'roomSize':
              const actualLayer = projectActions.scenes[table === 'initialTable' ? 0 : 1].layers[0];
              const area = actualLayer.areas.find(a => a.id === 'cocina').floor.measurements.area;

              let areaOption = 0;
              if(parseFloat(area) > 5.5 && parseFloat(area) <= 8){areaOption = 1}
              if(parseFloat(area) > 8){areaOption = 2}
              
              performActions(scenename,action.variants[areaOption].actions)
            break;
            default:
          }
        break;
        case 'removeAllFromArea':
          const actualLayer = projectActions.scenes[table === 'initialTable' ? 0 : 1].layers[0];
          removeAllFromArea(actualLayer,action.area,action.e,action.filter)
        break;
        case 'removeAll':
          const layerToRemove = projectActions.scenes[table === 'initialTable' ? 0 : 1].layers[0];
          removeElementFromLayer(layerToRemove,action.area,elementNameToId(action.e))
        break;
        default:
          console.log('TRYING TO PERFORM ACTION',action)
      }                              
    }

    const performActions = (scenename = 'mScene', actions = [],id_elements = [], filters=[], areaId, id) => {
      actions.forEach(action => {
            if(id_elements.length > 0){
                id_elements.forEach(el_id => {
                  performSingleAction(scenename,action,el_id,filters,action.area,id)
                })
            } else {
              performSingleAction(scenename,action,parseInt(elementNameToId(action.e)),filters,action.area,id)           
            }
        })
    }

    const makeActions = (table = 'all',actions,itemID,areaID,groupID) => {
      console.log('inside make actions',actions)
      actions.forEach(a => {
        const elId = isNaN(a.idelement ?? a.e) ? elementNameToId(a.idelement ?? a.e) : a.idelement ?? a.e;
        const tempLayer = projectActions.scenes[table === 'initialTable' ? 0 : 1].layers[0];
        let foundArea = null

        if(itemID){
          foundArea = getAreaOfId(tempLayer,itemID)
          console.log('FOUND',foundArea)
        }

        switch(a.a){
          case 'add':
            const newElement = getNewElement('scene',null,a.e,a.typology,a.label)
            const layer = projectActions.scenes[0].layers[0]
            const area = layer.areas.find(ar => ar.id === a.area)
            let target = null
            if(a.target){
              switch (a.target) {
                case 'wall':
                  const foundLine = layer.lines.find(l => area.lines.includes(l.id))

                  console.log('FOUND LINE',foundLine)

                  if(foundLine && area){
                    target = foundLine.id
                  }                  
                break;
                default:
                  target = a.target
              }
            }

            console.log('====ADDING',table,newElement)
            if(table === 'all'){
              addElement('initialTable',a.area,newElement,target)
              const converted = convertToNew(projectActions.calcData,newElement)
              converted.features[7] = getOldFromCalcData(calcData,converted.id_element) //    -    without old 

              console.log('ADD',converted.features)

              addElement('integratedTable',a.area,converted,target)
            } else if(table !== 'integratedTable') {
              addElement(table,a.area,newElement,target)
            } else {
              addElement(table,a.area,convertToNew(projectActions.calcData,newElement),target)
            }
          break;
          case 'del':
            console.log('DELETING',)
            removeElement(table,null,null,null,a.id)
          break;
          case 'set':
            console.log('MAKING SET',a,elId,'GID',groupID,projectActions.scenes[0].layers[0].groups.map(g => g.items))
            if(groupID){
              setGroupFeature('mScene', a.f, a.v, groupID, elId)
            } else {
              setFeature('mScene',a.f, a.v, elId, a.filters, !foundArea ? itemID : null,foundArea ? foundArea.id : a.area,groupID)
            }
          break;
          default:
            console.log('MAKING ACTION',a)
        }
      })
    }

    const changeSize = (e) => {
        const { name, value, id } = e.target;
        
        console.log('IN SIZE',name, value, id)

        const realValue =  parseFloat(value) / 100;

        const tempScene = projectActions.scenes[0]

        const tempVerts = tempScene.layers[0].vertices
        
        const foundElement = tempScene.layers[0].lines.find(el => {
            return el.id === id
        })

        const connectedElements = tempScene.layers[0].lines.filter(elm => elm.id !== foundElement.id).filter(el => {
            return el.v1 === foundElement.v2 || el.v2 === foundElement.v2
        })

        const extendLn  = extendLine(
            tempVerts[connectedElements[0].v1].x,
            tempVerts[connectedElements[0].v1].y,
            tempVerts[connectedElements[0].v2].x,
            tempVerts[connectedElements[0].v2].y,
            realValue
          )
        
        const angle = angleBetweenTwoPoints(
            tempVerts[foundElement.v1].x,
            tempVerts[foundElement.v1].y,
            tempVerts[foundElement.v2].x,
            tempVerts[foundElement.v2].y
        )

        const oldDistance = verticesDistance(tempVerts[foundElement.v1],tempVerts[foundElement.v2])

        if(oldDistance < realValue){
            const diff = realValue - oldDistance;
            if(angle === 0){
                if(connectedElements.length > 0){
                  tempVerts[connectedElements[0].v1].x += diff;
                  tempVerts[connectedElements[0].v2].x += diff;
                } else {
                  tempVerts[foundElement.v2].x += diff;
                }
            } else if (angle > 0 && angle < 1.6) {
                if(connectedElements.length > 0){
                  tempVerts[connectedElements[0].v1].y += diff;
                  tempVerts[connectedElements[0].v2].y += diff;
                } else {
                  tempVerts[foundElement.v2].y += diff;
                }
            } else if (angle > 3) {
                if(connectedElements.length > 0){
                  tempVerts[connectedElements[0].v1].x -= diff;
                  tempVerts[connectedElements[0].v2].x -= diff;
                } else {
                  tempVerts[foundElement.v2].x -= diff;
                }
            } else if (angle > 2 && angle < 3) {
                if(connectedElements.length > 0){
                  tempVerts[connectedElements[0].v1].x -= diff;
                  tempVerts[connectedElements[0].v2].x -= diff;
                } else {
                  tempVerts[foundElement.v2].x -= diff;
                }
              } else if (angle < -1.5 && angle > -3) {
                if(connectedElements.length > 0){
                  tempVerts[connectedElements[0].v1].y -= diff;
                  tempVerts[connectedElements[0].v2].y -= diff;
                } else {
                  tempVerts[foundElement.v2].y -= diff;
                }
              } else if (angle < -3) {
                if(connectedElements.length > 0){
                  tempVerts[connectedElements[0].v1].x -= diff;
                  tempVerts[connectedElements[0].v2].x -= diff;
                } else {
                  tempVerts[foundElement.v2].x -= diff;
                }
              }
          }
          if(oldDistance > realValue){
            const diff = oldDistance - realValue;
            if(angle === 0){
              if(connectedElements.length > 0){
                tempVerts[connectedElements[0].v1].x -= diff;
                tempVerts[connectedElements[0].v2].x -= diff;
              } else {
                tempVerts[foundElement.v2].x -= diff;
              }
            } else if (angle > 0 && angle < 1.6) {
              if(connectedElements.length > 0){
                tempVerts[connectedElements[0].v1].y -= diff;
                tempVerts[connectedElements[0].v2].y -= diff;
              } else {
                tempVerts[foundElement.v2].y -= diff;
              }
            } else if (angle > 3) {
              if(connectedElements.length > 0){
                tempVerts[connectedElements[0].v1].x += diff;
                tempVerts[connectedElements[0].v2].x += diff;
              } else {
                tempVerts[foundElement.v2].x += diff;
              }
            } else if (angle < -1.5 && angle > -3) {
              if(connectedElements.length > 0){
                tempVerts[connectedElements[0].v1].y += diff;
                tempVerts[connectedElements[0].v2].y += diff;
              } else {  
                tempVerts[foundElement.v2].y += diff;
              }
            } else if (angle < -3) {
              if(connectedElements.length > 0){
                tempVerts[connectedElements[0].v1].x += diff;
                tempVerts[connectedElements[0].v2].x += diff;
              } else {
                tempVerts[foundElement.v2].x += diff;
              }
            }
          }
        
          setNewVertices(tempVerts)
    }

    return (
        <SceneContext.Provider value={{
            viewMode, setViewMode,
            scene, setScene, setSceneParameter,  setCurrentScene, layer,
            mScene, setMScene, copyScene, saveScenes, resetScenes, deleteScene,
            refresh, setRefresh,
            addVertex, addLine, addRoom, removeRoom, setAreaScale, moveArea, moveElement,
            target, setTarget, attachElement,
            elements, setElements, addElement, addElementToAll, removeElement, removeElement, delSelected,
            moveToGroup,removeFromGroup,resetGroups,toggleBetweenGroups,
            setFeature, setFeatureOfAll, setGroupFeature, setProperty, setMeasurements,
            performActions, makeActions,
            replaceLines, replaceVertices,setNewVertices,changeSize,
            currentArea, setCurrentArea,
            selected, setSelected,
            activeRoom, setActiveRoom,
            active, setActive,
            over, setOver,
            shiftActive
        }}>{children}</SceneContext.Provider>
    )
}

export default SceneContext; 