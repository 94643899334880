import {toFixedFloat, fAbs} from './math.js';
import {EPSILON} from './constants';

export function compareVertices(v0, v1) {
  return v0.x === v1.x ? v0.y - v1.y : v0.x - v1.x;
}

export function minVertex(v0, v1) {
  return compareVertices(v0, v1) > 0 ? v1 : v0;
}

export function maxVertex(v0, v1) {
  return compareVertices(v0, v1) > 0 ? v0 : v1;
}

export function minVertOfVerts(verts) {
  return verts[0]
}

export function maxVertOfVerts(verts) {
  return verts[1]
}

export function orderVertices(vertices) {
  return vertices.sort(compareVertices);
}

export const getBBox = (vertices,scale = 1) => {

  const minX = vertices.map(v => v.x * scale).reduce((acc, val) => {
        return acc < val ? acc : val
    })
    const maxX = vertices.map(v => v.x * scale).reduce((acc, val) => {
      return acc > val ? acc : val
    })
    const minY = vertices.map(v => v.y * scale).reduce((acc, val) => {
        return acc < val ? acc : val
    })
    const maxY = vertices.map(v => v.y * scale).reduce((acc, val) => {
        return acc > val ? acc : val
    })

  return {x:minX, y:minY, width:maxX - minX, height:maxY - minY, maxX:maxX, maxY:maxY}
}

export const shiftVertices = (vertices,newPos = {x:0,y:0}) => {

  vertices = vertices.map(v => {
    return {x:v.x + newPos.x,y:v.y + newPos.y}
  })

  return vertices
}

export function getOffset(ctx, vertices, scale) {
  if(vertices.length > 0){
    const minX = vertices.map(v => v.x).reduce((acc, val) => {
        return acc < val ? acc : val
    })
    const maxX = vertices.map(v => v.x).reduce((acc, val) => {
        return acc > val ? acc : val
    })
    const minY = vertices.map(v => v.y).reduce((acc, val) => {
        return acc < val ? acc : val
    })
    const maxY = vertices.map(v => v.y).reduce((acc, val) => {
        return acc > val ? acc : val
    })

    const width = ctx.canvas.width;
    const height = ctx.canvas.height;

  // console.log('IN OFFSET',vertices,minX,minY,maxX, maxY, width,height)

    const offsetX = ((width - pointsDistance(minX, 0, maxX, 0) * 100) / 2) - minX * 100
    const offsetY = ((height - pointsDistance(0, minY, 0, maxY) * 100) / 2) - minY * 100

    return {
        x: offsetX,
        y: offsetY
    }    
  } else {
    return {
        x: 0,
        y: 0
    }        
  }
}

export const getOffset2 = (ctx,vertices,scale) => {
  if(vertices.length > 0){
    const minX = vertices.map(v => v.x * scale).reduce((acc, val) => {
        return acc < val ? acc : val
    })
    const maxX = vertices.map(v => v.x * scale).reduce((acc, val) => {
      return acc > val ? acc : val
    })
    const minY = vertices.map(v => v.y * scale).reduce((acc, val) => {
        return acc < val ? acc : val
    })
    const maxY = vertices.map(v => v.y * scale).reduce((acc, val) => {
        return acc > val ? acc : val
    })

    const length = maxX - minX
    const height = maxY - minY

    const offsetX = -minX + (ctx.canvas.width - length) / 2
    const offsetY = -minY + (ctx.canvas.height - height) / 2

    // console.log('IN OFFSET',scale,length,height,offsetX,offsetY)

    return {x:offsetX, y:offsetY}
    // return {x:10, y: 10}
  } else {
    return {x:10, y: 10}
  }

}

export const getScale = (ctx,vertices) => {
  if(vertices.length > 0){
    const minX = vertices.map(v => v.x).reduce((acc, val) => {
        return acc < val ? acc : val
    })
    const maxX = vertices.map(v => v.x).reduce((acc, val) => {
        return acc > val ? acc : val
    })
    const minY = vertices.map(v => v.y).reduce((acc, val) => {
        return acc < val ? acc : val
    })
    const maxY = vertices.map(v => v.y).reduce((acc, val) => {
        return acc > val ? acc : val
    })

    const length = maxX - minX
    const height = maxY - minY

    return Math.floor(Math.min(ctx.canvas.width/length, ctx.canvas.height/height)) - 5
  } else {
    return 40;
  }
}

export const getRealPos = (pos,scale = 1,offset = {x:0,y:0}) => {
  pos = {x:(pos.x - offset.x)/scale,y:(pos.y - offset.y)/scale}

  return pos
}

export function pointsDistance(x0, y0, x1, y1) {
    let diff_x = x0 - x1;
    let diff_y = y0 - y1;

    return Math.sqrt((diff_x * diff_x) + (diff_y * diff_y));
}

export function verticesDistance(v1, v2) {

    // console.log('measure',v1,v2)

    let { x: x0,y: y0 } = v1;
    let { x: x1,y: y1 } = v2;

    return pointsDistance(x0, y0, x1, y1);
}

export function getPolygonCentroid(points){
    var centroid = {x: 0, y: 0};
    for(var i = 0; i < points.length; i++) {
       var point = points[i];
       centroid.x += point.x;
       centroid.y += point.y;
    }
    centroid.x /= points.length;
    centroid.y /= points.length;
    return centroid;
  }

export function midPoint( x1, y1, x2, y2 ) {
    return { x: (x1+x2)/2, y: (y1+y2)/2 };
  }

export function roundRect(ctx, x, y, width, height, radius, fill, stroke) {
  if (typeof stroke === 'undefined') {
    stroke = true;
  }
  if (typeof radius === 'undefined') {
    radius = 5;
  }
  if (typeof radius === 'number') {
    radius = {tl: radius, tr: radius, br: radius, bl: radius};
  } else {
    var defaultRadius = {tl: 0, tr: 0, br: 0, bl: 0};
    for (var side in defaultRadius) {
      radius[side] = radius[side] || defaultRadius[side];
    }
  }

  ctx.beginPath();
  ctx.moveTo(x + radius.tl, y);
  ctx.lineTo(x + width - radius.tr, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
  ctx.lineTo(x + width, y + height - radius.br);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
  ctx.lineTo(x + radius.bl, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
  ctx.lineTo(x, y + radius.tl);
  ctx.quadraticCurveTo(x, y, x + radius.tl, y);
  ctx.closePath();
  if (fill) {
    ctx.fill();
  }
  if (stroke) {
    ctx.stroke();
  }

}

export function pointOnLine(x1, y1, x2, y2, distance){

  // console.log('POINT ON LINE',x1,y1,x2,y2,distance)

  // //if line is vertical
  if(x1 === x2){
      return {x: x2, y: y2 > y1 ? y1 + distance : y1 - distance};
  }
  // //if line is horizontal
  if(y1 === y2)
      return {y: y2, x: x2 > x1 ? x1 + distance : x1 - distance};

  // //get each side of original triangle length
  var adjacent   = y2 - y1;
  var opposite   = x2 - x1;
  var hypotenuse = Math.sqrt(Math.pow(opposite, 2) + Math.pow(adjacent,2));

  // //find the angle
  var angle = Math.acos(adjacent/hypotenuse);

  // //calculate new opposite and adjacent sides
  var newOpposite = Math.sin(angle) * distance;
  var newAdjacent = Math.cos(angle) * distance;

  // //calculate new x/y, see which direction it's going
  var y = y1 - newAdjacent,
      x = x1 + newOpposite;

  return {y: y, x: x};

}

export function pointPositionOnLineSegment(x1, y1, x2, y2, xp, yp) {
  let length = pointsDistance(x1, y1, x2, y2);
  let distance = pointsDistance(x1, y1, xp, yp);

  let offset = distance / length;
  if (x1 > x2) offset = mapRange(offset, 0, 1, 1, 0);

  return offset;
}

export function mapRange(value, low1, high1, low2, high2) {
  return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
}

export function angleBetweenTwoPointsAndOrigin(x1, y1, x2, y2) {
  return -(Math.atan2(y1 - y2, x2 - x1)) * 180 / Math.PI;
}

export function angleBetweenTwoPoints(x1, y1, x2, y2) {
  return Math.atan2(y2 - y1, x2 - x1);
}

export function absAngleBetweenTwoPoints(x1, y1, x2, y2) {
  return Math.atan2(Math.abs(y2 - y1), Math.abs(x2 - x1));
}

export function samePoints({x: x1, y: y1}, {x: x2, y: y2}) {
  return fAbs(x1 - x2) <= EPSILON && fAbs(y1 - y2) <= EPSILON;
}

export function extendLine(x1, y1, x2, y2, newDistance, precision = 6) {
  let rad = angleBetweenTwoPoints( x1, y1, x2, y2 );

  return {
    x: toFixedFloat(x1 + (Math.cos(rad) * newDistance), precision),
    y: toFixedFloat(y1 + (Math.sin(rad) * newDistance), precision),
  };
}

export function roundVertex(vertex, precision = 6) {
  vertex.set('x', toFixedFloat(vertex.get('x'), precision));
  vertex.set('y', toFixedFloat(vertex.get('y'), precision));

  return vertex;
}
export function pointIsInPoly(p, polygon) {

  console.log('IN POLY',p,polygon)

  var isInside = false;
  var minX = polygon[0].x, maxX = polygon[0].x;
  var minY = polygon[0].y, maxY = polygon[0].y;
  for (var n = 1; n < polygon.length; n++) {
      var q = polygon[n];
      minX = Math.min(q.x, minX);
      maxX = Math.max(q.x, maxX);
      minY = Math.min(q.y, minY);
      maxY = Math.max(q.y, maxY);
  }

  if (p.x < minX || p.x > maxX || p.y < minY || p.y > maxY) {
      return false;
  }

  var i = 0, j = polygon.length - 1;
  for (i, j; i < polygon.length; j = i++) {
      if ( (polygon[i].y > p.y) != (polygon[j].y > p.y) &&
              p.x < (polygon[j].x - polygon[i].x) * (p.y - polygon[i].y) / (polygon[j].y - polygon[i].y) + polygon[i].x ) {
          isInside = !isInside;
      }
  }

  return isInside;
}

export const pointIsInArea = (point,vertices) => {
  console.log(point,vertices)

    //A point is in a polygon if a line from the point to infinity crosses the polygon an odd number of times
    let odd = false;
    //For each edge (In this case for each point of the polygon and the previous one)
    for (let i = 0, j = vertices.length - 1; i < vertices.length; i++) {
        //If a line from the point into infinity crosses this edge
        if (((vertices[i].y > point[1]) !== (vertices[j].y > point.y)) // One point needs to be above, one below our y coordinate
            // ...and the edge doesn't cross our Y corrdinate before our x coordinate (but between our x coordinate and infinity)
            && (point.x < ((vertices[j].x - vertices[i].x) * (point.y - vertices[i].y) / (vertices[j].y - vertices[i].y) + vertices[i].x))) {
            // Invert odd
            odd = !odd;
        }
        j = i;
    }
    //If the number of crossings was odd, the point is in the polygon
    return odd;
}

export const inside = (point, vertices) => {
  
  const between = (p, a, b) => p >= a && p <= b || p <= a && p >= b

  let inside = false
  for (let i = vertices.length-1, j = 0; j < vertices.length; i = j, j++) {
      const A = vertices[i]
      const B = vertices[j]
      // corner cases
      if (point.x == A.x && point.y == A.y || point.x == B.x && point.y == B.y) return 0
      if (A.y == B.y && point.y == A.y && between(point.x, A.x, B.x)) return 0

      if (between(point.y, A.y, B.y)) { // if P inside the vertical range
          // filter out "ray pass vertex" problem by treating the line a little lower
          if (point.y == A.y && B.y >= A.y || point.y == B.y && A.y >= B.y) continue
          // calc cross product `PA X PB`, P lays on left side of AB if c > 0 
          const c = (A.x - point.x) * (B.y - point.y) - (B.x - point.x) * (A.y - point.y)
          if (c == 0) return 0
          if ((A.y < B.y) == (c > 0)) inside = !inside
      }
  }

  return inside ? 1 : -1
};

export const inside2 = (point, vs) => {

  var x = point.x,
  y = point.y;

  var inside = false;
  for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    var xi = vs[i].x,
        yi = vs[i].y;
    var xj = vs[j].x,
        yj = vs[j].y;

    var intersect = ((yi > y) != (yj > y)) &&
        (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
    if (intersect) inside = !inside;
  }

  return inside;
} 

export function calculateArea (coords) {
  let area = 0;

  for (let i = 0; i < coords.length; i++) {
    const [x1, y1] = coords[i];
    const [x2, y2] = coords[(i + 1) % coords.length];

    area += x1 * y2 - x2 * y1
  }

  // return area / 2;
  // replace with
  return Math.abs(area) / 2;
}
 
export const pointsScale = (points,ratio,anchor) => {

  console.log('for centroid',ratio,points)

  ratio = ratio ?? 1 

  if(!anchor){
    anchor = getPolygonCentroid(points)
  }

  points.map((point,i) => {

    let vector_x = point.x - anchor.x
    let vector_y = point.y - anchor.y
    
    point.x = vector_x * Math.sqrt(ratio) + anchor.x
    point.y = vector_y * Math.sqrt(ratio) + anchor.y

    return point
  })

  return points
}

export const getVectors = (points,startPos) => {
  points = points.map(p => {
    return {x:startPos.x - p.x,y:startPos.y - p.y}
  })
  return points
}

export const pointsShift = (points, vectors, startPos, newPos) => {

  const minX = points.map(v => v.x).reduce((acc, val) => {
    return acc < val ? acc : val
  })

  const minY = points.map(v => v.y).reduce((acc, val) => {
    return acc < val ? acc : val
  })

  const offsets = points.map(p => {
    return {x:startPos.x - p.x,y:startPos.y - p.y}
  })

  points = points.map((p,i) => {
      return {x:newPos.x - vectors[i].x, y:newPos.y - vectors[i].y}  
  })

  return points
}

export function distancePointFromLine(x1, y1, x2, y2, xp, yp) {
  //http://stackoverflow.com/a/6853926/1398836

  let A = xp - x1;
  let B = yp - y1;
  let C = x2 - x1;
  let D = y2 - y1;

  let dot = A * C + B * D;
  let len_sq = C * C + D * D;
  let param = -1;
  if (len_sq != 0) //in case of 0 length line
    param = dot / len_sq;

  let xx, yy;

  if (param < 0) {
    xx = x1;
    yy = y1;
  }
  else if (param > 1) {
    xx = x2;
    yy = y2;
  }
  else {
    xx = x1 + param * C;
    yy = y1 + param * D;
  }

  let dx = xp - xx;
  let dy = yp - yy;

  // console.log(Math.sqrt(dx * dx + dy * dy))

  return Math.sqrt(dx * dx + dy * dy);
}

/**
 *
 * @param x1 {number} x for first vertex of the segment
 * @param y1 {number} y for first vertex of the segment
 * @param x2 {number} x for second vertex of the segment
 * @param y2 {number} y for second vertex of the segment
 * @param xp {number} x for point we want to verify
 * @param yp {number} y for point we want to verify
 * @param maxDistance {number} the epsilon value used for comparisons
 * @returns {boolean} true if the point lies on the line segment false otherwise
 */
export function isPointOnLine(x1, y1, x2, y2, xp, yp, maxDistance = EPSILON) {
  return distancePointFromLine(x1, y1, x2, y2, xp, yp) <= maxDistance;
}

export function offsetPoints(pts, offset) {
  let newPoints = [];
  for (let j = 0; j < pts.length; j++) {
      let i = (j - 1);
      if (i < 0) i += pts.length;
      let k = (j + 1) % pts.length;

      let v1 = [pts[j].x - pts[i].x, pts[j].y - pts[i].y];
      let mag1 = Math.sqrt(v1[0] * v1[0] + v1[1] * v1[1])
      v1 = [v1[0] / mag1, v1[1] / mag1]
      v1 = [v1[0] * offset, v1[1] * offset]
      let n1 = [-v1[1], v1[0]];
      let x1 = pts[i].x + n1[0];
      let y1 = pts[i].y + n1[1];
      let x2 = pts[j].x + n1[0];
      let y2 = pts[j].y + n1[1];

      let v2 = [pts[k].x - pts[j].x, pts[k].y - pts[j].y];
      let mag2 = Math.sqrt(v2[0] * v2[0] + v2[1] * v2[1])
      v2 = [v2[0] / mag2, v2[1] / mag2]
      v2 = [v2[0] * offset, v2[1] * offset]
      let n2 = [-v2[1], v2[0]];
      let x3 = pts[j].x + n2[0];
      let y3 = pts[j].y + n2[1];
      let x4 = pts[k].x + n2[0];
      let y4 = pts[k].y + n2[1];

      let den = ((y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1));
      let ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / den;
      let x = x1 + ua * (x2 - x1);
      let y = y1 + ua * (y2 - y1);

      newPoints.push({ x, y });
  }

  return newPoints;
}
