import { useRef, useEffect, useState, useContext } from 'react'
import ProjectContext from '../../context/ProjectContext'
import SceneContext from '../../context/SceneContext'
import QnrContext from '../../context/QnrContext'
import MiniFloorPlan from '../planner/MiniFloorPlan'
import { createLine, createRoom } from '../../utils/sceneUtils'
import { Button, message, Popconfirm } from 'antd'
import { FiEdit, FiSave } from 'react-icons/fi'

const key = "updatable";

const Rooms = ({modal}) => {

    const projectActions = useContext(ProjectContext)
    const sceneActions = useContext(SceneContext)


    const [areas, setAreas] = useState([])
    const [vertices, setVertices] = useState([])
    const [lines, setLines] = useState([])
 
    useEffect(() => {
        const layer = projectActions.scenes[0].layers[0]

        // console.log('===================',layer.vertices,layer.lines.map(l => [l.id,l.v1,l.v2]),layer.areas.map(a => [a.id,a.lines]))

        setVertices(() => layer.vertices);
        setLines(() => layer.lines);
        setAreas(() => layer.areas.filter(a => a.features[0] !== 6 && a.features[0] !== 8))
    },[sceneActions.refresh])

    // useEffect(() => {
    //    // console.log('IN ROOMS',projectActions.scenes[1].layers[0])
    // },[])


    const handleAddRoom = () => {

        const start = Math.floor(Math.random() * 5);
        const newVertices = [
            {x:0 + start,y:0 + start/2},
            {x:3 + start,y:0 + start/2},
            {x:3 + start,y:2 + start/2},
            {x:0 + start,y:2 + start/2}
        ]

        let startVertex = projectActions.scenes[1].layers[0].vertices.length
        
        const lineIDs = [] 
        
        sceneActions.addVertex(newVertices)
        
        // console.log('----------------addRoom',newVertices,'last',startVertex,projectActions.scenes[1].layers[0].vertices)


        // newVertices.forEach((v,i) => {
        //     // console.log("+ BEFORE",i,v,projectActions.scenes[1].layers[0].vertices.length)
        //     // 
        //     console.log("+ AFTER ",i,v,startVertex + i - 1)
        //     let verts = {}
        //     if(i > 0){
        //         console.log('- CREATE LINE',startVertex + i - 2,startVertex + i - 1)
        //         verts = {v1:startVertex + i - 3,v2:startVertex + i - 1}
        //         const newLine = createLine(null,null,verts.v1,verts.v2,[10,12,14,17,null,null,29,null])
        //         lineIDs.push(newLine.id)
        //         sceneActions.addLine(newLine)        
        //         if(i === newVertices.length - 1){
        //             console.log('- CLOSING LINE',startVertex + i - 1,startVertex)
        //             verts = {v1:startVertex + i - 1,v2:startVertex + 1}
        //             const newLine = createLine(null,null,verts.v1,verts.v2,[10,12,14,17,null,null,29,null])
        //             lineIDs.push(newLine.id)
        //             sceneActions.addLine(newLine)
        //         }            
        //     }
        // })

        const newLine0 = createLine('Pared_A','A',startVertex,startVertex + 1,[10,12,14,17,null,null,29,null])
        sceneActions.addLine(newLine0)
        const newLine1= createLine('Pared_B','B',startVertex + 1,startVertex + 2,[10,12,14,17,null,null,29,null])
        sceneActions.addLine(newLine1)
        const newLine2 = createLine('Pared_C','C',startVertex + 2,startVertex + 3,[10,12,14,17,null,null,29,null])
        sceneActions.addLine(newLine2)
        const newLine3 = createLine('Pared_D','D',startVertex + 3,startVertex,[10,12,14,17,null,null,29,null])
        sceneActions.addLine(newLine3)

        const newRoom = createRoom('room','Room',10,[
            newLine0.id,
            newLine1.id,
            newLine2.id,
            newLine3.id
        ])

        console.log('AFTER AREAS',newRoom)
  
        sceneActions.addRoom(null,newRoom)
    } 

    return <div className='q-widebtns'>
    {sceneActions.selected}
        {areas.map((area,i) => <AreaInput key={i} area={area}/>)}
        <div style={{textAlign:'right'}}>
        <Button shape="round" type="primary" style={{width:'200px',height:'50px'}} onClick={handleAddRoom}>Add room +</Button>
        </div>
    </div>
}

export default Rooms

const AreaInput = ({area}) => {
    const projectActions = useContext(ProjectContext)
    const sceneActions = useContext(SceneContext)

    const [vertices, setVertices] = useState([])
    const [edit, setEdit] = useState(false)

    const [areaData, setAreaData] = useState({
        label:area.label,
        name:area.name,
        room:area.features[0],
        area:area.floor.measurements.area
    })

    const [label, setLabel] = useState()

    useEffect(() => {
        const layer = projectActions.scenes[0].layers[0]
        setVertices(() => layer.vertices);
        setAreaData({
            label:area.label,
            name:area.label,
            room:area.features[0],
            area:area.floor.measurements.area
        })
    },[sceneActions.refresh,area])

    const handleRemoveRoom = (areaid) => {
        console.log('removeRoom',areaid)
        sceneActions.removeRoom(areaid)
    }

    const handleEditRoom = (id) => {
        console.log('EDIT',id)
        sceneActions.setActiveRoom(id)
        projectActions.setModal('c-35')
    }

    const handleSaveRoom = () => {
        if(areaData.name !== '' && areaData.label !== '' && areaData.area !== ''){
            sceneActions.setProperty('all','name',areaData.name,null,null,area.id)
            sceneActions.setProperty('all','label',areaData.label,null,null,area.id)
            sceneActions.setFeatureOfAll('all',0,area.id,0,areaData.room)
            sceneActions.setAreaScale('all',0,area.id,areaData.area)
            message.info({
                content: 'Setting room property',
                key,
            });                    
        } else {
            message.error({
                content: projectActions.messages.fillAllFields,
                key,
            });
        }
        setEdit(false)
        sceneActions.setRefresh(!sceneActions.refresh)
    }

    const description = "This operation cannot be undone. Are You sure to delete room?";

    const inputCss = edit === false ? {border:'2px solid #0000',background:'none'} : {}
    const disable = edit === true

    const lines = projectActions.scenes[0].layers[0].lines.filter(l => area.lines.includes(l.id))

    return <div className='q-widebtn'><div style={{display:'flex',alignItems:'center',gap:'1em'}}>
            <MiniFloorPlan width="60" height="60" vertices={vertices} areaid={[area.id]}/>
            <label>Label: <input disabled={!disable} style={inputCss} value={areaData.label} onChange={(e) => setAreaData({...areaData,label:e.target.value})}/></label>
            <label>Name: <input disabled={!disable} style={inputCss} value={areaData.name} onChange={(e) => setAreaData({...areaData,name:e.target.value})}/></label>
        </div>
        <div>
            {lines.length > 0 && lines.map((l,j) => {
                return <div key={j} style={{minWidth:'150px'}}>{l.name} ({l.label}) : {l.measurements.length.toFixed(2)}m</div>
            })}
        </div>
        <div className='flex items-center gap-3'>
        <label>Room type: {areaData.room}<br/>
        <select defaultValue={areaData.room} disabled={!disable} style={{...inputCss,padding:'10px'}} onChange={(e) => setAreaData({...areaData,room:e.target.value})}>
            {projectActions.calcData.rooms.map((room,i) => {
                return <option key={i} value={room.id}>{room.name}</option>
            })}
        </select></label>
        <label>Area:<br/> <input style={{...inputCss,width:'100px'}} disabled={!disable} value={areaData.area} onChange={(e) => setAreaData({...areaData,area:e.target.value})}/></label>
        {/* <AreaInput area={area}/> */}
        {edit === true ?
            <Button
                shape="round"
                type="primary"
                title="Edit result"
                onClick={handleSaveRoom} 
            ><FiSave size={17} /></Button> : 
            <Button
                shape="round"
                type="primary"
                title="Edit result" 
                onClick={() => setEdit(true)}
            ><FiEdit size={17} /></Button> 
        }
        <Button
            shape="round"
            type="primary"
            name={area.id} 
            data-areaid={area.id} 
            onClick={() => handleEditRoom(area.id)}
        >Edit layout</Button>
        <Popconfirm
            placement="topRight"
            title="Are You sure to Delete a room with all its content?"
            description={description}
            onConfirm={() => handleRemoveRoom(area.id)}
            okText="Si"
            cancelText="No"
        >
            <Button shape="round" type="danger">-</Button>
        </Popconfirm>
        </div>
    </div>
}