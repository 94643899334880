import React, { Fragment, useEffect, useContext, useState } from 'react'
import { CatmullRomCurve3 } from 'three'
import ProjectContext from '../../context/ProjectContext'
import SceneContext from '../../context/SceneContext'
import CalcContext from '../../context/CalcContext'
import {getIdfromSelected, getNameFromId, getOldValue, getFilteredElements, getElementOfId } from '../../utils/sceneUtils'
import { BsArrowRightCircle, BsArrowLeftCircle } from 'react-icons/bs'
import Pricetag from './Pricetag'
import SelectorInput from './SelectorInput'
import MaterialSelector from './MaterialSelector'
import ModelSelector from './ModelSelector'


const FeatureInput = ({table='integratedTable',action,elementData,property}) => {

  const projectActions = useContext(ProjectContext)
  const sceneActions = useContext(SceneContext)
  const calcActions = useContext(CalcContext)

  const [layer, setLayer] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [ imgId, setImgId ] = useState(0)
  const [material, setMaterial] = useState(null)
  const [ combinedName, setCombinedName ] = useState('')

  useEffect(() => {
    setLayer(projectActions.scenes[table === 'integratedTable' ? 1 : 0].layers[0])
    // console.log(property)
  },[refresh,table])


  useEffect(() => {
    setRefresh(!refresh)
    // console.log('ELEMENT',elementData)
  },[sceneActions.selected])


  const handleAction = (e) => {

    const { dataset,value } = e.target
    console.log('IN ACTION',dataset,value)

    if(dataset.feature){
      const aFeature = dataset.feature.split(',')

      const combinedName = aFeature.map(f => {

      })
    }

    action(e)
    setRefresh(!refresh)
  }

  const handleModelAction = (value,id_element,groupId) => {
    // console.log('MODEL ',value,itemId,id_element)
    if(groupId){
        sceneActions.setGroupFeature('mScene',17,parseInt(value),groupId,parseInt(id_element))
    } else {
        sceneActions.setFeature('mScene',17,parseInt(value),parseInt(id_element),[],sceneActions.selected)
    }
    calcActions.makeCalculations();
    calcActions.makeResults();
    projectActions.setRefresh(!projectActions.refresh)
  }

  const handleMaterialAction = (value,id_element,groupId) => {
      console.log('MAT ',value,sceneActions.selected,id_element)
      setMaterial(value)
      if(groupId){
          sceneActions.setGroupFeature('mScene',3,parseInt(value),groupId,parseInt(id_element))
      } else {
          sceneActions.setFeature('mScene',3,parseInt(value),parseInt(id_element),[],sceneActions.selected)
      }
      calcActions.makeCalculations();
      calcActions.makeResults();
      projectActions.setRefresh(!projectActions.refresh)
  }

  const handleNext = () => {
    setImgId(imgId + 1)
  }

  const handlePrev = () => {
    setImgId(imgId - 1)
  }

  const inp = () => {
    switch(property.type){
      case 'select':
        return <>{elementData.properties && elementData.properties.filter(proper => {
            return !proper.exclude
          }).filter(pr => {
            let testProp = true;

            if(property.properties && property.properties.length > 0){
              testProp = property.properties.includes(pr.name);
            }

            return testProp
          }).map((pr,i) => {

            console.log(pr)

            const id = getIdfromSelected(layer,sceneActions.selected) 

            let oldValue = getOldValue(layer,null,elementData.id_element,pr.feature,id)

            const valueData = pr.values.find(v => v.value === oldValue)

            let feature = pr.feature

            if(valueData && valueData.connect){
              valueData.connect.forEach((conn,i) => {
                feature += ',' + conn.f
                oldValue += ',' + conn.v
              })
            }
 
            // console.log('OLD VALUE',table,oldValue,valueData)           
            
            return <Fragment key={i}><label className="q-prop" key={i}>
              <select 
                onChange={handleAction} 
                data-idelement={elementData.id_element}
                data-table={table}
                data-feature={feature}
                data-id={id}
                value={oldValue && oldValue !== [] ? oldValue : 0}
              >
                {id ? <option key={i} value={0}>---make a choice---</option> : <option key={i} value={null}>---different values---</option>}
                {pr.values.filter(val => {
                  let testFilter = [];

                  if(val.filter && val.filter.length > 0){
                    const items = getFilteredElements(layer,null,elementData.id_element)    

                    let testAll = true

                    if(items && items.length > 0 && id === null){
                      testAll = items.every(it => {
                        return elementData.id_element === 1 ? it.features[pr.feature] === oldValue : it.features[pr.feature] === oldValue 
                      })

                      if(testAll === true){
                        val.filter.forEach(filt => {
                          {/* console.log('checking-----',filt.v) */}
                          testFilter.push(filt.v.some(f => {
                            if(elementData.id_element === 1){
                              return items[0].features[filt.f] === f
                            } else {
                              return items[0].features[filt.f] === f
                            }
                          }))
                        }) 
                      }    
                    }

                    if(id !== null){
                      const foundElement = getElementOfId(layer,null,id)
                      {/* console.log('IN COVERINGS',sceneActions.selected,foundElement) */}
                      val.filter.forEach(filt => {
                        {/* console.log('checking-----',filt) */}
                        testFilter.push(filt.v.some(f => {
                          if(foundElement.id_element === 1){
                            return foundElement.features[filt.f] === f
                          } else {
                            return foundElement.features[filt.f] === f
                          }
                        }))
                      })                       
                    }                    
                  }

                  return testFilter.every(test => test === true);
              }).map((v,j) => {
                let value = v.value
                if(table !== 'initialTable' && v.connect){
                  {/* console.log('CONNECT',oldValue,valueData.connect) */}
                  v.connect.forEach(conn => {
                    value += ',' + conn.v
                  })
                }
                return <option key={j} value={value}>{v.label}</option>
              })}
              </select></label>
              </Fragment>
          })}
          </>
      case 'covering':
          return <>{elementData.properties && elementData.properties.filter(proper => {
            return !proper.exclude
          }).filter(pr => {
            let testProp = true;
            if(property.properties && property.properties.length > 0){
              testProp = property.properties.includes(pr.name);
            }
            return testProp
          }).map((pr,i) => {
            const id = getIdfromSelected(layer,sceneActions.selected) 
            let oldValue = getOldValue(layer,null,elementData.id_element,pr.feature,id,sceneActions.selected)
            let feature = pr.feature
            const valueData = pr.values.find(v => v.value === oldValue)
            return <select
                key={i}
                onChange={handleAction} 
                data-idelement={elementData.id_element}
                data-table={table}
                data-feature={feature}
                data-id={id}
                value={oldValue && oldValue !== [] ? oldValue : 0}
              >
                {id ? <option key={i} value={0}>---make a choice---</option> : <option key={i} value={null}>---different values---</option>}
                {pr.values.filter(val => {
                  let testFilter = [];

                  if(val.filter && val.filter.length > 0){
                    const items = getFilteredElements(layer,null,elementData.id_element)    

                    let testAll = true

                    if(items && items.length > 0 && id === null){
                      testAll = items.every(it => {
                        return elementData.id_element === 1 ? it.features[pr.feature] === oldValue : it.features[pr.feature] === oldValue 
                      })

                      if(testAll === true){
                        val.filter.forEach(filt => {
                          {/* console.log('checking-----',filt.v) */}
                          testFilter.push(filt.v.some(f => {
                            if(elementData.id_element === 1){
                              return items[0].features[filt.f] === f
                            } else {
                              return items[0].features[filt.f] === f
                            }
                          }))
                        }) 
                      }    
                    }

                    if(id !== null){
                      const foundElement = getElementOfId(layer,null,id)
                      console.log('IN COVERINGS',sceneActions.selected,foundElement)
                      val.filter.forEach(filt => {
                        {/* console.log('checking-----',filt) */}
                        testFilter.push(filt.v.some(f => {
                          if(foundElement.id_element === 1){
                            return foundElement.features[filt.f] === f
                          } else {
                            return foundElement.features[filt.f] === f
                          }
                        }))
                      })                       
                    }                    
                  }

                  return testFilter.every(test => test === true);
              }).map((v,j) => {
                return <option key={j} data-name={v.name} value={v.value}>{v.label}</option>
              })}
            </select>
            })
          }</>
      case 'radio':
        return <div className="radios">{elementData.properties && elementData.properties.filter(proper => {
          return !proper.exclude
        }).map((pr,i) => {
          return pr.values.map((v,j) => {

            let feature = pr.feature.toString()

            if(v.connect){
              v.connect.forEach((conn,i) => {
                feature += ',' + conn.f
                {/* oldValue += ',' + conn.v */}
              })
            }

            {/* let oldValue = getOldValue(layer,null,elementData.id_element,pr.feature) */}
            const id = getIdfromSelected(layer,sceneActions.selected) 
            let oldValue = getOldValue(layer,null,elementData.id_element,pr.feature,id,sceneActions.selected)

            return <label key={'r_' + i + '_' + j}><input 
              style={{background:'green',maxWidth:'20px'}}
              onChange={handleAction}
              data-idelement={elementData.id_element}
              data-table={table}
              checked={oldValue == v.value}
              data-feature={feature}
              type='radio' name={'r_' + i} 
              value={v.value} 
          />{v.label} {v.superscript && <sup>{v.superscript}</sup>} {table}</label>})})}
        </div>
      case 'radioOption':   
        return property && <div className='radios'>
          {property.actions && property.actions.map((action,i) => {
            let oldValue = 1;
            return <label key={i}>
              <input 
                style={{background:'green',maxWidth:'20px'}}
                onChange={handleAction}
                data-idelement={elementData.id_element}
                data-table={table}
                // checked={oldValue == action.v}
                data-area={action.area}
                data-feature={action.f}
                type='radio' name={property.id} 
                value={action.v} 
              />
              {action.label}
            </label>
          })}
        </div>  
      case 'selector':
        return <SelectorInput table={table} o={property} itemId={sceneActions.selected}/>
      case 'materialSelector':
        return <MaterialSelector
          option={property}
          action={handleMaterialAction}
          localStyle={0} 
          localSubstyle={0}
          material={material}
          itemId={sceneActions.selected}
      /> 
      case 'modelSelector':       
          return <ModelSelector 
          option={property}
          action={handleModelAction}
          localStyle={0} 
          localSubstyle={0}
          material={material}
          itemId={sceneActions.selected}
      />    
      case 'input':
        return <input placeholder={property.placeholder ?? ''}/>   
      default:
        return <div>{property.label}-{property.type}</div>
    }
  }

  const lab = () => {
    if(sceneActions.selected !== null){

      // console.log(getLabelFromId(layer,sceneActions.selected))

      return getNameFromId(layer,sceneActions.selected)
    } else {
      return property.label
    }
  }

  return (
    <div className="q-property" style={{display:'flex',flexDirection:'column',alignContent:'flex-end'}}>
      <div className='q-title' style={{fontSize:'1.3em',textDecoration:'underline'}}>{property.toggle ? lab() : property.label}</div>
      {inp()}

      {/* <Pricetag combinedName={''}/> */}
    </div>
  )
}

export default FeatureInput