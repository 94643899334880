import { useState, useContext, useEffect } from 'react'
import { Collapse, Select, Radio } from 'antd';
import ProjectContext from '../../context/ProjectContext'
import SceneContext from '../../context/SceneContext'
import CalcContext from '../../context/CalcContext'
import { getThumb } from '../../utils/formatUtils'
import { AiOutlineCheck } from 'react-icons/ai'

const StyleSelector = ({q, styles}) => {
    const projectActions = useContext(ProjectContext)
    const sceneActions = useContext(SceneContext)
    const calcActions = useContext(CalcContext)

    const { Panel } = Collapse;
    const { Option } = Select;

    const [activeStyle, setStyle] = useState(projectActions.project.defaults.style ?? 0)
    const [activeSubstyle, setSubstyle] = useState(projectActions.project.defaults.substyle ?? 0)

    useEffect(() => {
        setStyle(() => projectActions.project.defaults.style)
        setSubstyle(() => projectActions.project.defaults.substyle)
    },[projectActions.project.defaults.style,projectActions.project.defaults.substyle])

    const handleChange = ({ target: { value } }) => {
        console.log('change style', value)
        projectActions.setDefault('style',value)
        projectActions.setDefault('substyle',0)
        sceneActions.setFeatureOfAll({scene:'mScene',feature:23,value:projectActions.project.defaults.style})
          .then(scenes => {
            calcActions.makeCalculations();
            calcActions.makeResults();
            // projectActions.saveProject(projectActions.project.projectId,projectActions.project.name)
          })
    }

    const handleChangeSubstyle = (j,i) => {
        projectActions.setDefault('substyle',j)
        projectActions.setDefault('style',i)
        sceneActions.setRefresh(!sceneActions.refresh);
    }

return (
    <div style={{width:'350px'}}>
          <div className='c-title' style={{marginLeft:'12px'}}>Estilo</div>
          <div className='c-subtitle' style={{fontSize:'10px',marginBottom:'1em',marginLeft:'1em'}}>Elige el estilo que desees {activeStyle} {activeSubstyle}</div>
            <Collapse
                defaultActiveKey={[activeStyle]}
                ghost
                accordion
            >
          {styles.map((styleData,i) => {
            return styleData && <Panel key={i} header={<><Radio value={i} onChange={handleChange} checked={activeStyle === i}/>{styleData.label}</>} showArrow={false}>
                <div  style={{marginLeft:'1.5em'}}>
                <label>
                <div style={{display:'flex',flexDirection:'column'}}>
                    {/* <input type="radio" name="style"/>             */}
                    {styleData.description && <div style={{fontSize:'10px',marginBottom:'1em'}}>{styleData.description}</div>}
                </div>        
                </label>
                <div>
                    <div style={{marginBottom:'.5em'}}>Selecciona subestilo</div>
                    <div className='s-thumbs' style={{flexWrap:'wrap'}}>
                        {styles[i] && styles[i].substyles.map((thumb,j) => <div key={j}
                            onClick={() => handleChangeSubstyle(j,i)}
                            style={{
                                backgroundImage:`url(${process.env.PUBLIC_URL}'/icons/preloader.gif')`,
                                backgroundSize:'200px',
                                flex:'0 0 45%',
                                backgroundPosition:'center', 
                                backgroundRepeat:'no-repeat'  ,
                                position:'relative',  
                                border:j === activeSubstyle && i === activeStyle ? '2px solid #007DFF' : '2px solid white'
                                
                            }}
                            ><div style={{
                                display:'flex',
                                alignItems:'flex-end',
                                gap:'1em',
                                cursor:'pointer',
                                backgroundImage:`url(${process.env.PUBLIC_URL}${getThumb(thumb.url)})`,
                                backgroundSize:'cover',
                                backgroundPosition:'center', 
                                backgroundRepeat:'no-repeat',
                                borderRadius:'.5em',
                                height:'100%',
                            }}
                        ><div style={{
                            padding:'10px 10px',
                background:'linear-gradient(360deg, rgba(21, 19, 19, 0.7) 0%, rgba(21, 19, 19, 0) 100%)',
                width:'100%'
                        }}>{thumb.label}</div></div>
                            {j === activeSubstyle && i === activeStyle && <div
                            style={{
                                position:'absolute',
                                top:'10px',
                                right:'10px',
                                background:'#007DFF',
                                width:'20px',
                                height:'20px',
                                borderRadius:'10px',
                                display:'flex',
                                justifyContent:'center',
                                alignItems:'center'
                            }}>
                            <AiOutlineCheck /></div>}
                        </div>)}
                    </div>
                </div>
                </div>
            </Panel>
          })}
          </Collapse>
        </div>
        )
    }

export default StyleSelector