import { useEffect, useState, useRef, useContext } from 'react'
// import PuffLoader from "react-spinners/PuffLoader";
import { motion } from 'framer-motion'
import Overlay from './Overlay'
// import Loader from '../../assets/ui/preloader.gif'
import { getElementsFromLayer } from '../../utils/sceneUtils'
import { getCharFromIndex } from '../../utils/formatUtils'
import SceneContext from '../../context/SceneContext'
import { BsCheck2, BsPlus, BsX} from 'react-icons/bs'


const Carousel = ({layer, id_element, feature, action, values, width=800, border=null}) => {

  const sceneActions = useContext(SceneContext)

  const [carouselWidth, setCarouselWidth] = useState(0)
  const [over, setOver] = useState(null)
  const [moved, setMoved] = useState(false)
  const [featured, setFeatured] = useState([])
  const [elements,setElements] = useState([])

  const carousel = useRef();

  useEffect(() => {
    if(values){
      setCarouselWidth(values.length * 200 - 400)
      setElements(getElementsFromLayer(layer,id_element))
    }
  },[values])

  const zoomImg = () => {
    console.log('ZOOM')
  }

  const handleClick = () => {
    console.log('click')
    if(moved === false){
    }
  }

  const handleMove = (e) => {
    // e.preventDefault();
    // setMoved(true);
    
    
    // let timeout;
    // (() => {
    //   clearTimeout(timeout);
    //   timeout = setTimeout(() => setMoved(false), 100);
    // })();
    // console.log('move',moved)
  }

  const handleDragStart = () => {
    console.log('Drag start')
  }

  const handleDragEnd = () => {
    console.log('Drag End')
  }

  const handleDrag = () => {
    console.log('Dragging')
  }

  const handlePan = () => {
    console.log('Panning')
  }

  const handleVis = (i) => {
    action(2,values[i])
  }

  const handleOver = (i) => {
    setOver(i)
  }

  const handleOut = () => {
    setOver(null)
  }

  const checkSelection = (o) => {
   
    const filtered = elements.filter(el => {
      if(id_element === 1){
        return el.features[feature] === o.value
      } else {
        return el.features[feature] === o.value
      }
    })

    return filtered
  }

  return (
    <motion.div ref={carousel} 
      onMouseMove={handleMove} 
      style={{
        width:`${width}px`,
        overflow:'hidden',
      }}>
      <motion.div 
        drag="x"
        dragConstraints={{ right:0, left:-carouselWidth}} 
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        whileDrag={handleDrag}
        onPan={handlePan}
        className="m-carousel"
        style={{

        }}
      >
        {values && values.map((o,i) => {
          const filtered = checkSelection(o)

          const checked = filtered.length > 0

          return <div key={i} className="carousel-thumb" style={{
              background:`url(${process.env.PUBLIC_URL}'/icons/preloader.gif')`,
              backgroundSize:'100px',
              backgroundPosition:'center', 
              backgroundRepeat:'no-repeat'
            }}><label>
            <div style={{
              background:'url(' + process.env.PUBLIC_URL + o.img[0] + ')',
              // background:'url(`' + process.env.PUBLIC_URL + '/icons/preloader.gif' + '`)',
              width:'200px',
              height:'200px',
              backgroundSize:'cover',
              cursor:'e-resize',
              display:'flex',
              flexDirection:'column',
              justifyContent:'space-between',
              border:checked === true ? '3px solid #04073F' : 'none'
            }}
              // onMouseOver={() => setOver(i)}
              // onMouseOut={() => setOver(null)}
            >                
            {/* {cardChck === true && <div style={{width:'200px',height:'100px'}}><Overlay /></div>} */}          
            {/* <input type="radio" className='debug' 
              checked={cardChck} 
              data-oid={o.id}
              data-option={i} 
              data-link={q.link} 
              name={id} value={i} 
              onChange={action}/>
            </div>
            <div className="q-slide-bottom">
              {o.description && <div className="q-description">{o.description}</div>}*/}
              {o.label && <div className="q-slide-title" style={{paddingLeft:'.3em',background:'#fff5'}}>{o.label} <span id={'zoom_' + i} className='q-link' onClick={() => handleVis(i)}> &#x279C;</span>
              {/* <input type="checkbox" className='debug' onChange={action} onClick={handleClick}/> */}
              </div>}
              <div style={{position:'relative'}}>

              {id_element === 1 && filtered.length > 0 && <div className='left bottom'>
                {filtered.map((el,j) => {
                  return <div className='small blue' key={j}>Pared {el.label}</div>
                })}
              </div>}
              <div className='expandable right bottom' onMouseOver={handleOver} onMouseLeave={handleOut}>
                {filtered.length > 0
                  && <span className="btncheck on"><BsCheck2 size="20px"/><span className="following">Elegido</span></span>}
                  {/* <span className="btncheck off" onClick={() => handleClick()}><BsPlus size="20px"/><span className="following">Elección</span></span>  */}
                
              </div>
              </div>
            </div></label> 
          </div>
        })} 
      </motion.div>
    </motion.div>
  )
}

export default Carousel