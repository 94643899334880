import { useRef, useEffect, useState, useContext } from 'react'
import ProjectContext from '../../context/ProjectContext'
import SceneContext from '../../context/SceneContext'
import QnrContext from '../../context/QnrContext'
import areapolygon from 'area-polygon';
import {
    midPoint,
    getOffset,
    verticesMidPoint,
    verticesDistance,
    roundRect,
    getPolygonCentroid,
    getScale,
    getOffset2
  } from '../../utils/geometry'
  import { COLORS } from '../../utils/constants'
import { getAreaVertices, getElementData, getElementOfId } from '../../utils/sceneUtils';
import { BsFileBreakFill } from 'react-icons/bs';

const Canvas = (props) => {

    const colors = COLORS.planner

    const projectActions = useContext(ProjectContext)
    const sceneActions = useContext(SceneContext)
    const qnrActions = useContext(QnrContext)
  
    const canvasRef = useRef(null)

    const [shapes, setShapes] = useState([])
    const [pos, setPos] = useState([])
    const [active, setActive] = useState(null)
    const [start, setStart] = useState(null)
    const [highlight, setHighlight] = useState(null)
    const [scale, setScale] = useState(100)

    const labels = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","R","S","T"]
  
    const draw = (ctx) => {

        let activeElements = []

        if(ctx){
            const layer = projectActions.scenes[0].layers[0]
            const vertices = layer.vertices;
            const areas = layer.areas
            const lines = layer.lines
            const groups = layer.groups 

            const scale = getScale(ctx,vertices)

            const offset = getOffset2(ctx,vertices,scale)
            // const offset = {x:100,y:10}
            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)

            setShapes([])

            areas.forEach((area,i) => {
                const areaVertices = getAreaVertices(layer,area.id)
                if(i < 100){
                    // const newArea = new Path2D();
                    // var poly=[ 5,5, 100,50, 50,100, 10,90 ];
                    ctx.fillStyle = 'rgba(255, 0, 0, 0.3)';
                    ctx.beginPath();
                    
                    // console.log('IN DRAW',areaVertices)

                    areaVertices.forEach((vs,j) => {
                        // console.log('DRAWING:',j,vs)
                        if(j === 0){
                            ctx.moveTo(vs.x * scale, vs.y * scale + offset.y);
                            // ctx.lineTo(vs[1].x * scale, vs[1].y * scale + offset.y)
                        } else {
                            ctx.lineTo(vs.x * scale, vs.y * scale + offset.y)
                            // ctx.lineTo(vs[1].x * scale, vs[1].y * scale + offset.y)
                        }
                    })
                    ctx.closePath();
                    // ctx.fill()
                }
                const polygon = areaVertices.map(v => [v.x,v.y])

                let areaSize = areapolygon(polygon, false);
                const center = getPolygonCentroid(areaVertices);

                ctx.fillStyle = "#007DFF";
                ctx.fillText(area.label, center.x * scale + offset.x, center.y * scale + offset.y);            
                ctx.fillText(Math.round(areaSize * 100) / 100 + ' m2', center.x * scale + offset.x, center.y * scale + offset.y + 15);            
            })

            if(lines && vertices){
                lines.forEach((e,i) => {
                    const v1 = {x:vertices[e.v1].x * scale + offset.x, y:vertices[e.v1].y * scale + offset.y}
                    const v2 = {x:vertices[e.v2].x * scale + offset.x, y:vertices[e.v2].y * scale + offset.y}
                    // const v1 = {x:vertices[e.v1].x * scale, y:vertices[e.v1].y * scale + offset.y}
                    // const v2 = {x:vertices[e.v2].x * scale, y:vertices[e.v2].y * scale + offset.y}

                    let labelText = labels[i];

                    const line = new Path2D();
                    let grouped = false;
                    let selected = false;

                    if(props.q && props.q.highlight){
                        let foundElement = null;
                        switch(props.q.highlight){
                            case 'wall':
                                foundElement = getElementOfId(layer,null,props.itemId);
                            break;
                            case 'group':
                                const group = groups.find(gr => gr.id === props.itemId)
                                grouped = group ? group.items.includes(e.id) : null;
                            break;
                            default:
                                foundElement = getElementOfId(layer,null,props.itemId);
                            }
                            console.log('---FOUND------',props.itemId)
                            if(foundElement && foundElement.id === e.id){
                                grouped = foundElement
                            }
                    } else {
                        // if(groups && groups.length > 0){
                        //     // console.log(groups)
                        //     grouped = groups.find(gr => gr.id === 'ducha').items.includes(e.id)                           
                        // }                        
                    }

                    //lines
                    ctx.strokeStyle = grouped ? colors.lineActive : colors.line;
                    ctx.lineWidth = 3;
                    ctx.beginPath();
                    line.moveTo( v1.x, v1.y);
                    line.lineTo( v2.x, v2.y);
                    ctx.closePath();      // go back to point 1
                    ctx.stroke(line);

                    let act = false;

                    switch(props.plannermode){
                        case 'dimensions':
                            act = selected !== null && selected.name === "line_" + i
                        break;
                        case 'wallsfloor':
                            act = sceneActions.selected === "line_" + i
                        break;
                        case 'wallgroups':
                            // console.log('checking',sceneActions.active,act,sceneActions.active === e.id)
                            act = sceneActions.active === e.id
                        break;
                        default:
                            
                    }

                    if(
                        props.plannermode === 'dimensions' && 
                        e.label || props.plannermode === 'wallsfloor'
                        || props.plannermode === 'wallgroups'
                    ){
                        const label = new Path2D()
                            ctx.fillStyle = colors.label;
                            const size = 30;
                            const mp = midPoint(v1.x,v1.y,v2.x,v2.y);
                            roundRect(ctx, mp.x - size/2 , mp.y - size/2, size, size, {tl: 3, tr: 3, br: 3, bl: 3}, true, false);

                        const rect = new Path2D();
                            rect.rect(mp.x - size/2 + 1,mp.y - size/2 + 1,size - 2,size - 2);
                            activeElements.push({"name":"line_" + i,"shape":rect})
                            ctx.fillStyle = act === true ? 'white' : grouped ? colors.labelSelected : colors.label;               
                            ctx.fill(rect);

                        ctx.font = "15px Arial";
                        ctx.fillStyle = "#FFFFFF";
                        if(act === true){
                            ctx.fillStyle = colors.label;
                        }
                        ctx.textAlign = "center";
                        ctx.fillText(e.label ? e.label : labels[i], mp.x, mp.y+5);
                        // ctx.fillText(labelText, mp.x, mp.y+5);
                        // ctx.fillText(i, mp.x, mp.y+5);
                    }

                })

                // const circle = new Path2D();
                // circle.arc(50, 50, 20, 0, 2 * Math.PI);
                // ctx.fillStyle = active !== null && active.name === "circle" ? 'green' : 'red';
                // ctx.fill(circle);

                // const floor = new Path2D()
                // ctx.fillStyle = active !== null && active.name === "floor" ? 'green' : 'red';
                // ctx.beginPath();
                // vertices.forEach((v,j) => {
                //     console.log(v)
                //     if(j === 0){
                //         ctx.moveTo(0,0);
                //     } else {
                //         ctx.lineTo(v.x,v.y);
                //     }
                // })
                // ctx.moveTo(300, 20);
                // ctx.lineTo(400,20);
                // ctx.lineTo(400, 100);
                // ctx.lineTo(300, 100);
                // ctx.closePath(floor);
                // ctx.fill();               
                // ctx.stroke(floor);           

                // const rect = new Path2D();
                // rect.rect(500,400,100,100);
                // ctx.fillStyle = active !== null && active.name === "rect" ? 'green' : 'red';
                // ctx.fill(rect);

/*                 const polygon = vertices.map(v => [v.x,v.y])

                let areaSize = areapolygon(polygon, false);
                const center = getPolygonCentroid(vertices);
                ctx.fillStyle = "#007DFF";
                ctx.fillText(Math.round(areaSize * 100) / 100 + ' m2', center.x * 100 + offset.x, center.y * 100 + offset.y);
 */
                // activeElements.push({"name":"floor","shape":floor})
                // activeElements.push({"name":"circle","shape":circle})
                // activeElements.push({"name":"rect","shape":rect})
            }

            // vertices.forEach((v,i) => {
            //     ctx.font = "13px Arial";
            //     ctx.fillStyle = "#000000";
            //     ctx.textAlign = "center";
            //     ctx.fillText(i, v.x * scale + 10, v.y * scale + 25);
            // })
            
            setShapes(activeElements)
        }
    }
    
    const checkSelected = (x,y) => {
        if((pos.x > x && pos.x < (x + 30)) && (pos.y > y && pos.y < (y + 30))){
            return true
        } else {
            return false
        }
    }

    const getElementAtPosition = (pos) => {

        let activeObject = null;

        const canvas = canvasRef.current
        const ctx = canvas.getContext('2d')
        activeObject = shapes.filter((s,i) => {
          if(ctx.isPointInStroke(s.shape, pos.x, pos.y)){
            // console.log('STROKE',s,i,shapes)
            return s
          }
          if(ctx.isPointInPath(s.shape, pos.x, pos.y)){
            // console.log('PATH',s,i,shapes)
            return s
          }
        })
    
        if(activeObject.length > 0){
          setActive(activeObject[0])
        } else {
          setActive(null)
        }
    }

    useEffect(() => {
        
        const canvas = canvasRef.current
        const context = canvas.getContext('2d')
        
        // console.log('CANVAS REF',projectActions.scenes,props.plannermode)

        //Our draw come here
        // setVertices(scene.scene.layers[0].vertices)
        // setElements(scene.scene.layers[0].lines)


        draw(context)
        
    }, [projectActions.scenes,pos,sceneActions.active,sceneActions.refresh])
    

    const handleMouseMove = (e) => {
        const bound = canvasRef.current.getBoundingClientRect()

        getElementAtPosition({x:Math.round(e.clientX - bound.left),y:Math.round(e.clientY - bound.top)})

        const position = {x:Math.round(e.clientX - bound.left),y:Math.round(e.clientY - bound.top)}

        setPos(position)

        if(props.plannermode === 'dimensions' && active !== null && start !== null){
            sceneActions.shiftActive(active.name,start,position)
        }
    }

    const handleMouseDown = (e) => {
        console.log('MOUSE DOWN',active)
        if(props.plannermode === 'dimensions' && active !== null){
            const bound = canvasRef.current.getBoundingClientRect()
            setStart({x:Math.round(e.clientX - bound.left),y:Math.round(e.clientY - bound.top)})
        }
    }

    const handleMouseUp = (e) => {
        if(active !== null){
            sceneActions.setSelected(active.name)
        } else {
            sceneActions.setSelected(null)
        }

        if(props.plannermode === 'dimensions'){
            setStart(null)
        }
    }

    const handleDragOver = (e) => {
        e.preventDefault();
        // console.log('drag over ',e.target)
        if(sceneActions.over === null){
            sceneActions.setOver('canvas')
        }
    }

    const handleDragLeave = (e) => {
        // console.log('drag leave ',e.target)
        sceneActions.setOver(null)
    }

    const handleDrop = (e) => {
        const option = JSON.parse(e.dataTransfer.getData('o'))
        console.log('drop ',option,projectActions.elementsData)
        
        if(option.id_element){
            const elementData = getElementData(projectActions.elementsData,option.id_element)
            
        }
    }

    return <canvas 
        style={{width:'100%',height:'100%'}}
        className='rc-shapeCanvas' 
        onDrop={handleDrop}
        onDragOver={handleDragOver} 
        onDragLeave={handleDragLeave} 
        onMouseMove={handleMouseMove} 
        onMouseUp={handleMouseUp} 
        onMouseDown={handleMouseDown} 
        ref={canvasRef} {...props}
    />
}

export default Canvas