import { useContext } from 'react'
import { getElementData } from '../../utils/sceneUtils'
import ProjectContext from '../../context/ProjectContext'


const ElementsDock = ({q,handleDragStart,handleDrop}) => {

  const projectActions = useContext(ProjectContext)

  return (
    <div className="q-labels">
        {q.options.map((o,i) => {
          const elementData = getElementData(projectActions.elementsData,o.id_element,o.typology)

          return <div 
            key={i}
            draggable={true}
            data-option={i}
            onDragStart={handleDragStart}
            onDragEnd={handleDrop}
          >
            {o.img && <img draggable={false} className={"q-icon"} src={o.img} alt={o.label}/>}
            {(!o.img && elementData && elementData.icon) && <img draggable={false} className={"q-icon"} src={elementData.icon} alt={o.label ?? elementData.label}/>}
            <div style={{fontSize:'10px'}}>{o.label ?? elementData.label}</div>
          </div>
        })}
    </div>
  )
}

export default ElementsDock