import React, { useEffect, useState } from 'react'
import { getFeatureValue } from '../../utils/formatUtils'

const PropertyInput = ({action,property,item,typology=null,option=null,ignoreFilters}) => {

  const [val,setVal] = useState(null)
  const [refresh,setRefresh] = useState(null)

  useEffect(() => {
    const oldValue = getFeatureValue(item,property.feature)
    const propertyValue = property.values.find(val => val.value === oldValue)
    setVal(propertyValue ? parseInt(propertyValue.value) : null)
  },[refresh])

  const handleAction = (e) => {
    if(e.target.value){
      action(e)
      setRefresh(!refresh)
    }
  }

  const inp = () => {
    switch(property.type){
      case 'measure':
        return <label className='q-prop'>{property.label}<input className="num-small" type="number" onChange={action}/></label>
      case 'position':
        return <div className='q-prop'>
          {property.label}
          <label className='q-prop'>x:<input className="num-small" type="number"/></label>
          <label className='q-prop'>y:<input className="num-small" type="number"/></label>
        </div>
      case 'radio':
        return <div className="q-prop">
          {property.label && <div className="q-title">{property.label}</div>}
          {property.description && <div className="q-description">{property.description}</div>}
          {property.values.filter(val => {
            let testFilter = [];
            if(item && val.filter && val.filter.length > 0){
              {/* console.log('IN FILTER',filtered.filter) */}
              val.filter.forEach(filt => {
                {/* console.log(filt.v) */}
                if(ignoreFilters === filt.f){
                  testFilter.push(true)
                } else {
                  testFilter.push(filt.v.some(f => item.features[filt.f] === f))
                }
              })
            }
            return testFilter === [] || testFilter.every(fl => fl === true);
          }).map((value,i) => {
            // console.log('IN RADIO',val,value)
            return <label key={i}><input type="radio" 
              onChange={handleAction}
              name={item ? item.id + '_' + property.type : property.type}
              data-feature={property.feature} 
              data-itemid={item ? item.id : null}  
              data-idelement={item ? item.id_element : null}
              data-typology={item ? item.typology : typology}
              data-option={option}
              value={value.value}
              checked={val === value.value}
              style={{marginRight:'.5em'}}
            /> {value.label} {value.superscript && <div style={{marginLeft:'.5em',fontSize:'0.8em',height:'1.5em',fontStyle:'italic',background:'#C7DEFC',borderRadius:'3px',padding:'0 5px'}}>{value.superscript}</div>}</label>
          })}
        </div>
      case 'enum':
        return <label className='q-prop'>
          {property.label && <div className="q-title">{property.label}</div>}
          {property.description && <div className="q-description">{property.description}</div>}
          <span className='debug'>({property.feature})</span>
          <select 
            onChange={handleAction} 
            data-feature={property.feature} 
            data-itemid={item ? item.id : null}  
            data-idelement={item ? item.id_element : null}
            data-typology={item ? item.typology : typology}
            data-option={option}
            data-property={JSON.stringify(property)}
            value={property.feature !== ignoreFilters ? val : null}
          >
          <option value={null}>---seleccionar---</option> 
          {property.values.filter(val => {
            let testFilter = [];
            if(item && val.filter && val.filter.length > 0){
              {/* console.log('IN FILTER',filtered.filter) */}
              val.filter.forEach(filt => {
                {/* console.log(filt.v) */}
                if(ignoreFilters === filt.f){
                  testFilter.push(true)
                } else {
                  testFilter.push(filt.v.some(f => item.features[filt.f] === f))
                }
              })
            }
            {/* console.log('IN ENUM',val.filter,testFilter) */}
            return testFilter === [] || testFilter.every(fl => fl === true);
          }).map((option,i) => <option key={i} value={option.value}>{option.label}{option.superscript && <sup>{option.superscript}</sup>}</option>)}
          </select>
        </label>
      case 'connectedEnum':
        return <label className='q-prop'>
          {property.label && <div className="q-title">{property.label} ({property.feature})</div>}
          {property.description && <div className="q-description">{property.description}</div>}
          <select 
            onChange={handleAction} 
            data-feature={property.feature} 
            data-itemid={item ? item.id : null} 
            data-idelement={item ? item.id_element : null}
            data-typology={item ? item.typology : typology}
            data-option={option}
            data-property={JSON.stringify(property)}
            value={property.feature !== ignoreFilters ? val : null}
          >
          {property.values.filter(val => {
            let testFilter = [];
            if(item && val.filter && val.filter.length > 0){
              {/* console.log('IN FILTER',filtered.filter) */}
              val.filter.forEach(filt => {
                {/* console.log(filt.v) */}
                if(ignoreFilters === filt.f){
                  testFilter.push(true)
                } else {
                  testFilter.push(filt.v.some(f => item.features[filt.f] === f))
                }
              })
            }
            return testFilter === [] || testFilter.every(fl => fl === true);
          }).map((option,i) => <option key={i} value={option.value}>{option.label}{option.superscript && <sup>{option.superscript}</sup>} ({option.value})</option>)}
          </select>
        </label>
      case 'color':
        return <div className="q-colors">
          {property.label && <div className="q-title">{property.label}</div>}
          {property.description && <div className="q-description">{property.description}</div>}
          <div className='q-thumbs' >
          {property.values.filter(val => {
            let testFilter = [];
            if(item && val.filter && val.filter.length > 0){
              {/* console.log('IN FILTER',filtered.filter) */}
              val.filter.forEach(filt => {
                console.log(filt.v)
                testFilter.push(filt.v.some(f => item.features[filt.f] === f))
              })
            }
            return testFilter === [] || testFilter.every(fl => fl === true);
          }).map((color,i) => <label key={i}>
          <div style={{
            background:color.color ? color.color : 'url(' + color.thumb + ')',
            width:'1.5em',
            height:'1.5em',
            borderRadius:'50%',
            border:'1px solid #e0e0e0',
            margin:'.5em .5em .5em 0',
          }}
          ></div>{color.label && color.label}
          <input type="radio"
            className="debug"
            data-feature={property.feature} 
            data-itemid={item ? item.id : null} 
            data-idelement={item ? item.id_element : null} 
            data-typology={item ? item.typology : typology}
            data-option={option}
            name={item ? item.id + '_' + property.type : property.type} 
            value={color.value} 
            data-property={JSON.stringify(property)}
            onChange={handleAction}
            checked={val === color.value}
          />           
          </label>)}
          </div>
        </div>
      case 'hidden':
          return null
      default:
        return <div className="debug">{property.label}-{property.type}-</div>
    }
  }

  return (
    <div className="q-property"><form>{inp()}</form></div>
  )
}

export default PropertyInput