import areaPolygon from 'area-polygon'
import {v1, v4 as uuid } from 'uuid'
import {elementIdToName} from './formatUtils'
import { orderVertices, verticesDistance } from './geometry'
import { wrapNumber } from './math'

export const createNewElement = (elementData,option,pos) => {
  // console.log('Creating',elementData,option)
  const newElement = {
      id:uuid().slice(0,8),
      name:elementData.name,
      label:elementData.label,
      id_element:elementData.id_element,
      type:elementData.type ?? null,
      features:elementData.features ?? [],
      measurements:elementData.measurements,
      position:pos ?? {x:1,y:1},
      decisions:[],
  }  

  return newElement
}

export const createElement = (name = 'Element', label = 'Element', id_element = 8, type='', features, measurements = null,position,id = null,decisions = null) => {

  // console.log('IN Create element', name,label,id_element,type,features,measurements)

    const newElement = {
        id:id ? id : uuid().slice(0,8),
        name:name,
        label:label,
        id_element:parseInt(id_element),
        type:type,
        features:features,
        decisions:[]
    }

    if(measurements !== null){
      newElement.measurements = measurements
    }

    if(decisions !== null){
      newElement.decisions = decisions
    }

    if(id_element === 19){
      newElement.holes = []
      newElement.items = []
      newElement.floor = {
        id_element:2,
        type:'floor',
        holes:[],
        items:[],
        decisions:[],
        measurements:{}
      }
      newElement.ceiling = {
        id_element:3,
        type:'ceiling',
        holes:[],
        items:[],
        decisions:[],
        measurements:{}
      }
    }

    return newElement
}

export const createLine = (name = 'line', label = 'Wall', v1, v2, features = []) => {
  const lid = uuid().slice(0,8)

  const newLine = {
    id:lid,
    type:"wall",
    id_element:1,
    name:name ?? "Pared",
    v1:v1,
    v2:v2,
    label:label ?? '', 
    holes:[],
    items:[],
    features:features,
    measurements:{length:2,height:1,area:2},
    decisions:[]
  }

  return newLine
}

export const createRoom = (name = 'room', label = 'Room',type = 10, lineIDs, features = [10], newVertices, height) => {
 
  let areaID = uuid().slice(0,8)

  let area = 9;
  
  const newArray = {
      id:areaID,
      name:name,
      label:label,
      id_element:19,
      type:'area',
      lines:lineIDs,
      holes:[],
      items:[],
      measurements:{area:area},
      decisions:[],
      features:features,
      decisions:[],       
      floor:{id:"floor",type:"floor",id_element:2,holes:[],items:[],features:[type,null,null,35,47,null,50,null],measurements:{area:16.7},decisions:[]},
      ceiling:{id:"ceil",type:"ceiling",id_element:3,holes:[],items:[],features:[type,55,57,60,66,null,67,null],measurements:{area:16.7},decisions:[]}            
  }

  return newArray
}

export const createGroup = (groupId = null, items=[], name = 'group', label = 'Group', type = 'wallGroup') => {
  const newGroup = {
    id:groupId ? groupId : uuid().slice(0,8),
    name:name,
    label:label,
    type:type,
    items:[...items]
  }

  return newGroup
}

export const convertToNew = (calcData,newElement) => {


  const zeros = new Array(16).fill(0)
  const tempElement = {...newElement};
  
  tempElement.features = [...tempElement.features,...zeros]
  tempElement.features[7] = getNewFromCalcData(calcData,tempElement.id_element)

  // console.log('converting',newElement,tempElement

  return tempElement
}

export const addElementToLayer = (layer, areaID=null, target, element) => {

  // console.log('IN ADD TO LAYER',layer, areaID, target, element)

  const foundArea = areaID ? layer.areas.find(a => a.id === areaID) : null

  if(foundArea){
    // console.log('ADD TO LAYER',element.id_element,element.features)
    element.features[0] = foundArea.features[0];
  }

  switch(element.id_element){
    case 1:
      layer.lines = [...layer.lines, element]
    break;
    case 4:
    case 5:
    case 6:
      if(!target && !areaID){
        const holeIndex = wrapNumber(layer.holes.length,0,layer.areas.length)
        const wallIndex = wrapNumber(layer.holes.length,0,4)
        let tempArea = null;
        layer.lines = layer.lines.map((line,i) => {
          const tempWallId = layer.areas[holeIndex].lines[wallIndex]
          if(line.id === tempWallId){
            const tempItems = new Set(line.holes)
            tempItems.add(element.id)
            line.holes = [...tempItems]
            tempArea = layer.areas[holeIndex]
          }
          return line
        })
        if(tempArea){
          if(tempArea){
            element.features[0] = tempArea.features[0];
          }
        }
      }
      layer.holes = [...layer.holes, element]
    break;
    default:

      layer.items = [...layer.items, element]
      if(!target && !areaID){
    
        const itemIndex = wrapNumber(layer.items.length,0,layer.areas.length)
        const wallIndex = wrapNumber(layer.items.length,0,4)
        let tempArea = null;
        layer.lines = layer.lines.map((line,i) => {
          const tempWallId = layer.areas[itemIndex].lines[wallIndex]
          if(line.id === tempWallId){
            const tempItems = new Set(line.items)
            tempItems.add(element.id)
            line.items = [...tempItems]
            tempArea = getAreaOfLine(layer,line.id)
          }
          return line
        })   
        if(tempArea){
          element.features[0] = tempArea.features[0];
        }
      }
  }

  if(target){
    const foundParent = getElementOfId(layer,areaID,target)

    console.log('IN PARENT',foundParent,areaID,target)

    if(foundParent){
      switch(foundParent.type){
        case 'wall':
          layer.lines = layer.lines.map(l => {
            const tempItems = new Set(element.id_element > 3 && element.id_element < 7 ? l.holes : l.items)
            if(l.id === foundParent.id){
              tempItems.add(element.id)
              if(element.id_element >= 4 && element.id_element <= 6){
                l.holes = [...tempItems]
              } else {
                l.items = [...tempItems]
              }
            }
            return l
          })
        break;
        case 'area':
          layer.areas.map(a => {
            if(foundParent && a.id === areaID){
              const tempItems = new Set(a.floor.items)
              tempItems.add(element.id)
              a.floor.items = [...tempItems]
            }
            return a
          })
        break;
        default:
          foundParent.items.push(element.id)
      }
    }
  } else if (areaID) {
    layer.areas.map(a => {
      // console.log('ADDING IN',a.id,areaID,element,target)        
      if(a.id === areaID){
        const areaItems = new Set(a.items)
        areaItems.add(element.id)
        a.items = [...areaItems]
      }
      return a
    })
  }

  return layer
}

export const getNextIndex = (itemscount, linescount) => {

  return 
}



export const getAreaOfLine = (layer,lineID) => {
  const foundArea = layer.areas.find(a => a.lines.includes(lineID))
  return foundArea
}

export const getLineOfId = (layer,lineID) => {
  return layer.lines.find(l => l.id === lineID)
}

export const removeElementFromLayer = (layer, areaID, id_element, itemID, parent) => {

    console.log('REMOVE ALL',areaID, id_element, itemID)

    switch(id_element){
      case 1:
        layer.lines = [...layer.lines.filter(line => {
          let test = false  

          if(areaID){
            let testArea = checkIfInArea(layer,areaID,line)           
            test = line.id_element === id_element && testArea
          } else { 
            test = line.id_element === id_element
          }  
          return test
        })]
      break;
      case 4:
      case 5:
      case 6:
        layer.holes = [...layer.holes.filter(hole => {
          let test = false        
            
          if(areaID){
            let testArea = checkIfInArea(layer,areaID,hole)           
            test = hole.id_element === id_element && testArea
          } else { 
            test = hole.id_element === id_element
          }        

          if(itemID){
            test = hole.id === itemID
          }

          return !test
        })]
      break;
      default:

      console.log('|||||||DEF DEFAULT',itemID)

        layer.items = [...layer.items
          .filter(item => {  
            let test = false        
            
            if(areaID){
              let testArea = checkIfInArea(layer,areaID,item)           
              test = item.id_element === id_element && testArea
            } else { 
              test = item.id_element === id_element
            }        

            if(itemID){
              test = item.id === itemID
            }

            return !test
          }) 
        ] 
    }

    layer.areas = layer.areas.map(a => {

      const tempItems = new Set(a.items)
      tempItems.delete(itemID)
      a.items = [...tempItems]

      const tempHoles = new Set(a.holes)
      tempHoles.delete(itemID)
      a.holes = [...tempHoles]

      const tempFloorItems = new Set(a.floor.items)
      tempFloorItems.delete(itemID)
      a.floor.items = [...tempFloorItems]

      const tempCeilItems = new Set(a.ceiling.items)
      tempCeilItems.delete(itemID)
      a.ceiling.items = [...tempCeilItems]


      return a
    })

    layer.lines = layer.lines.map(l => {

      const tempItems = new Set(l.items)
      tempItems.delete(itemID)
      l.items = [...tempItems]

      const tempHoles = new Set(l.holes)
      tempHoles.delete(itemID)
      l.holes = [...tempHoles]

      return l
    })

    console.log('AFTER',layer.items)

  return layer
}

export const detachFromId = (layer, areaID, itemID) => {
  if(getAreaOfId(layer,areaID)){
    
  }
  return layer
}

export const removeAllFromArea = (layer, areaID, id_element, filter) => {
  
  // layer = layer.items.map()

  return layer    
}

export const updateElement = (layer, element) => {

  // console.log('in update',layer)

  switch(element.id_element){
    case 1:
      layer.lines = [...layer.lines.forEach(el => el.id === element.id ? el = element : el)]
    break;
    case 4:
    case 5:
    case 6:
      layer.holes = [...layer.holes.forEach(el => el.id === element.id ? el = element : el)]
    break;
    default:
      layer.items = [...layer.items.forEach(el => el.id === element.id ? el = element : el)]
  }
  return layer
} 

export const getElementsFromLayer = (layer, id_element) => {

  let elements = []

  if(layer){
    switch(id_element){
      case 1:
        elements = layer.lines;
      break;
      case 2:
      case 3:
        elements = layer.areas;
      break;
      case 4:
      case 5:
      case 6:
        elements = layer.holes;
      break;
      default:
        elements = layer.items;
    }    
  }  

  return elements
}

export const getItem = (layer,id_element,typology,filter=[],id) => {

  const elements = getElementsFromLayer(layer,id_element)

  console.log('|||||||||||foundElements',id_element,elements)

  return elements.find(item => {
    let testID = true;
    if(id_element){
      if(id_element === 2){
        testID = item.floor.id_element == id_element;
      } else if (id_element === 3) {
        testID = item.ceiling.id_element == id_element;
      } else {
        testID = item.id_element == id_element;
      }
    }
    let testTypo = true
    if(typology){
      testTypo = item.features[1] == typology;
    }
    let testFilter = true
    if(filter && filter.length > 0){
      testFilter = filter.some(fil => item.features[fil.f] === fil.v)
    }
    let idFilter = true
    if(id){
      idFilter = item.id === id
    }

    return testID === true && testTypo === true && testFilter === true && idFilter === true;
  })
}

export const getItemVertices = (item) => {
  if(item.measurements){
    return [
      {x:item.position.x, y:item.position.y},
      {x:item.position.x + item.measurements.length ?? 1, y:item.position.y},
      {x:item.position.x + item.measurements.length ?? 1, y:item.position.y + item.measurements.depth ?? 1},
      {x:item.position.x, y:item.position.y + item.measurements.depth ?? 1}
    ]
  } 
}

export const getVector = (pos,item) => {
  const position = item.position ?? {x:0,y:0}

  // console.log('INSIDE GET VECTOR',pos,item.position)
  
  return {x:pos.x - position.x,y:pos.y - position.y}
}

export const getFilteredElements = (layer,areaId,id_element,typology=null,filter=[],id=null) => { // todo filter

  let elements = getElementsFromLayer(layer,id_element)

  elements.filter(item => {
    let testTypo = true
    if(typology){
      testTypo = item.features[1] == typology;
    }
    let testFilter = true
    if(filter && filter.length > 0){
      testFilter = filter.some(fil => item.features[fil.f] === fil.v)
    }
    let testId = true
    if(id){
      testId = item.id === id
    }

    return testTypo === true && testFilter === true && testId === true;
  })

  return elements.length > 0 ? elements : null
}

export const filterElement = (element, typology, id_element = null, filter = []) => {

  let testIdElement = true;
  if(id_element){
    testIdElement = element.id_element === id_element;
  }

  let testTypo = true;
  if(typology){
    testTypo = element.features[1] === typology;
  }

  let testFilter = true
  if(filter && filter.length > 0){
    testFilter = filter.some(fil => element.features[fil.f] === fil.v)
  }

  return (testIdElement === true && testTypo === true && testFilter === true); 
}

export const getElementData = (elementData,id_element,typology) => {
  let elements = []

  switch(id_element){
    case 0:
    case 1:
    case 2:
    case 3:
      elements = elementData.elements;
    break;
    case 4:
    case 5:
    case 6:
      elements = elementData.holes;
    break;
    case 19:
      elements = elementData.elements;
    break;
    default:
      elements = elementData.items;
  }

  return elements.find(element => {
    const typoTest = typology ? element.type === typology : true
    const idElTest = id_element ? element.id_element === id_element : true

    return idElTest === true && typoTest === true 
  })
}

export const checkValueFilter = (value,filters = [],item = null) => {
  let testFilter = []
  if(filters.length > 0 && value.filter && value.filter.length > 0){
      value.filter.forEach(filt => {
        const foundFilter = filters.find(fl => fl.f === filt.f)
        if(foundFilter){
          testFilter.push(filt.v.some(f => foundFilter.v.includes(f)))
        } else {
          testFilter.push(true)
        }
      })
    }
    // console.log('===',testFilter.length === 0,testFilter.every(test => test === true))
  return testFilter.length === 0 || testFilter.every(test => test === true)
}

export const checkFilterItem = (item,filters = [],id_element = []) => {
  let testFilter = true
  if(item){
    if(filters && filters.length > 0){
      testFilter = filters.every(fil => { 
        let testFil = false
        if(fil.v.constructor.name === "Array"){
          testFil = fil.v.some(v => item.features[fil.f] === v)
        } else {
          testFil = item.features[fil.f] == parseInt(fil.v)
        }
        // console.log('---',item.features[fil.f],parseInt(fil.v),testFil)
        return testFil
      })
    }

    // console.log('IN FILTER',testFilter,filters)

    // if(id_element){
    //   if(!id_element.constructor.name == "Array"){
    //     id_element = [id_element]
    //   }

    // }    
  }

  // console.log('IN CHECK',item.features,filters,testFilter)

  return testFilter
}

export const filteredItems = (items,filters = []) => {
  let filtered = items;
  if(filters.length > 0){
    filtered = items.filter(item => {
      const tests = []
      filters.forEach(filter => {
        tests.push(item.features[filter.f] == parseInt(filter.v))
      })
      return tests.every(t => t === true)
    })
  }   
  return filtered
}

export const filteredSomeItems = (items,filters = []) => {
  let filtered = items.filter(item => {

    const tests = []

    filters.forEach(filter => {
      tests.push(item.features[filter.f] === filter.v)
    })

    return tests.some(t => t === true)
  })
    
  return filtered
}

export const getOldFromCalcData = (calcData,id_element,element) => {

  // const olds = [,,,,76,,185,,117,120,132,136,145,152,160,175,198,,,145,]

  const olds = calcData.elements.map(el => el.old || el.old !== "" ? el.old : null)

  const idEl = element ? element.id_element : id_element

  return olds[idEl];
} 

export const getNewFromCalcData = (calcData,id_element = null,element) => {
  const calcElement = calcData.elements.find(
    el => el.id_element === (element ? element.id_element : id_element))

    // console.log('FINDING NEW',calcElement.new)

    return calcElement ? calcElement.new : null;
} 

export const getElementOfId = (layer,areaID=null,itemID,filters) => {

  let found = []

  if(layer){
    found = [
      layer.areas.find(area => area.id === itemID),
      layer.lines.find(line => line.id === itemID),
      layer.groups.find(group => group.id === itemID),
      layer.holes.find(hole => hole.id === itemID),
      layer.items.find(item => item.id === itemID)
    ]    
  }

  const foundArea = layer.areas.find(a => a.id === areaID)

  if(found.id_element === 'wall'){
      layer.lines.find(l => {
        if(foundArea){
          return foundArea.lines.includes(l.id)
        } else {
          return false;
        }
      })
  }

  if(found.id_element === 'floor'){
    return foundArea  
  }

  if(!itemID){
    const testFiltered = filteredItems(layer.items,filters)

    console.log('CHECKING FILTER',testFiltered)
    if(testFiltered.length > 0){
      found = [testFiltered[0]]
    }
  }

  return found.find(el => el !== undefined)
}

export const getAreaOfId = (layer,areaID=null) => {
  if(layer){
    switch(areaID){
      case 'others':
        return layer.areas.find(area => area.features[0] !== 6 || area.features[0] !== 8)
      case 'wetrooms':
        return layer.areas.find(area => area.features[0] === 6 || area.features[0] === 8)
      case 'banos':
        return layer.areas.find(area => area.features[0] === 6)
      case 'cocinas':
        return layer.areas.find(area => area.features[0] === 8)
      default:
        return layer.areas.find(area => area.id === areaID)
    }
  }
}

export const getAreaOfElement = (layer,itemID) => {
  const foundArea = layer.areas.find(a => {
    const areaCheck = a.items.some(item => item === itemID)
    const floorCheck = a.floor.items.some(item => item === itemID)
    const aHolesCheck = a.holes?.some(item => item === itemID)
    const ceilingCheck = a.ceiling.items.some(item => item === itemID)
    const areaWalls = layer.lines.filter(l => a.lines.includes(l.id))
    const foundWall = areaWalls.some(line => line.id === itemID)
    const wallCheck = areaWalls.some(line => line.items.includes(itemID))
    const wallHoleCheck = areaWalls.some(line => line.holes.includes(itemID))
   
    return areaCheck || floorCheck || ceilingCheck || wallCheck || aHolesCheck || wallHoleCheck || foundWall
  })

  return foundArea
}

export const getStyle = (scene,areaID) => {

  let tempStyle = 0
  let tempLayer = scene ? scene.layers[0] : null
  let tempArea = null

  if(areaID && tempLayer){

    switch(areaID){
      case 'banos':
        tempArea = tempLayer.areas.find(a => a.features[0] === 6)
      break;
      case 'cocinas':
        tempArea = tempLayer.areas.find(a => a.features[0] === 8)
      break;
      case 'others':
        tempArea = tempLayer.areas.find(a => a.features[0] !== 8 && a.features[0] !== 6)
      break;
      default:
        tempArea = tempLayer.areas.find(a => a.id === areaID)
    }

    if(tempArea){
      tempStyle = tempArea.features[23]
    }
  }

  // console.log('IN GET STYLE',areaID,tempArea)

  return tempStyle
}

export const getIdfromSelected = (layer, selected) => {
  let tempSelected = null
  
  if(layer !== null && selected !== null){
    layer.lines.forEach((line,i) => {
      if(parseInt(selected.split('_')[1]) === i){
        tempSelected = line.id
      }
    })
  }
  return tempSelected
}

export const getNameFromId = (layer,id,areaId) => {

  // console.log('in layer',layer,id) 

  return 'Pared ' + id.split('_')[1]
}

export const getLabelFromId = (layer,id,areaId) => {

  // console.log('in layer',layer,id) 

  return 'Pared ' + id.split('_')[1]
}

export const getElements = (layer,areaId,id_element,filter) => {

  let tempElements = []

  switch(id_element){
    case 1:
      tempElements = layer.lines
    break;
    case 2:
      tempElements = layer.areas[0].floor
    break;
    case 3:
      tempElements = layer.areas[0].ceiling
    break;
    case 4:
    case 5:
    case 6:
      tempElements = layer.holes
    default:
      tempElements = layer.items
    break;
  }

  // console.log(filteredItems(tempElements,filter))

  return filteredItems(tempElements,filter)
}

export const getFirstElement = (layer,areaId=null,id_element=null,filter=null) => {
  
  const elems = getElements(layer,areaId,id_element,filter) 
  
  // console.log('elements found',elems)  

  return elems.length > 0 ? elems[0] : null
}

export const getAllValues = (items,column,feature) => { 

  if(Array.isArray(items)){
    // console.log(items.every(item => items[0].features[column] === getFeatureValue(item,column)))
    switch(items[0].type){
      case "wall":
        if(items.every(item => items[0].features[column] === getFeatureValue(item,column))){
          return items[0].features[column]
        }
      break;
      default:
    }
    // console.log(items[0])

    // return getFeatureValue(items[0],column)
  } else {
    return getFeatureValue(items,column)
  }
}

export const getAreaLines = (layer,areaID = null) => {
  const tempLines = layer.lines
  let testLines = []
  
  if(areaID){
    switch(areaID){
      case 'others':
        const filteredAreas = layer.areas.filter(a => a.features[0] !== 8 && a.features[0] !== 6)
          testLines = tempLines.filter(wall => {
          return filteredAreas.some(a => a.lines.includes(wall.id))
        })
      break;
      case 'cocinas':
        const cocinas = layer.areas.filter(a => a.features[0] === 8)
          testLines = tempLines.filter(wall => {
          return cocinas.some(a => a.lines.includes(wall.id))
        })
      break;
      case 'banos':
        const banos = layer.areas.filter(a => a.features[0] === 6)
          testLines = tempLines.filter(wall => {
          return banos.some(a => a.lines.includes(wall.id))
        })
      break;
      case 'wetrooms':
        const wetAreas = layer.areas.filter(a => a.features[0] === 8 && a.features[0] === 6)
          testLines = tempLines.filter(wall => {
          return wetAreas.some(a => a.lines.includes(wall.id))
        })
      break;
      default:
        const tempArea = layer.areas.find(a => a.id === areaID)
        testLines = tempArea ? tempLines.filter(wall => tempArea.lines.includes(wall.id)) : []
    } 
  }
  
  return testLines
}

export const refreshLayer = (layer) => {

  layer.areas = layer.areas.map(a => {
      const areaPoints = []
      layer.lines = layer.lines.map(l => {
        if(a.lines.includes(l.id)){
          const v1 = layer.vertices[l.v1]
          const v2 = layer.vertices[l.v2]

          l.measurements.length = verticesDistance(v1,v2)
          l.measurements.area = l.measurements.height ? l.measurements.length * l.measurements.height : l.measurements.length * 2.4

          areaPoints.push(v1,v2)
        }
        return l
      })

      const polygon = areaPoints.map(v => [v.x,v.y])

      let areaSize = areaPolygon(polygon, false);

      // console.log('Layer',areaSize,areaPoints)

      a.floor.measurements = {area:parseFloat(areaSize).toFixed(2)}
      a.ceiling.measurements = {area:parseFloat(areaSize).toFixed(2)}
      a.measurements = {area:parseFloat(areaSize).toFixed(2)}
      return a
  })

  layer.lines = layer.lines.filter(line => {
    return layer.areas.some(area => area.lines.includes(line.id))
  })

  const validVertices = layer.vertices.map((v,i) => {
    if(layer.lines.some(l => l.v1 === i || l.v2 === i)){
      return v
    } else {
      return null
    }
  })

  // layer.vertices.forEach((v,i) => {
  //   if(layer.lines.some(l => l.v1 === i || l.v2 === i)){
  //     validVertices.push(i)
  //   }
  // })

  const correctVertiuces = []

  validVertices.forEach((v,i) => {
    if(v !== null){
      correctVertiuces.push(v)
      layer.lines = layer.lines.map(l => {
        l.v1 = l.v1 === i ? correctVertiuces.length - 1 : l.v1 
        l.v2 = l.v2 === i ? correctVertiuces.length - 1 : l.v2
        return l
      })      
    }
  })

  // checking if all items has its parent

  console.log('-------',validVertices,correctVertiuces)

  layer.vertices = correctVertiuces;

  layer.items = layer.items.filter((item,i) => {
    const testLines = layer.lines.some(l => l.items.includes(item.id))
    const testAreas = layer.areas.some(a => a.items.includes(item.id))
    const testFloors = layer.areas.some(a => a.floor.items.includes(item.id))
    const testCeilings = layer.areas.some(a => a.ceiling.items.includes(item.id))
    return testLines || testAreas || testFloors || testCeilings
  })

  // checking if all holes has its parent

  layer.holes = layer.holes.filter((hole,i) => {
    const testLines = layer.lines.some(l => l.holes.includes(hole.id))
    const testAreas = layer.areas.some(a => a.holes.includes(hole.id))
    const testFloors = layer.areas.some(a => a.floor.holes.includes(hole.id))
    const testCeilings = layer.areas.some(a => a.ceiling.holes.includes(hole.id))
    return testLines || testAreas || testFloors || testCeilings
  })

  return layer
} 

export const refreshVertices = (layer,defaults) => {
  const tempVertices = layer.vertices

  console.log('===REFRESHING')

  layer.areas.map(area => {
    const areaVertices = getAreaVertices(layer,area.id)
    const polygon = areaVertices.map(v => [v.x,v.y])
    // const polygon = areaVertices
    
    let areaSize = areaPolygon(polygon, false);
    
    area.floor.measurements = {area:parseFloat(areaSize).toFixed(2)}
    area.ceiling.measurements = {area:parseFloat(areaSize).toFixed(2)}
    area.measurements = {area:parseFloat(areaSize).toFixed(2)}

    return area
  })

  layer.vertices = tempVertices

  layer.lines.forEach(line => {
    const tempLength = verticesDistance(tempVertices[line.v1],tempVertices[line.v2])
    const tempHeight = defaults ? defaults.height : 2.40
    const tempArea = tempLength * tempHeight
    line.measurements = {length:tempLength,height:tempHeight,area:tempArea}
  })  



  return layer
}

export const detachArea = (layer, areaID) => {

  // console.log('INCOMING',layer.vertices)

  const foundArea = getAreaOfId(layer,areaID)
  if(foundArea){
    const vertices = layer.vertices
    const areaVertices = getAreaVerticesID(layer,areaID)
    const areaLines = layer.lines.filter(l => foundArea.lines.includes(l.id))

    // console.log('before detach',areaVertices,areaLines.map(l => [l.v1,l.v2]),vertices)

    const tempVerts = []

    for(let v of areaVertices){
      const existsInOthers = layer.areas.filter(a => a.id !== areaID).some(area => {
        const otherAreaVertices = getAreaVerticesID(layer,area.id)
        // console.log('checkingIn',area.id,otherAreaVertices,otherAreaVertices.includes(v))
        return otherAreaVertices.includes(v)
      })

      if(existsInOthers){
        vertices.push(vertices[v])
        tempVerts.push({id:v,index:vertices.length-1})
      }
    }

    layer.lines = layer.lines.map(l => {
      if(foundArea.lines.includes(l.id)){
        tempVerts.forEach(v => {
          if(l.v1 === v.id){
            l.v1 = v.index
          }
          if(l.v2 === v.id){
            l.v2 = v.index
          }
          })
        }
      return l
    })
    // layer.lines = layer.lines.map(line => {
    //     if(foundArea.lines.includes(line.id)){
      //     console.log('IN LINE',line.v1,line.v2)
      //     layer.areas.filter(a => a.id !== areaID).forEach(area => {
      //       const otherAreaVertices = getAreaVerticesID(layer,area.id)
      //       if(otherAreaVertices.includes(line.v1)){
      //         console.log('adding',vertices[line.v1])  
      //         const v1 = vertices.length + count
      //         tempVerts.push([v1,vertices[line.v1]])
      //         count++
      //       }
      //       if(otherAreaVertices.includes(line.v2)){
      //         console.log('adding',vertices[line.v2])     
      //         const v2 = vertices.length + count
      //         tempVerts.push([v2,vertices[line.v2]])
      //         count++
      //       }
      //     })
    //     }
    //   return line
    // })
    
    // console.log('after detach',tempVerts,vertices,layer.vertices,layer.lines.map(l => [l.v1,l.v2]))

    
    // tempVerts.forEach(v => layer.vertices.push(v[1])) 
    // layer.vertices = [].concat(vertices,tempVerts)
    // console.log('CHECKED',layer.lines.map(l => [l.v1,l.v2]),layer.vertices)

    // const areaLinesID = getAreaLines(layer,areaID).map(l => l.id)
    // layer.areas.filter(area => area.id !== areaID).forEach(a => {
    //   a.lines.forEach(l => {
    //     const tempLine = layer.lines.find(line => line.id === l)
    //     if(tempLine){
    //       if(areaVertices.includes(tempLine.v1)){
    //         layer.vertices.push(layer.vertices[tempLine.v1])
    //         const index = layer.vertices.length-1

    //        layer.lines = layer.lines.map(lin => {
    //           if(areaLinesID.includes(lin.id)){
    //             if(lin.v1 === tempLine.v1){
    //               lin.v1 = index
    //             } else if (lin.v2 === tempLine.v1) {
    //               lin.v2 = index
    //             }
    //           }
    //           return lin
    //         })
    //       }
    //     }
    //   })
    // })
    // console.log('after detach',layer.vertices.length,foundArea.lines,layer.lines.filter(l => foundArea.lines.includes(l.id)).map(lin => [lin.v1,lin.v2]),getAreaVertices(layer, areaID))
  }
  return layer
} 

export const atachArea = (layer) => {

  return layer
}

export const getAreaVertices = (layer, areaID) => {
  const areaLines = getAreaLines(layer,areaID);
  const verts = layer.vertices
  const wallVertices = areaLines.map(w => {

    // console.log('====WALL',w.v1,w.v2)

    return [layer.vertices[w.v1],layer.vertices[w.v2]]
  })

  // console.log('in verts',wallVertices,verts)

  const allVertices = new Set([...wallVertices.map(v => v[0]),...wallVertices.map(v => v[1])])

  return [...allVertices]
} 

export const getAreaVerticesID = (layer, areaID) => {
  const areaLines = getAreaLines(layer,areaID);
  const wallVertices = areaLines.map((l) => [l.v1,l.v2])

  const allVertices = new Set([...wallVertices.map(v => v[0]),...wallVertices.map(v => v[1])])

  return [...allVertices]
}



export const getOldValue = (layer,areaId = null,id_element = null,f,id = null,selected = null) => {

  let tempItems = []
  let value = []

  id_element = id_element !== null ? parseInt(id_element) : getElementOfId(layer,null,id).id

  let foundArea = null

  areaId = areaId ?? selected
  if(layer){
    if(areaId !== null){
      foundArea = layer.areas.find(a => a.id === areaId)
    }
    switch(parseInt(id_element)){
      case 1:
        tempItems = layer.lines.filter(l => {
          let testArea = true
          
          if(areaId && foundArea){
            testArea = foundArea.lines.includes(l.id);
          }

          return testArea
        })
      break;
      case 2:
        if(foundArea){
          tempItems = [foundArea.floor]
        } else {
          tempItems = [layer.areas[0].floor]
        }
      break;
      case 3:
        if(foundArea){
          tempItems = [foundArea.ceiling]
        } else {
          tempItems = [layer.areas[0].ceiling]
        }
      break;
      case 4:
      case 5:
      case 6:
        tempItems = layer.holes
      break;
      default:
        tempItems = layer.items
    }

    if(id_element === 1){
      if(id !== null){
        const foundItem = tempItems.find(item => item.id === id);
        value = foundItem ? foundItem.features[f] : null
      } else {
        const firstValue = tempItems[0].features[f]
        value = tempItems.every(item => item.features[f] === firstValue) ? firstValue : null
      }
    } else {
      value = tempItems[0].features[f]
    }

  }

  if(selected){

  }
  // console.log('in old',layer)

  return value
} 

export const getFeatureValue = (item,column,id_element) => {
  if(item && column){
    switch(id_element){
      case 2:
        return item.floor.features[parseInt(column)]
      case 3:
        return item.ceiling.features[parseInt(column)]
      default:
        return item.features[parseInt(column)]    
    }
  }
} 

export const getGroupFeatureValue = (layer,groupId,column) => {
  // console.log('IN OLD VALUE',layer,groupId,column);

  const group = layer.groups.find(gr => gr.id === groupId)

  let values = [];

  if(group){
    group.items.forEach(itemId => {
      const item = getElementOfId(layer,null,itemId)
      if(item){
        values.push(getFeatureValue(item,column)) 
      }
    })
  }

  return values.every(v => v === values[0]) ? values[0] : null
}

export const checkInGroup = (itemId,groupId,groups) => {
  const foundGroup = groups ? groups.find(gr => gr.id === groupId) : null
  // console.log('CHECKING GROUPS',groups)

  let testGroup = false

  if(foundGroup && foundGroup.items){
    testGroup = foundGroup.items.includes(itemId)
  }
  
  return testGroup
}

export const checkIfInArea = (layer,areaID,item) => {

  let testAreaItems = false
  let testFloorItems = false
  let testCeilingItems = false
  let testWalls = false
  let testHoles = false
  if(areaID){
    const tempArea = getAreaOfId(layer,areaID)
    if(tempArea){
      testAreaItems = tempArea.items.includes(item.id)
      testFloorItems = tempArea.floor.items.includes(item.id)
      testCeilingItems = tempArea.ceiling.items.includes(item.id)
      testWalls = layer.lines.some(l => tempArea.lines.includes(l.id) && l.items.includes(item.id))
      testHoles = layer.holes.some(l => tempArea.lines.includes(l.id) && l.holes.includes(item.id))
    }
  }

  // console.log('check in area',areaID,item,testAreaItems,testFloorItems,testCeilingItems,testWalls,testHoles)

  return testAreaItems || testFloorItems || testCeilingItems || testWalls || testHoles
}

export const checkFilterItems = (layer,id_element = null, filter = []) => {
  if(layer === null) return false
  
  const elements = getElementsFromLayer(layer,id_element)

  return elements.filter(it => {
      let test = []

      if(filter && filter.length > 0){
        filter.forEach(filt => {
          if(it.features[filt.f] === filt.v) {
            test.push(true)
          } else {
            test.push(false)
          }
        })
      }

      // console.log('IN CHECK ',filter,id_element,layer,test.length === 0,test.every(t => t === true))

      return test.length === 0 || test.every(t => t === true)
    });
}

export const setElementProperty = (item,property,value) => {
  item[property] = value
  return item
}

export const checkActions = (item, actions) => {
  let checks = [];
  if(actions){
  actions.forEach(action => {
      switch (action.a){
        case 'set':
          // console.log('checking',item.id,item.features[action.f],'-',action,item.features[action.f] === parseInt(action.v))
          if(item.features[action.f] === parseInt(action.v)){
            checks.push(true)
          }
        break;
        default:
          checks.push(false)
      }
    })
  }

  // console.log('============',item.features[actions[0].f],actions[0],checks)

  return checks.every(ch => ch === true) && checks.length > 0
}

export const getCalculatedDimensions = (items,typologies = [],which) => {

  which = which ? which : 'length';

  const summed = items
    .filter(item => typologies.includes(item.features[1]))
    .filter(it => {return it.measurements !== undefined})
    .reduce((total,item_) => {
      // console.log('measuring ',item_.id,'-',item_.measurements[which],'-',total)
      return total + item_.measurements[which]
    },0)

  // console.log('IN CHECK DIMM',summed)

  return isNaN(summed) ? 0 : summed.toFixed(2);
}

    
export const getMousePos = (canvas, evt) => {
  var rect = canvas.getBoundingClientRect();
  return {
    x: evt.clientX - rect.left,
    y: evt.clientY - rect.top
  };
}