import React, { useRef, useEffect, useState, useContext, createElement } from 'react'
import ProjectContext from '../../context/ProjectContext'
import SceneContext from '../../context/SceneContext'
import QnrContext from '../../context/QnrContext'
import areapolygon from 'area-polygon';
import {
    getPolygonCentroid,
    getRealPos,
    inside2,
    getScale,
    getOffset2,
    shiftVertices,
    getBBox,
    getVectors,
    isPointOnLine,
    pointPositionOnLineSegment,
    midPoint,
    pointOnLine,
    offsetPoints,
    verticesDistance
  } from '../../utils/geometry'
import { COLORS } from '../../utils/constants'
import { createLine, createNewElement, createRoom, getAreaVertices, getElementData, getItemVertices, getMousePos, getVector } from '../../utils/sceneUtils';
import { AiOutlineConsoleSql } from 'react-icons/ai';
import { BsController } from 'react-icons/bs';

const Canvas2 = (props) => {

    const colors = COLORS.planner

    const {sceneId, plannermode = 'dimensions', width, height, enabled} = props;

    const projectActions = useContext(ProjectContext)
    const sceneActions = useContext(SceneContext)
    const qnrActions = useContext(QnrContext)

    const { scene, layer, setVertices, elements, setElements } = useContext(SceneContext)
  
    const canvasRef = useRef(null)

    const [shapes, setShapes] = useState([])
    const [scale, setScale] = useState(1)
    const [offset, setOffset] = useState({x:0,y:0})
    const [pos, setPos] = useState([])
    const [activeLayer,setActiveLayer] = useState(null)
    const [realPos, setRealPos] = useState([])
    const [vector, setVector] = useState([])
    const [vectors, setVectors] = useState([])
    const [startPos, setStartPos] = useState({x:0,y:0})
    const [tempPoints,setTempPoints] = useState([])
    const [selected, setSelected] = useState([])
     
    // useEffect(() => {
    //     document.addEventListener('keydown',handleKeyDown)
    //     return () => {
    //         document.removeEventListener("keydown", handleKeyDown);
    //     };
    // },[])
    
    const handleKeyDown = (e) => {
      console.log('key',e.key,sceneActions.selected)
      switch(e.key){
          case 'Delete':
              if(selected){
                    // console.log('deleting')
                    sceneActions.delSelected()
              }
          break;
      }
    
    //   sceneActions.setRefresh(!sceneActions.refresh)
    }
    

    useEffect(() => {
        
        const canvas = canvasRef.current
        const context = canvas.getContext('2d')
        
        draw(context)

        document.addEventListener("keydown", handleKeyDown);
        return () => {
          document.removeEventListener("keydown", handleKeyDown);
        }

    }, [projectActions.scenes,sceneActions.refresh,sceneActions.activeRoom,sceneActions.active])   

     const prepareObjects = () => {
        const canvas = canvasRef.current
        const ctx = canvas.getContext('2d')
        

        const vertices = layer.vertices

        const lines = []
        
        layer.lines.forEach((l,i) => {
            const v1 = {x:vertices[l.v1].x * scale + offset.x, y:vertices[l.v1].y * scale + offset.y}
            const v2 = {x:vertices[l.v2].x * scale + offset.x, y:vertices[l.v2].y * scale + offset.y}

            const line = new Path2D();

            line.moveTo( v1.x, v1.y)
            line.lineTo( v2.x, v2.y);

            // go back to point 1

            // console.log('ON CREATION',line)

            lines.push(line)

            // vertex labels
            
            // ctx.font = "15px Arial";
            // ctx.fillStyle = "#FFFFFF";
            // ctx.textAlign = "center";
            // ctx.fillText(i, v1.x, v2.x);
            
            // if(e.label && plannerType === 'dimensions'){
                //     const label = new Path2D()
                //     ctx.fillStyle = "#00D4FF";
                //     const size = 30;
                //     const mp = midPoint(v1.x,v1.y,v2.x,v2.y);
                //     roundRect(ctx, mp.x - size/2 , mp.y - size/2, size, size, {tl: 3, tr: 3, br: 3, bl: 3}, true, false);
                //     ctx.font = "15px Arial";
                //     ctx.fillStyle = "#FFFFFF";
                //     ctx.textAlign = "center";
                //     ctx.fillText(e.label, mp.x, mp.y+5);
                // }
        })

        setShapes(() => lines)
    }


    const draw = ctx => {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)

        const layer = projectActions.scenes[0].layers[0]
        const areas = layer.areas

        const lines = layer.lines.filter(l => {
            let testRoom = true
            if(props.display === 'filtered' && sceneActions.activeRoom){
                const foundArea = layer.areas.find(a => a.id === sceneActions.activeRoom)
                if(foundArea){
                    testRoom = foundArea.lines.includes(l.id)
                }
            }
            return testRoom
        })

        const vertices = projectActions.scenes[0].layers[0].vertices     

        const filteredVertices = vertices.filter((v,i) => {
            return lines.some(l => {
                return l.v1 === i || l.v2 === i
            })
        })
        
        const scale = getScale(ctx,filteredVertices)
        
        const offset = getOffset2(ctx,filteredVertices,scale)
        
        // draw lines
        // shapes.forEach(s => {
        //     // if(ctx.isPointInStroke(lines[0], e.clientX, e.clientY)){
        //     //     ctx.strokeStyle = "#00D4FF";
        //     // } else {
        //     // }
        //     console.log('stroking line')

        //     ctx.strokeStyle = "#00D4FF";

        //     ctx.lineWidth = 3;

        //     ctx.stroke(s)
        // })

        // console.log('--------------in canvas',scale,offset,filteredVertices,sceneActions.activeRoom,layer.areas,layer.lines)      
        
        setScale(() => scale)
        setOffset(() => offset)

        // draw grid

        ctx.strokeStyle = "#c8c8c8";
        ctx.lineWidth = 0.3;
        ctx.beginPath();

        for(let i = 0; i < 30; i++){
            ctx.moveTo(scale * i + offset.x, 0);
            ctx.lineTo(scale * i + offset.x, ctx.canvas.height);
        }

        for(let j = 0; j < 20; j++){
            ctx.moveTo(0,scale * j + offset.y);
            ctx.lineTo(ctx.canvas.width,scale * j + offset.y);
        }

        ctx.stroke();

        areas.filter(a => {
            let testArea = true
            
            if(props.display === 'filtered' && sceneActions.activeRoom){
                testArea = a.id === sceneActions.activeRoom
            }

            return testArea
        }).forEach((area,i) => { 
            const areaVertices = getAreaVertices(layer,area.id)
            const offsetVertices = offsetPoints(areaVertices,0.001 * scale)

            // console.log('WCHODZI',areaVertices,offsetVertices)
                // const newArea = new Path2D();
                // var poly=[ 5,5, 100,50, 50,100, 10,90 ];
            

            ctx.fillStyle = 'rgba(255, 255, 0, 0.3)';

            ctx.strokeStyle = 'grey';
            ctx.lineWidth = 1;
            ctx.beginPath();
            
            // console.log('IN DRAW',areaVertices)

            offsetVertices.forEach((vs,j) => {
                // console.log('DRAWING:',j,vs)
                if(j === 0){
                    ctx.moveTo(vs.x * scale + offset.x, vs.y * scale + offset.y);
                }
                    ctx.lineTo(vs.x * scale + offset.x, vs.y * scale + offset.y)
                if(j === offsetVertices.length - 1){
                    ctx.lineTo(offsetVertices[0].x * scale + offset.x, offsetVertices[0].y * scale + offset.y)
                }
            })

            ctx.stroke();
            ctx.closePath();
            
            if((area.features[0] === 6 || area.features[0] === 8) && sceneActions.selected !== area.id){
                ctx.fillStyle = 'rgba( 0, 255,255, 0.3)';
                ctx.fill()
            }

            if(sceneActions.active === area.id){
                ctx.fillStyle = 'rgba( 0, 255,0, 0.3)';
                ctx.fill()
                ctx.canvas.style.cursor = 'pointer'
            }
            
            if(sceneActions.selected === area.id){
                ctx.strokeStyle = 'rgba(0,0,0,0.8)';
                ctx.fillStyle = 'rgba(255, 255, 0, 0.3)';
                ctx.fill()
                ctx.beginPath();

                if(plannermode === 'rooms'){
                    const BBox = getBBox(areaVertices)

                    ctx.strokeStyle = "#c8c8c8";
                    ctx.lineWidth = 1;

                    ctx.rect(BBox.x * scale + offset.x,BBox.y * scale + offset.y,BBox.width * scale,BBox.height * scale)

                    ctx.moveTo(BBox.x * scale + offset.x + BBox.width * scale / 2,BBox.y * scale + offset.y - 5, 10, 10)
                    ctx.lineTo(BBox.x * scale + offset.x + BBox.width * scale / 2,BBox.y * scale + offset.y -50, 10, 10)

                    ctx.rect(BBox.x * scale + offset.x + BBox.width * scale / 2 - 5,BBox.y * scale + offset.y - 60, 10, 10)

                    ctx.rect(BBox.x * scale + offset.x - 5,BBox.y * scale + offset.y - 5, 10, 10)
                    ctx.rect(BBox.x * scale + offset.x - 5,BBox.maxY * scale + offset.y - 5, 10, 10)
                    ctx.rect(BBox.maxX * scale + offset.x - 5,BBox.y * scale + offset.y - 5, 10, 10)
                    ctx.rect(BBox.maxX * scale + offset.x - 5,BBox.maxY * scale + offset.y - 5, 10, 10)

                    ctx.rect(BBox.x * scale + offset.x + BBox.width * scale / 2 - 5,BBox.y * scale + offset.y - 5, 10, 10)
                    ctx.rect(BBox.maxX * scale + offset.x - 5,BBox.y * scale + offset.y + BBox.height * scale / 2 - 5, 10, 10)
                    ctx.rect(BBox.x * scale + offset.x - 5,BBox.y * scale + offset.y + BBox.height * scale / 2 - 5, 10, 10)
                    ctx.rect(BBox.x * scale + offset.x + BBox.width * scale / 2 - 5,BBox.maxY * scale + offset.y - 5, 10, 10)

                    ctx.stroke();
                }
            }

            const polygon = areaVertices.map(v => [v.x,v.y])

            let areaSize = areapolygon(polygon, false);
            const center = getPolygonCentroid(areaVertices);

            ctx.fillStyle = "#007DFF";
            ctx.fillText(area.label, center.x * scale + offset.x - 20, center.y * scale + offset.y);            
            ctx.fillText(Math.round(areaSize * 100) / 100 + ' m2', center.x * scale + offset.x - 20, center.y * scale + offset.y + 15);            
        
            ctx.lineWidth = 1;
            // ctx.fillStyle = "#00ff00";
            
            // area.floor.items.forEach(itemID => {
            //     ctx.fillStyle = '#0000ff';

            //     if(sceneActions.active === itemID) ctx.fillStyle = COLORS.planner.itemActive;
 
            //     if(sceneActions.selected === itemID) ctx.fillStyle = COLORS.planner.itemSelected;                              

            //     const foundItem = layer.items.find(item => {
            //         return item.id === itemID
            //     })
                    
            //     if(foundItem){                  
            //         const itemPosition = foundItem.position ? foundItem.position : center
            //         const itemLength = foundItem.measurements ? foundItem.measurements.length : 1
            //         const itemDepth = foundItem.measurements ? foundItem.measurements.depth : .5

            //         ctx.fillRect(itemPosition.x * scale + offset.x,itemPosition.y * scale + offset.y,itemLength * scale,itemDepth * scale)
            //     }
            // })

            // area.ceiling.items.forEach(item => {
            //     if(sceneActions.active === item.id) 
            //         ctx.fillStyle = '#00ffff';
            //     const itemPosition = item.position ? item.position : center
            //     const itemLength = item.measurements ? item.measurements.length : 1.5
            //     const itemDepth = item.measurements ? item.measurements.depth : .5
            //     ctx.fillRect(itemPosition.x * scale + offset.x,itemPosition.y * scale + offset.y,itemLength * scale,itemDepth * scale)
            // })
            // ctx.stroke();

                    // draw lines

            area.lines.forEach((lineID,i) => {

                const foundLine = layer.lines.find(line => line.id === lineID)

                if(foundLine){
                    const v1 = {x:vertices[foundLine.v1].x * scale + offset.x, y:vertices[foundLine.v1].y * scale + offset.y}
                    const v2 = {x:vertices[foundLine.v2].x * scale + offset.x, y:vertices[foundLine.v2].y * scale + offset.y}
    
                    const line = new Path2D();
    
                    // ctx.strokeStyle = "#c8c8c8";
                    ctx.strokeStyle = "red";
                    ctx.lineWidth = 5;
    
                    if(sceneActions.active === foundLine.id) ctx.strokeStyle = COLORS.planner.itemActive;
    
                    if(sceneActions.selected === foundLine.id) ctx.strokeStyle = COLORS.planner.itemSelected;
    
                    if(sceneActions.selected === foundLine.id || sceneActions.active === foundLine.id){
        
                        line.moveTo( v1.x, v1.y)
                        line.lineTo( v2.x, v2.y);
                        lines.push(line)
        
                    }
                    
                    ctx.stroke(line)                        
                }
            })
        })

        // draw holes

        layer.lines.forEach((l,i) => {
            const v1 = {x:vertices[l.v1].x * scale + offset.x, y:vertices[l.v1].y * scale + offset.y}
            const v2 = {x:vertices[l.v2].x * scale + offset.x, y:vertices[l.v2].y * scale + offset.y}

            l.holes.forEach(h => {
                
                const foundHole = layer.holes.find(hole => hole.id === h)

                if(foundHole){
                    const mid = midPoint(v1.x,v1.y,v2.x,v2.y)
                    const start = pointOnLine(mid.x,mid.y,v2.x,v2.y,(foundHole.measurements ? foundHole.measurements.length / 2 : 0.5) * scale)
                    const end = pointOnLine(mid.x,mid.y,v2.x,v2.y,-(foundHole.measurements ? foundHole.measurements.length / 2 : 0.5) * scale)
                    
                    if(foundHole.id_element < 6){
                        ctx.strokeStyle = "#000";
                    } else {
                        ctx.strokeStyle = "#520000";
                    }

                    if(sceneActions.active === foundHole.id) ctx.strokeStyle = COLORS.planner.itemActive;

                    if(sceneActions.selected === foundHole.id) ctx.strokeStyle = COLORS.planner.itemSelected;

                    ctx.lineWidth = 5;
                    ctx.beginPath()
                    ctx.moveTo(start.x,start.y)
                    ctx.lineTo(end.x,end.y)
                    ctx.stroke()
                }
            })

            l.items.forEach(itemID => {
                const foundItem = layer.items.find(item => item.id === itemID)

                if(foundItem){
                    const mid = midPoint(v1.x,v1.y,v2.x,v2.y)
                    const start = pointOnLine(mid.x,mid.y,v2.x,v2.y,(foundItem.measurements ? foundItem.measurements.length / 2 : 0.5) * scale)
                    const end = pointOnLine(mid.x,mid.y,v2.x,v2.y,-(foundItem.measurements ? foundItem.measurements.length / 2 : 0.5) * scale)
                    
                    if(foundItem.id_element < 6){
                        ctx.strokeStyle = "#f00";
                    } else {
                        ctx.strokeStyle = "#ff0000";
                    }

                    if(sceneActions.active === foundItem.id) ctx.strokeStyle = COLORS.planner.itemActive;

                    if(sceneActions.selected === foundItem.id) ctx.strokeStyle = COLORS.planner.itemSelected;

                    ctx.lineWidth = 5;
                    ctx.beginPath()
                    ctx.moveTo(start.x,start.y)
                    ctx.lineTo(end.x,end.y)
                    ctx.stroke()
                }
  
            })

        })

        layer.items.forEach(item => {

            const areaIncluded = layer.areas.some(a => a.floor.items.includes(item.id) || a.ceiling.items.includes(item.id))

            if(areaIncluded){
                ctx.fillStyle = '#0000ff';
    
                if(sceneActions.active === item.id) ctx.fillStyle = COLORS.planner.itemActive;
    
                if(sceneActions.selected === item.id) ctx.fillStyle = COLORS.planner.itemSelected;                              
                    
                if(item.position){                  
                    const itemPosition = item.position ? item.position : 5
                    const itemLength = item.measurements ? item.measurements.length : 1
                    const itemDepth = item.measurements ? item.measurements.depth : .5
    
                    ctx.fillRect(itemPosition.x * scale + offset.x,itemPosition.y * scale + offset.y,itemLength * scale,itemDepth * scale)
                }
            }
        })

        // draw labels
        if(vertices){
            const polygon = vertices.map(v => [v.x,v.y])

            let areaSize = areapolygon(polygon, false);
            const center = getPolygonCentroid(vertices);
            ctx.fillStyle = "#00D4FF";
            ctx.fillText(Math.round((areaSize * scale) / scale).toFixed(2) + ' m2', center.x * scale + offset.x, center.y * scale + offset.y);
            ctx.fillText(sceneActions.selected, center.x * scale + offset.x, center.y * scale + offset.y + 10);
        }
    }

    const handleMouseDown = (e) => {
        // const ctx = canvasRef.current
        const layer = projectActions.scenes[0].layers[0] 
        const areas = layer.areas
        // const vertices = layer.vertices

        let testArea = false;
        // let testLine = false;
        // let testHole = false;
        // let testItem = false;

        for(const area of areas){
            const areaVertices = getAreaVertices(layer,area.id)
            const testInside = inside2(pos, areaVertices)

            if(testInside === true){
                sceneActions.setSelected(area.id);
                testArea = true;   
                
                setVectors(getVectors(areaVertices,pos))
            }
        }

        // layer.items.forEach(item => {
        //     const itemVertices = getItemVertices(item)
        //     const testInside = inside2(pos, itemVertices)

        //     if(testInside){
        //         setStartPos(pos)
        //     }
        // })

        // layer.lines.forEach((l,i) => {

        //     const v1 = {x:vertices[l.v1].x, y:vertices[l.v1].y}
        //     const v2 = {x:vertices[l.v2].x, y:vertices[l.v2].y}


        //     l.holes.forEach(h => {

        //         const mid = midPoint(v1.x,v1.y,v2.x,v2.y)

        //         const start = pointOnLine(mid.x,mid.y,v2.x,v2.y,(h.measurements ? h.measurements.length / 2 : 0.5))
        //         const end = pointOnLine(mid.x,mid.y,v2.x,v2.y,-(h.measurements ? h.measurements.length / 2 : 0.5))

        //         if(isPointOnLine(start.x,start.y,end.x,end.y,pos.x,pos.y)){
        //             sceneActions.setActive(h)

        //             console.log('WCHODZI DO HOLE',h)

        //             testHole = true;
        //         }

        //     })

        //     if(isPointOnLine(vertices[l.v1].x,vertices[l.v1].y,vertices[l.v2].x,vertices[l.v2].y,realPos.x,realPos.y)){
        //         sceneActions.setSelected(l.id)
        //         testLine = true;
        //     }
        // })
        
        // if(testArea !== true && testLine !== true && testHole !== true){
        //     sceneActions.setSelected(null)
        // }
        
        // console.log('SELECTED',sceneActions.active,sceneActions.selected,testLine,testArea,testHole)

        // sceneActions.setRefresh(!sceneActions.refresh)
        if(sceneActions.active){
            sceneActions.setSelected(sceneActions.active)
            setSelected(sceneActions.active)
            const foundItem = layer.items.find(it => it.id === sceneActions.active)
            if(foundItem){
                setVector(getVector(pos,foundItem))
            }
            setStartPos(pos)
        } else {
            sceneActions.setSelected(null)
            setSelected(null)
            setVector(null)
            setStartPos(null)
        }

    }

    const handleMouseMove = (e) => {
        const ctx = canvasRef.current.getContext('2d')
        const layer = projectActions.scenes[0].layers[0]
        const vertices = layer.vertices
        const pos = getMousePos(canvasRef.current,e)
        setRealPos(() => getRealPos(pos,scale,offset))
        setPos(() => realPos)

        let testIn = false;

        for(const area of layer.areas){
            const areaVertices = getAreaVertices(layer,area.id)
            const testInside = inside2(realPos, areaVertices)        
            
            let testEnabled = true

            if(enabled === 'others'){
                testEnabled = area.features[0] !== 6 && area.features[0] !== 8
            }

            if(testInside === true && testEnabled === true){
                sceneActions.setActive(() => area.id)
                testIn = true;
                if(startPos && area.id === sceneActions.selected && plannermode === 'rooms'){
                    sceneActions.moveArea(area.id,vectors,startPos,realPos)
                }
            }      

            if(plannermode !== 'rooms' && plannermode !== 'wallsfloor' && testEnabled === true){
                area.ceiling.items.forEach((itemID,i) => {
                    const foundItem = layer.items.find(it => it.id === itemID)
                })

                area.floor.items.forEach((itemID,i) => {
                    const foundItem = layer.items.find(it => it.id === itemID)

                    if(foundItem){
                        const testItem = inside2(realPos,getItemVertices(foundItem))
                    
                        if(testItem === true){
                            testIn = true;
                            sceneActions.setActive(() => foundItem.id)
                            console.log('jest',i,realPos,foundItem.id, sceneActions.active) 
                            if(startPos && sceneActions.active && vector){
                                sceneActions.moveElement(null,foundItem.id,vector,startPos,realPos)
                            }
                        }
                    }
                })
            }
        }

        layer.lines.forEach((l,i) => {

            const v1 = {x:vertices[l.v1].x, y:vertices[l.v1].y}
            const v2 = {x:vertices[l.v2].x, y:vertices[l.v2].y}

            if(isPointOnLine(vertices[l.v1].x,vertices[l.v1].y,vertices[l.v2].x,vertices[l.v2].y,realPos.x,realPos.y)){
                sceneActions.setActive(l.id)
            }

        })

        if(plannermode !== 'rooms' && plannermode !== 'wallsfloor'){
            layer.lines.forEach((l,i) => {

                let holeTest = false

                const v1 = {x:vertices[l.v1].x, y:vertices[l.v1].y}
                const v2 = {x:vertices[l.v2].x, y:vertices[l.v2].y}
 
                // if(isPointOnLine(vertices[l.v1].x,vertices[l.v1].y,vertices[l.v2].x,vertices[l.v2].y,realPos.x,realPos.y)){
                //     sceneActions.setActive(l.id)
                // }

                l.holes.forEach(h => {
                    const mid = midPoint(v1.x,v1.y,v2.x,v2.y)
                    const start = pointOnLine(mid.x,mid.y,v2.x,v2.y,(h.measurements ? h.measurements.length / 2 : 0.5))
                    const end = pointOnLine(mid.x,mid.y,v2.x,v2.y,-(h.measurements ? h.measurements.length / 2 : 0.5))

                    if(isPointOnLine(start.x,start.y,end.x,end.y,realPos.x,realPos.y)){
                        sceneActions.setActive(h)
                        holeTest = true;
                    }
                })

                l.items.forEach(item => {
                    const mid = midPoint(v1.x,v1.y,v2.x,v2.y)
                    const start = pointOnLine(mid.x,mid.y,v2.x,v2.y,(item.measurements ? item.measurements.length / 2 : 0.5))
                    const end = pointOnLine(mid.x,mid.y,v2.x,v2.y,-(item.measurements ? item.measurements.length / 2 : 0.5))

                    if(isPointOnLine(start.x,start.y,end.x,end.y,realPos.x,realPos.y)){
                        sceneActions.setActive(item)
                        holeTest = true;
                    }
                })


            })
        }

        if(testIn === false){
            sceneActions.setActive(null)
        }
        sceneActions.setRefresh(!sceneActions.refresh)
    }

    const handleDragOver = (e) => {
        e.preventDefault();
        const layer = projectActions.scenes[0].layers[0]
        const lines = layer.lines
        const areas = layer.areas
        const vertices = layer.vertices
        
        let areaTest = false
        let lineTest = false

        const poss = getMousePos(canvasRef.current,e)
        const realPos = getRealPos(poss,scale,offset)

        if(sceneActions.target === 'floor' || sceneActions.target === 'ceiling' || sceneActions.target === 'all' || sceneActions.target === 'floorWall' || sceneActions.target === 'ceilingWall' || !sceneActions.target){
            areas.forEach(area => {
                    const areaVertices = getAreaVertices(layer,area.id)
                    const testInside = inside2(realPos, areaVertices)
                    if(testInside === true){
                        sceneActions.setActive(area.id)
                        areaTest = true
                    }
            })            
        }

        if(sceneActions.target === 'wall' || sceneActions.target === 'all'  || sceneActions.target === 'floorWall'  || sceneActions.target === 'ceilingWall' || !sceneActions.target){
            lines.forEach(l => {
                if(isPointOnLine(vertices[l.v1].x,vertices[l.v1].y,vertices[l.v2].x,vertices[l.v2].y,realPos.x,realPos.y)){
                    sceneActions.setActive(l.id)
                    lineTest = true
                } 
            })
        }
        
        if(areaTest !== true && lineTest !== true){
            sceneActions.setActive(null)
        }
    }

    const handleDragLeave = (e) => {
        console.log('LEAVE')
        setStartPos(null)

        console.log(realPos.x,realPos.y)
    }

    const handleMouseUp = (e) => {
        setStartPos(null)
    }

    const handleOnDrop = (e) => {
        const option = JSON.parse(e.dataTransfer.getData('o'))
        const layer = projectActions.scenes[0].layers[0]
        const poss = getMousePos(canvasRef.current,e)
        const realPos = getRealPos(poss,scale,offset)

        // console.log(pos,realPos)
        const elementData = getElementData(projectActions.elementsData,option.id_element,option.typology) 

        console.log('DROP ON',option,elementData)
        
        if(elementData){
            switch(option.id_element){
                case 19:
                    let startVertex = projectActions.scenes[0].layers[0].vertices.length
                    const newVertices = shiftVertices(elementData.variants[option.type].vertices,realPos)

                    console.log(elementData.variants[option.type].vertices,newVertices)

                    const lineIDs = []
                    sceneActions.addVertex(newVertices)
                    for(let i=0; i < newVertices.length; i++){
                        const zeros = [10,12,14,17,null,null,29,null]
                        zeros[0] = 10;
                        if(i > 0){
                            console.log('Line',newVertices[i-1],newVertices[i]);
                            // const newDistance = verticesDistance(newVertices[i-1],newVertices[i])
                            const newLine = createLine('wall','Pared',startVertex + i -1,startVertex + i,zeros)
                            sceneActions.addLine(newLine)
                            lineIDs.push(newLine.id)
                            console.log('NEW LINE',i,newLine)
                        }
                        if(i === newVertices.length -1){
                            console.log('Line',newVertices[i],newVertices[0]);
                            const newLine = createLine('wall','Pared',startVertex + i,startVertex,zeros)
                            sceneActions.addLine(newLine)
                            lineIDs.push(newLine.id)
                            console.log('NEW LINE ',i,newLine)
                        }
                    }
                    const newRoom = createRoom('Room','Room',0,lineIDs,[10])
                    sceneActions.addRoom(null,newRoom)
                    console.log('drop ',pos,projectActions.scenes.map(s => s.layers[0]))
                break;
                case 4:
                case 5:
                case 6:
                    if(sceneActions.active){
                        const newHole = createNewElement(elementData,option,realPos)
                        console.log('Created',newHole,'added to',sceneActions.target)
                        sceneActions.attachElement('all',layer,option.target,sceneActions.active,newHole)
                    }
                break;
                default:
                    console.log('default add',pos,realPos)
                    if(sceneActions.active){
                        const newItem = createNewElement(elementData,option,realPos)
                        console.log('Created',newItem,'added to',sceneActions.target)
                        sceneActions.attachElement('all',layer,option.target,sceneActions.active,newItem)
                    }
            }
        }
        sceneActions.setTarget(null)
        sceneActions.setRefresh(!sceneActions.refresh)
    }
    
    const handleDragEnter = (e) => {
        // const option = JSON.parse(e.dataTransfer.getData('o'))
        console.log('ENTERING',sceneActions.target)
    }

    return <canvas 
        style={{width:'100%',height:'100%'}}
        className='rc-shapeCanvas' 
        onDrop={handleOnDrop}
        onDragOver={handleDragOver} 
        onDragEnter={handleDragEnter} 
        onDragLeave={handleDragLeave} 
        onMouseMove={handleMouseMove} 
        onMouseUp={handleMouseUp} 
        onMouseDown={handleMouseDown} 
        ref={canvasRef} {...props}
    />
}

export default Canvas2