import React, { useEffect, useState} from 'react';
import {MdKeyboardArrowLeft} from "react-icons/md";
import {Col, Row} from "antd";
import {currencyFormat} from "../../utils/formatUtils";
import whiteSpace from "../../assets/icons/whiteSpace.png";
import ItemDescriptionProject from "./ItemDescriptionProject";

/**
 * Modal description detail(acabado) job.
 *
 * @param prop Props.
 * @param prop.job Detail(acabado) to render.
 * @param prop.closeModal Callback to close modal since the parent.
 * @param prop.allElements Json elements to find image.
 * @param prop.isActive If project is active.
 * @return React element ModalDescriptionJob component.
 */
const ModalDescriptionJob = ({job, closeModal, allElements, isActive}) => {
    const [imgDetail, setImgDetail] = useState(undefined);
    const [elementData, setElementData] = useState(undefined);
    const [attributes, setAttributes] = useState([])
    const getImageByJob = () => {
        const nameArray = job.name.split('|');
        setAttributes(nameArray)
        const elementData = allElements.find(el => {
            let tempName = nameArray[0]
            switch (nameArray[0]){
                case 'Suelo':
                    tempName = "floor"
                break;
                case 'Pared':
                    tempName = "wall"
                break;
            }
            return el.name.includes(tempName)
        })    

        if(job.img !== ''){
            setImgDetail(process.env.PUBLIC_URL + job.img)
        } else {
            if(elementData) {
                setElementData(elementData)
                const foundProp = elementData.properties.find(prop => prop.name === 'model')
                if (foundProp) {
                    let modelFromName = nameArray[1]
                    switch (elementData.name){
                        case 'wall':
                        case 'floor':
                            modelFromName = nameArray[2]
                        break;
                    }
                    const imgObject = foundProp.values.find(val => val.name === modelFromName)
                    const imgTemp = imgObject && imgObject.img && imgObject.img.length
                        ? process.env.PUBLIC_URL + imgObject.img[0]
                        : whiteSpace;
                    setImgDetail(imgTemp)
                } else {
                    setImgDetail(whiteSpace)
                }
            }
        }
    };

    useEffect(() => { getImageByJob() }, [job]);

    return <>
        {!isActive && <div className="flex items-center">
            <MdKeyboardArrowLeft size={30} className="cursor-pointer" onClick={closeModal} />
            <span className="font-bold color-blue-dark">Volver a presupuesto detallado</span>
        </div>}
        <h1 className="text-2xl pt-4 color-blue-dark">
            {attributes && attributes.length && (attributes[0]?.replace(/\/|\_|\|/g, ' ') ||
            job.name.replace(/\/|\_|\|/g, ' '))}
        </h1>

        <Row gutter={20}>
            <Col xs={24} sm={24} md={14} lg={14}>
                <div style={{height: 450}} className="bg-secondary rounded">
                    <img
                        className='w-full h-full rounded object-cover'
                        src={imgDetail}
                        alt={''}
                    />
                </div>
            </Col>
            <Col xs={24} sm={24} md={10} lg={10}>
                <div>
                    {elementData && elementData.properties.filter(prop => prop.name !== 'style').map((el,i) => 
                        <ItemDescriptionProject key={i} name={el.label} value={attributes[i + 1]?.replace(/\/|\_|\|/g, ' ') || '-'} />
                    )}

                    <hr className="my-4 hr-blue"/>

                    <ItemDescriptionProject
                        name={'Medidas'}
                        value={job.measurement.measurement.toFixed(2) + ' ' + job.measurement.unit}
                    />
                    <ItemDescriptionProject
                        name={'Precio ud.'}
                        value={currencyFormat(job.price.toFixed(2))}
                    />

                    <hr className="my-4 hr-blue"/>

                    <ItemDescriptionProject
                        customClass="items-center"
                        name={'Importe'}
                        value={
                            <span className="text-xl color-blue font-bold">
                                {currencyFormat(job.amountTotal.toFixed(2))}
                            </span>
                        }
                    />
                </div>
            </Col>
        </Row>
    </>
}

export default ModalDescriptionJob;
