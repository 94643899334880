import { useEffect, useState, useContext } from 'react'
import { Form, Input, Select } from 'antd'
import ProjectContext from '../../context/ProjectContext'
import { elementIdToName } from '../../utils/formatUtils'

const DecisionSelector = ({id_element}) => {
    const [form] = Form.useForm();
    const projectActions = useContext(ProjectContext)

    const [filteredDecisions, setFilteredDecisions] = useState([])
    const [decform, setDecForm] = useState({
        id: null,
        id_dec: null,
      })

    const calcData = projectActions.calcData;

    useEffect(() => {
        setFilteredDecisions(() => calcData.int_decisions_test.filter(dec => {
            const aDec = dec.id_dec.split('_')
            return parseInt(aDec[2]) === parseInt(id_element)
          }).map((d,i) => {
            const tempDecision = {key:i,value:d.id_dec,label:'Decision (' + d.id_dec + ') with works:(' + d.id_works + ')'}
            return tempDecision
          })
        )
    },[])
    
  const handleFillFormSelect = (value) => {
    console.log(value)
    setDecForm(() => ({...decform, id_dec: value}))
  }

  return (
    <div>Decisions of {elementIdToName(id_element)} {filteredDecisions.length}
        <Form form={form}>
        <Form.Item>
          <Select 
            onChange={handleFillFormSelect}
            options={filteredDecisions}
          />
        </Form.Item>
        </Form>
    </div>
  )
}

export default DecisionSelector