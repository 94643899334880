import { Fragment, useContext, useEffect, useState } from 'react'
import { Select, Tag, Button, Form, Input } from 'antd';

import { BsChevronExpand } from 'react-icons/bs'
import { FaChevronUp, FaChevronDown, FaChevronLeft, FaChevronRight } from 'react-icons/fa'

import ProjectContext from '../../context/ProjectContext'
import CalcContext from '../../context/CalcContext'
import SceneContext from '../../context/SceneContext'
import ModalContext from '../../context/ModalContext'

import {elementIdToName} from '../../utils/formatUtils'
import {getElementOfId, getIdfromSelected} from '../../utils/sceneUtils'

const Table = ({sceneId,scope}) => {

  const [form] = Form.useForm();

  const projectActions = useContext(ProjectContext)
  const sceneActions = useContext(SceneContext)
  const calcActions= useContext(CalcContext)
  const modalActions= useContext(ModalContext)
  const [layerCollapse, setLayerCollapse] = useState(null);
  const [actualScene, setActualScene] = useState(null);
  const [actualLayer, setActualLayer] = useState(null);
  const [show, setShow] = useState(false)
  const [ colapse, setColapse ] = useState(true)
  const [decform, setDecForm] = useState({
    id: null,
    id_dec: null,
  })
  
  const labels = [
    ['elementID','element','room','typ','mat','cov','per','oth','cond','style','id_dec','id_works','area','length','height','depth','x','y','z'],
    ['elementID','element','room','typ','mat','cov','per','oth','cond','new','o.typ','o.mat','o.cov','o.per','o.oth','o.cond.','c.room','c.typ.','c.cov','c.per','l.insul','l.insul2','l.typ','l.cov','l.per','style','dec','+','works','area','length','height','depth',,'x','y','z']
  ];
  
  useEffect(() => {
    setActualLayer(() => projectActions.scenes[sceneId] ? projectActions.scenes[sceneId].layers[0] : null);
    setActualScene(() => projectActions.scenes[sceneId] ?? null);

    console.log('----',projectActions.project)
   },[projectActions.scenes])

  const calcData = projectActions.calcData;

  const selectedId = getIdfromSelected(actualLayer,sceneActions.selected);

  const handleFillFormSelect = (value) => {
    // console.log(value)
    setDecForm(() => ({...decform, id_dec: value}))
  }

  const handleAddDecision = (item) => {
    const filteredDecisions = calcData.int_decisions_test.filter(dec => {
      const aDec = dec.id_dec.split('_')
      return parseInt(aDec[2]) === parseInt(item.id_element)
    }).map((d,i) => {
      const tempDecision = {key:i,value:d.id_dec,label:'Decision (' + d.id_dec + ') with works:(' + d.id_works + ')'}
      return tempDecision
    })
    // console.log('ADD',item,filteredDecisions)
    modalActions.setModalData({
      heading: 'Add decision',
      subHeading: "select from the list or define new decision",
      body: (<Form form={form}>
        <p><strong>Item:</strong> {item.name}, <strong> Item_id:</strong> {item.id} <strong> DecisionsFound:</strong> {filteredDecisions.length}</p>
        <p>Current decisions: ({item.decisions.map((dec,i) => {
          return (i > 0 && ', ') + dec.id
        })})
        </p>
        <Form.Item label="Select decision">
          <Select 
            onChange={handleFillFormSelect}
            options={filteredDecisions}
          />
        </Form.Item>
        <Form.Item
          label="id"
          name="id"
          defaultValue={decform.id}
          required={true}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="id_dec"
          name="id_dec"
          defaultValue={decform.id_dec}
          required={true}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="id_works"
          name="id_works"
          required={true}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="code_work"
          name="code_work"
          required={true}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='additional dec'
          id="name"
          name="name"
          rules={[
            {
              required: true,
              message: "Por favor, introduce el decision name",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
      ),
      buttons:[
        { text: "En otro momento", type: "text", onClick: "close" },
        {
          text: "ADD NEW DECISION",
          type: "primary",
          onClick: () => null
        }
      ]
    })
  }

  const collapseRow = (which) => {
    console.log(which)
    setLayerCollapse((l) => {
      const tempL = l;
      l[which] = l[which] ?? false;
      return l;
    })
  }

  return (
    <div>
    <button className='debug-btn debug' onClick={() => setShow(!show)}>Toggle {sceneId === 0 ? 'initial' : 'integrated'} Table {scope} {}<BsChevronExpand /></button>
      {actualScene ? 
        show === true && <table className="scene-table">
          <thead>
          <tr>
            <td></td>
            <td></td>
            {labels[sceneId].map((l,i) => <th key={i} className={((i > 1 && i < 26) || (i > 27 && i < 33)) ? 'vertical' : ''}>{l}</th>)}
          </tr></thead>
          <tbody>
            {actualScene.house.map((houseItem,i) => {
              return <tr key={i}>
                <td></td><td>{i === 0 && "Casa"}</td><td>({houseItem.id_element}){elementIdToName(houseItem.id_element)}</td><td>{houseItem.name}</td>
                {houseItem && houseItem.features.map((feature,j) => <td key={j} className={feature === null ? 'empty' : 'cell'}>{feature}</td>)}
                {houseItem && <td>{houseItem.decisions.map((dec,k) => {const coma = k > 0 ? ', ' : ""; return coma + dec.id_dec})}</td>}
                <td><button onClick={() => handleAddDecision(houseItem)}>&nbsp;+&nbsp;</button></td>
                {houseItem && <td>{houseItem.decisions.map((dec,k) => {const coma = k > 0 ? ', ' : ""; return coma + "(" + dec.id_works + ")"})}</td>}
              </tr>
            })}
            {actualScene.layers.map((layer,li) => {
              {/* console.log('IN TABLE',sceneId,layer) */}
              return <Fragment key={li}> 
                <tr>
                  <td><button>{layerCollapse === true ? <FaChevronUp /> : <FaChevronDown />}</button></td><td colSpan="13">Storey {li}</td>
                </tr>
                {layer.areas.filter(a => !scope || a.id === scope || (scope === 'General' && a.features[0] !== 6 && a.features[0] !== 8) || (scope === 'wetrooms' && (a.features[0] === 6 || a.features[0] === 8))).map((area,i) => {
                  return <Fragment key={i}>
                  <tr style={{border:'2px solid black'}}><td></td></tr>
                  <tr>
                    <th><button><FaChevronUp /></button></th>
                    <td><b>{area.id}</b></td>
                    <td>{area.id_element}({"Area"})</td>
                    <td><b>{area.label}</b></td>
                    {area.features.map((feature,j) => <td key={j} className={feature === null ? 'empty' : 'cell'}>{feature}</td>)}
                    {area.decisions && <td>{area.decisions.map((dec,j) => {const coma = j > 0 ? ', ' : ""; return coma + "(" + dec.id_works + ")"})}</td>}
                    {area.measurements && <td>{area.measurements.area}</td>}
                  </tr>
                  <tr>
                    <td></td>
                    <td></td><td>({area.floor.id_element}){elementIdToName(area.floor.id_element)}</td><td></td>
                    {area.floor.features.map((feature,j) => <td key={j} className={feature === null ? 'empty' : 'cell'}>{feature}</td>)}
                    {area.floor.decisions && <td>{area.floor.decisions.map((dec,j) => {const coma = j > 0 ? ', ' : ""; return coma + dec.id_dec})}</td>}
                    <td><button onClick={() => handleAddDecision(area.floor)}>&nbsp;+&nbsp;</button></td>
                    {area.floor.decisions && <td>{area.floor.decisions.map((dec,j) => {const coma = j > 0 ? ', ' : ""; return coma + "(" + dec.id_works + ")"})}</td>}
                    {area.floor.measurements && <td>{area.floor.measurements.area}</td>}
                  </tr>
                    {layer.items.filter(item => area.floor.items.includes(item.id)).map((item,i) => {
                      return <tr key={i} className={item.id === selectedId ? "selected" : "grey"}>
                        <td></td>
                        <td>{item.id}</td><td>({item.id_element}){elementIdToName(item.id_element)}</td><td>{item.name}</td>
                        {item.features.map((feature,j)=> <td key={j} className={feature === null ? 'empty' : 'cell'}>{feature}</td>)}
                        {item.decisions && <td>{item.decisions.map((dec,j) => {const coma = j > 0 ? ', ' : ""; return coma + dec.id_dec})}</td>}
                        <td><button onClick={() => handleAddDecision(item)}>&nbsp;+&nbsp;</button></td>
                        {item.decisions && <td>{item.decisions.map((dec,j) => {const coma = j > 0 ? ', ' : ""; return coma + "(" + dec.id_works + ")"})}</td>}
                        {item.measurements && <><td>{item.measurements.area ? item.measurements.area : ''}</td><td>{item.measurements.length ? item.measurements.length : ''}</td><td>{item.measurements.height ? item.measurements.height : ''}</td><td>{item.measurements.depth ? item.measurements.depth : ''}</td></>}
                        {item.position && <><td>{item.position.x}</td><td>{item.position.y}</td><td>{item.position.z}</td></>}
                      </tr>
                    })}               
                  <tr>
                    <td></td>
                    <td></td><td>({area.ceiling.id_element}){elementIdToName(area.ceiling.id_element)}</td><td></td>
                    {area.ceiling.features.map((feature,j) => <td key={j} className={feature === null ? 'empty' : 'cell'}>{feature}</td>)}
                    {area.ceiling.decisions && <td>{area.ceiling.decisions.map((dec,j) => {const coma = j > 0 ? ', ' : ""; return coma + dec.id_dec})}</td>}
                    <td><button onClick={() => handleAddDecision(area.ceiling)}>&nbsp;+&nbsp;</button></td>
                    {area.ceiling.decisions && <td>{area.ceiling.decisions.map((dec,j) => {const coma = j > 0 ? ', ' : ""; return coma + "(" + dec.id_works + ")"})}</td>}
                    {area.ceiling.measurements && <td>{area.ceiling.measurements.area}</td>}
                  </tr>
                  {layer.items.filter(item => area.ceiling.items.includes(item.id)).map((item,i) => {
                      return <tr key={i} className={item.id === selectedId ? "selected" : "grey"}>
                        <td></td>
                        <td>{item.id}</td><td>({item.id_element}){elementIdToName(item.id_element)}</td><td>{item.name}</td>
                        {item.features.map((feature,j)=> <td key={j} className={feature === null ? 'empty' : 'cell'}>{feature}</td>)}
                        {item.decisions && <td>{item.decisions.map((dec,j) => {const coma = j > 0 ? ', ' : ""; return coma + dec.id_dec})}</td>}
                        <td><button onClick={() => handleAddDecision(item)}>&nbsp;+&nbsp;</button></td>
                        {item.decisions && <td>{item.decisions.map((dec,j) => {const coma = j > 0 ? ', ' : ""; return coma + "(" + dec.id_works + ")"})}</td>}
                        {item.measurements && <><td>{item.measurements.area ? item.measurements.area : ''}</td><td>{item.measurements.length ? item.measurements.length : ''}</td><td>{item.measurements.height ? item.measurements.height : ''}</td><td>{item.measurements.depth ? item.measurements.depth : ''}</td></>}
                        {item.position && <><td>{item.position.x}</td><td>{item.position.y}</td><td>{item.position.z}</td></>}
                      </tr>
                    })}   
                  {area.lines && area.lines.map((lineId,i) => {
                    const foundLine = getElementOfId(layer,null,lineId)
                    if(foundLine){
                      return <Fragment key={i}><tr className={foundLine.id === selectedId ? "selected" : "wall"}>
                      <td>{i}</td>
                      <td><b>{foundLine.id}</b></td><td>({foundLine.id_element}){elementIdToName(foundLine.id_element)}</td><td>{foundLine.name}</td>
                        {foundLine.features.map((feature,j)=> <td key={j} className={feature === null ? 'empty' : 'cell'}>{feature}</td>)}
                        {foundLine.decisions && <td>{foundLine.decisions.map((dec,j) => {const coma = j > 0 ? ', ' : ""; return coma + dec.id_dec})}</td>}
                        <td><button onClick={() => handleAddDecision(foundLine)}>&nbsp;+&nbsp;</button></td>
                        {foundLine.decisions && <td>{foundLine.decisions.map((dec,j) => {const coma = j > 0 ? ', ' : ""; return coma + "(" + dec.id_works + ")"})}</td>}
                        {foundLine.measurements && <><td>{foundLine.measurements.area ? foundLine.measurements.area : ''}</td><td className="smallcell"> {foundLine.measurements.length ? foundLine.measurements.length : ''}</td><td>{foundLine.measurements.height ? foundLine.measurements.height : ''}</td><td>{foundLine.measurements.depth ? foundLine.measurements.depth : ''}</td></>}
                    </tr>
                        {layer.holes.filter(hole => foundLine.holes.includes(hole.id)).map((hole,i) => {
                          return <tr key={i} className={hole.id === selectedId ? "selected" : "door"}>
                            <td></td>
                            <td>{hole.id}</td><td>({hole.id_element}){elementIdToName(hole.id_element)}</td><td>{hole.name}</td>
                            {hole.features.map((feature,j)=> <td key={j} className={feature === null ? 'empty' : 'cell'}>{feature}</td>)}
                            {hole.decisions && <td>{hole.decisions.map((dec,j) => {const coma = j > 0 ? ', ' : ""; return coma + dec.id_dec})}</td>}
                            <td><button onClick={() => handleAddDecision(hole)}>&nbsp;+&nbsp;</button></td>
                            {hole.decisions && <td>{hole.decisions.map((dec,j) => {const coma = j > 0 ? ', ' : ""; return coma + "(" + dec.id_works + ")"})}</td>}
                            {hole.measurements && <><td>{hole.measurements.area ? hole.measurements.area : ''}</td><td>{hole.measurements.length ? hole.measurements.length : ''}</td><td>{hole.measurements.height ? hole.measurements.height : ''}</td><td>{hole.measurements.depth ? hole.measurements.depth : ''}</td></>}
                            {hole.position && <><td>{hole.position.x}</td><td>{hole.position.y}</td><td>{hole.position.z}</td></>}
                          </tr>
                        })} 
                        {layer.items.filter(item => foundLine.items.includes(item.id)).map((item,i) => {
                          return <tr key={i} className={item.id === selectedId ? "selected" : "grey"}>
                            <td></td>
                            <td>{item.id}</td><td>({item.id_element}){elementIdToName(item.id_element)}</td><td>{item.name}</td>
                            {item.features.map((feature,j)=> <td key={j} className={feature === null ? 'empty' : 'cell'}>{feature}</td>)}
                            {item.decisions && <td>{item.decisions.map((dec,j) => {const coma = j > 0 ? ', ' : ""; return coma + dec.id_dec})}</td>}
                            <td><button onClick={() => handleAddDecision(item)}>&nbsp;+&nbsp;</button></td>
                            {item.decisions && <td>{item.decisions.map((dec,j) => {const coma = j > 0 ? ', ' : ""; return coma + "(" + dec.id_works + ")"})}</td>}
                            {item.measurements && <><td>{item.measurements.area ? item.measurements.area : ''}</td><td>{item.measurements.length ? item.measurements.length : ''}</td><td>{item.measurements.height ? item.measurements.height : ''}</td><td>{item.measurements.depth ? item.measurements.depth : ''}</td></>}
                            {item.position && <><td>{item.position.x}</td><td>{item.position.y}</td><td>{item.position.z}</td></>}
                          </tr>
                        })}
                        </Fragment>                       
                      }
                    })}
                    {layer.items.filter(item => area.items.includes(item.id)).map((item,i) => {
                      return <tr key={i} className={item.id === selectedId ? "selected" : "grey"}>
                        <td></td>
                        <td>{item.id}</td><td>({item.id_element}){elementIdToName(item.id_element)}</td><td>{item.name}</td>
                        {item.features.map((feature,j)=> <td key={j} className={feature === null ? 'empty' : 'cell'}>{feature}</td>)}
                        {item.decisions && <td>{item.decisions.map((dec,j) => {const coma = j > 0 ? ', ' : ""; return coma + dec.id_dec})}</td>}
                        <td><button onClick={() => handleAddDecision(item)}>&nbsp;+&nbsp;</button></td>
                        {item.decisions && <td>{item.decisions.map((dec,j) => {const coma = j > 0 ? ', ' : ""; return coma + "(" + dec.id_works + ")"})}</td>}
                        {item.measurements && <><td>{item.measurements.area ? item.measurements.area : ''}</td><td>{item.measurements.length ? item.measurements.length : ''}</td><td>{item.measurements.height ? item.measurements.height : ''}</td><td>{item.measurements.depth ? item.measurements.depth : ''}</td></>}
                        {item.position && <><td>{item.position.x}</td><td>{item.position.y}</td><td>{item.position.z}</td></>}
                      </tr>
                    })}  

                  </Fragment>
                })}
              </Fragment>})}
            <tr><td><button>&nbsp;+&nbsp;</button></td></tr>            
          </tbody>  
        </table>
        :
        "Scene not found"   
      }
    </div>
  )
}

export default Table